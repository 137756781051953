
import { environment } from './../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerModel } from '../models/customer.module';
import { AnandaAuthenModel, AnandaMultiSite } from '../models/anandaauthen.module';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IpropView } from '../models/ipropview.module';

@Injectable({
  providedIn: 'root'
})
export class IpropService {

  _prefix = environment.apiCoreView;
  _urlAnandaIprop = this._prefix + 'fetchVmMobileUser';  //Method in Controller

  constructor(private http: HttpClient, private router: Router) {
    this.callENV();
  }

  async callENV() {
    this._prefix = await environment.apiCoreView;
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  PostToIprop(_unitcode: any): Observable<IpropView[]> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': 'Basic cGFjZWwtYXBwOnBhY2VsLWFwcC0yMDIzMDkwNg==',
     });
    var _data = {
      "unitcode": _unitcode
    }

    let options = { headers: headers };
    return this.http.post<IpropView[]>(this._urlAnandaIprop, _data, options)
      .pipe(
        map(response => {
          return <any>response;
        }),
        catchError(error => {
          this.handleError("ERROR");
          return of("ERROR");
        })
      );
  }

}




