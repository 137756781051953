<html>
<header>
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  <style>
    .scanner {
      position: absolute;
      margin-left: 13cm;
      margin-top: 7cm;
      width: 500px;
      height: 300px;
      color: white;
      backdrop-filter: blur(8px) brightness(80%);
    }

    .dropzone2 {
      width: 650px;
      display: flex;
      align-items: left;
      justify-content: left;
      flex-direction: column;
      font-weight: 100;
      height: 100px;
      border: 2px dashed #192d4ba1;
      border-radius: 3px;
      background: rgba(247, 242, 232, 0.137);
      margin: 5px 0;
      padding-bottom: 0.5cm;
    }

    .imagecontainer {
      background: url('../../assets/images/bg2.jpg') no-repeat center;
     background-size: cover;
      /* height: 150px;
      width: 100%; */
      /* width: 100px;
      height: auto;
      border: 1px solid;
      background-size: contain; */
    }

    div.background-parent
    {
      position: relative;
      width: auto;
      height: 135px;
      /* 350 */
    }
    .background {
      
      border-radius: 12px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-size:cover;
      background-image: url('../../assets/images/bg4.jpg');
    }

    
  </style>
  <title></title>
</header>

<body>
  <div class=" container-fluid" style="text-align: end;">
  <span  *ngIf="_view === 1 " class="material-icons" style="width: 12px;height: 2px;" (click)="changeView()">
    refresh
  </span>&nbsp; &nbsp;&nbsp;&nbsp;<a  *ngIf="_view === 1 " style="font-family:kanit;color: rgb(196, 196, 196);" (click)="changeView()">รีเฟรช</a>
</div>
  <!-- <div style="text-align: center;">
    <button style="font-family: kanit;" *ngIf="_view === 1 " mat-raised-button  (click)="changeView()">
      <span class="material-icons" style="width: 12px;">
        search
      </span>
      &nbsp;  &nbsp; ค้นหา</button>
    </div> -->

  <!-- <button style="font-family: kanit;" *ngIf="_view === 1 " mat-raised-button  (click)="changeView()">ค้นหา</button> -->
  <div *ngIf="readyForDropDown == 0 " class=" container-fluid" style="margin-top: 0.1cm">
    <div   class="row" style="text-align: center;background-color: #e9ecef;border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
      <div class="col-12">
        <mat-card style="border-radius: 6px;">
          <!-- <a style="font-family: kanit; text-align: center;">กรุณารอซักครู่ ...
          </a>
          <span class="material-icons" style="width: 12px;margin-top: 0.01cm;">
            directions_run
          </span> -->
       
          <a style="font-family: kanit; text-align: center;">กรุณารอซักครู่ ...
          </a>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        </mat-card>
      </div>
    </div>

  </div>

  <div *ngIf="readyForDropDown == 1  && readyForDropDown == 1" class=" container-fluid" style="margin-top: 0.1cm">
    <div  *ngIf="_view === 0 " class="row" style="background-color: #e9ecef;border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
      <div class="col-12">
        <mat-card style="border-radius: 6px;">
          <div class="col-12" *ngIf="parcelTranDateList !== null ">
            <select class="form-control form-control-sm" (change)='onSelectParcelDate(Trandatestr.value,$event)'
              name="Trandatestr" #Trandatestr id="Trandatestr"
              style="font-size: 17px;height: 30px;font-family: Kanit;margin-top: -0.10cm;border-color: white;  ">
              <option *ngFor="let items of parcelTranDateList" style="font-family: kanit;border-color: white;"
                [value]="items.trandatestr">
                {{items.trandatestr}}
              </option>
            </select>
          </div>
          <div class="col-12" style="margin-top: 0.4cm;border-radius: 4px;" *ngIf="parcelTranDateList !== null ">
            <div class="row">
              <div class="col-12">
                <div class="input-group mr-sm-12">
                  <div class="input-group-prepend" style="height: 32.3px;">
                    <div class="input-group-text">
                      <span class="material-icons">
                        home
                      </span>
                    </div>
                  </div>
                  <input autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                    style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                    placeholder="กรุณาระบุบ้านเลขที่" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="margin-top: 0.2cm;border-radius: 4px;" *ngIf="parcelTranDateList !== null ">
            <div class="row">
              <div class="col-12">

                <button mat-raised-button class="btn  btn-block" (click)="fetchParcel()"
                  style="letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #001b30;color: white;">
                  <span class="material-icons" style="width: 12px;">
                    search
                  </span>
                  &nbsp;&nbsp;ค้นหา&nbsp;
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div style="margin-bottom: -0.5cm;">&nbsp;</div>
  </div>

  <!-- <div class="row justify-content-center" >
    <p style="font-family: kanit;">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" style="border-radius: 9px;">
            <mat-button-toggle (click)="onS()" value="bold" style="font-family: kanit;color: #00b28b;">
              &nbsp;&nbsp;&nbsp;
              <span class="material-icons" style="width: 12px;">
                check_circle
               </span>
               &nbsp;&nbsp; Success (10)
              &nbsp;&nbsp;&nbsp;</mat-button-toggle>
            <mat-button-toggle (click)="onF()" style="font-family: kanit;color: red;" value="italic">
              &nbsp;&nbsp;&nbsp;
              <span class="material-icons" style="width: 12px;">
                error
               </span>
               &nbsp;&nbsp; Failed (0)
              &nbsp;&nbsp;&nbsp;</mat-button-toggle>
         </mat-button-toggle-group>
    </p>
</div> -->
 
  <div *ngIf="parcelSelected != 'กรุณาเลือกวันที่'   && _mockModel.length != 0 ">
    <div class="background-parent">
      <div class="background">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12"
                style="padding-left: 35px;padding-top: 0.5cm;color: #d6dae9;font-size: 19px;font-family: kanit;">
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;จำนวนพัสดุ ทั้งหมด : 10</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
             
              <div class="col-12"
                style="padding-left: 35px;padding-top: 0.1cm;color: #d6dae9;font-size: 16px;font-family: kanit;">
                <mat-chip-list #chipList>
                  &nbsp;&nbsp;  &nbsp;บันทึก สำเร็จ :<mat-chip class="assigned-chip" style="font-size: 18px;color:white;">{{_mockModel.length}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: -0.20cm;">
          <div class="col-12">
            <div class="row">
               
              <div class="col-12"
                style="margin-top: 0.1cm;padding-left: 35px;color: #d6dae9;font-size: 16px;font-family: kanit;">
                <mat-chip-list #chipList>
                  &nbsp;&nbsp;บันทึก ไม่สำเร็จ : <mat-chip class="assigned-chipF" style="font-size: 18px;color: white;">0</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div>&nbsp;</div>
 

<div *ngFor="let item of _mockModel;let i = index" class=" container-fluid"  >
  <div  *ngIf="parcelSelected != 'กรุณาเลือกวันที่'   && _mockModel.length != 0 "  class="row" style="background-color: #e9ecef;border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
    <div class="col-12">
    
      <div class="card" style="border-radius: 9px; ">     
        
        <div class="card-body">
          <!-- <div>
            <div class="row">
              <div class="col-12" style="margin-left: 0.25cm;">
                <span matBadge="{{i+1}}" matBadgeOverlap="false" style="font-family: Kanit;font-size: 12px;"></span>
              </div>
            </div>
          </div> -->
          <!-- <p>
            <span matBadge="{{11}}" matBadgeOverlap="false" style="font-family: Kanit;font-size: 12px;"></span>
          </p> -->
          <div>
              <p style="text-align: start;padding-right: 0.22cm; ">
                <span    matBadgeOverlap="false"
                  style="font-family: kanit;font-size: 19px;letter-spacing: 0.040cm;">บ้านเลขที่ #{{item.address}}</span>
              </p>
          </div>

          <div class="row justify-content-start" style="margin-top: -0.20cm;padding-left: 0.33cm;">
            <p style="align-items: flex-end;text-align: end;">
              <span matBadgeOverlap="false" style="font-family: kanit;font-size: 19px;">
                <mat-chip-list #chipList>
                  <mat-chip> &nbsp;&nbsp;&nbsp;
                    <span class="material-icons" style="width: 12px;margin-top: 0.01cm;">
                      qr_code_scanner
                    </span> &nbsp; &nbsp; &nbsp;QR : {{item.qr}}&nbsp;&nbsp;</mat-chip>
                </mat-chip-list>
              </span>
            </p>
          </div>
        <a #statusparcelv name="statusparcel" id="statusparcel" value="{{item.statusparcel}}"   >  </a>
         
            <!-- <div class="row">
              <div > 
                <mat-chip-list #chipList>
                  &nbsp; &nbsp; &nbsp;
                    <mat-chip>   &nbsp;&nbsp;&nbsp;
                      <span class="material-icons" style="width: 12px;margin-top: 0.01cm;">
                      qr_code_scanner
                    </span> &nbsp; &nbsp; &nbsp;QR : {{item.qr}}&nbsp; &nbsp; &nbsp;</mat-chip>          
                  </mat-chip-list>        
              </div> 
            </div> -->

            <p style="margin-top: 0.2cm;text-align: end;padding-right: 0.5cm;">
              <span *ngIf="statusparcelv.value === '0' "    matBadgeOverlap="false"
                style="font-family: kanit;font-size: 16px;color: #f75b8f4d;text-align: end;">#.
              </span>
              <span *ngIf="statusparcelv.value === '1' "    matBadgeOverlap="false"
              style="font-family: kanit;font-size: 16px;color: #f75b8f4d;text-align: end;">#ลูกค้ารับพัสดุไปแล้ว
            </span>
              <br>
                <!-- <span   matBadgeOverlap="false"
                style="font-family: kanit;font-size: 19px;">Optional 2 : {{item.text2}}</span><br>
                <span   matBadgeOverlap="false"
                style="font-family: kanit;font-size: 19px;">Optional 3 : {{item.text3}}</span><br> -->
            </p>

            <div class="col-12">
              <div class="input-group mr-sm-12">
                <div class="input-group-prepend" style="height: 32.3px;">
                  <div class="input-group-text">
                    <span class="material-icons">
                      bookmark
                    </span>
                  </div>
                </div>

                <input *ngIf="statusparcelv.value === '0' "     autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                placeholder="{{item.text1}}" />
                <input *ngIf="statusparcelv.value === '1' "  disabled    autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                placeholder="{{item.text1}}" />
           
             
              </div>
            </div>
            <div class="col-12" style="margin-top: 0.1cm;">
              <div class="input-group mr-sm-12">
                <div class="input-group-prepend" style="height: 32.3px;">
                  <div class="input-group-text">
                    <span class="material-icons">
                      call
                    </span>
                  </div>
                </div>
                <input  *ngIf="statusparcelv.value === '0' "    autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                  style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                  placeholder="{{item.text2}}" />
                  <input *ngIf="statusparcelv.value === '1' "  disabled  autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                  style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                  placeholder="{{item.text2}}" />
              </div>
            </div>
            <div class="col-12" style="margin-top: 0.1cm;">
              <div class="input-group mr-sm-12">
                <div class="input-group-prepend" style="height: 32.3px;">
                  <div class="input-group-text">
                    <span class="material-icons">
                      mail
                    </span>
                  </div>
                </div>
                <input  *ngIf="statusparcelv.value === '0' "  autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                  style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                  placeholder="{{item.text3}}" />

                  <input   *ngIf="statusparcelv.value === '1' " disabled  autocomplete="false" ngModel name="exampleInputEmail1" type="text"
                  style="color: #192d4b;font-family: kanit;font-size: 15px;" class="form-control form-control-sm"
                  placeholder="{{item.text3}}" />
              </div>
            </div>
            
            <div *ngIf="statusparcelv.value === '0' "   class="col-12" style="margin-top: 0.1cm;text-align: end;">
              <button mat-raised-button style="font-family: kanit;color: #001b30;">อัพเดต...</button>
            </div>
        
         
          <!-- <p style="font-family: kanit;">
            <button mat-raised-button style="font-family: kanit;">{{i + 1}}. บ้านเลขที่ {{item.address}}
              <span class="material-icons">
                done
              </span>
            </button>
          </p> -->
        </div>
         <div class="card-footer"  >
          <small  style="font-family: Kanit;color: #001b30;font-weight: 500;font-size: 15.3px;" >
          {{parcelSelected }} เวลา  {{item.time}}
          </small>
        </div>  
      </div>
    </div>
  </div>
  <div style="margin-bottom: -0.5cm;">&nbsp;</div>
 
</div>
  
</body>

</html>

