import { async } from '@angular/core/testing';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IpropService } from './../services/iprop.service';
import { AuthenService } from './../services/authen.service';
import { ParcelListModel, ParcelModel, ParcelStatusModel, ParcelListModelForExcel, ParcelListModelForCCRO } from './../models/parcel.module';
import { DashboardService } from './../services/dashboard.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TrandateModel } from '../models/trandate.module';
import { MockModel, ParcelModelMock2 } from '../models/mock.module';
import { ParcelItem } from '../models/item.module';
import { BarcodeFormat } from '@zxing/library';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { convert_dmy_TO_mdY } from './convertDatePicker';
import { AnandaMultiSite } from '../models/anandaauthen.module';
import { GenerateParcelNo } from '../models/generateparcelno.module';
import { ParcelOut } from '../models/parcelout.module';
import { IpropView } from '../models/ipropview.module';
import { ParcelService } from '../services/parcel.service';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "./format-datepicker";
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

// import { DeviceDetectorService } from "ngx-device-detector";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class HomeComponent implements OnInit {
  blob: Blob;
  _tokenFromServer = '';

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  _workingDelete = 0;
  _userAccount = '';
  _date = '';
  _month = '';
  _year = '';
  _time = '';

  _dateModify = '';
  _monthModify = '';
  _yearModify = '';
  _timeModify = '';

  _state = '0';
  _uniq = '';
  _box = '';
  _projectCode = '';
  _phoneno = '';
  _parcelModel: ParcelModelMock2[] = <any>[];
  parcelListModel: ParcelListModel[] = <any>[];
  parcelStatustModel: ParcelStatusModel[] = <any>[];
  imageSource: any;
  _gernerateParcelno: GenerateParcelNo[] = <any>[];
  _parcelGernerateNO = '0';
  _startFetchParcelGernerateNO = '0';
  _imageFromCamera: any = '-';
  _confirmImageFromCamera = '0';
  _oneSite = '-';
  _oneSiteParcelOut = '-';

  _checkVersionFromLocal = '';
  _checkVersionFromServer = '';
  _checkUniqidFromServer = '';
  _localUniqID = '';
  _lockButton = '0';
  _resultMatchingUniqId = '1';

  _parcelItem: ParcelItem = <any>[];

  _ipropMobileUser: IpropView[] = <any>[];

  randomIndex = 0;
  toggleVal = false;
  progressMode = false;
  progressModeCCRO = false;

  keyQrResult: any = '';
  scanResult: any = 'Waiting';
  scanResultCCRO: any = 'Waiting';


  _transport = '';
  rnumber: string = '';
  rprefixnumber = '';
  checkRoomNumber = '1';
  typeName = '';
  _save = 0;

  //Production
  _searchPrefixForm = 0;
  _parcelNOForm = 0;
  _roomLanding = '';
  _tmpSearchPrefixForm = '';
  _countMultiSiteOnHistory: number = 0;
  _anandaMultiSiteOnHistory: AnandaMultiSite[] = <any>[];
  _anandaMultiSite: AnandaMultiSite[] = <any>[];
  _prefixAddressRoom = 'บ้านเลขที่';// This is join from Authen * depend on Project
  _addCustomerName = '';
  _selectCustomerName = '';
  _selectParcelStatustModel = '0';

  _fullname = '';
  _countError = 3;
  _fetchCustomerStatus = 'close';
  _fetchCustomerDetail = [
    {
      'id': '001',
      'name': 'คุณ อนันดา คนดี',
    },
    {
      'id': '002',
      'name': 'คุณ อนันดา คนเก่ง',
    },
    {
      'id': '003',
      'name': 'คุณ อนันดา คนมีมารยาท',
    }
  ];

  transportList = [
    {
      'name': 'Thaipost',
      'img': '../../assets/images/trans/thaipost.webp'
    },
    {
      'name': 'Kerry',
      'img': '../../assets/images/trans/kerry.png'
    },
    {
      'name': 'J&T',
      'img': '../../assets/images/trans/jt.png'
    },
    {
      'name': 'Flash',
      'img': '../../assets/images/trans/flash.png'
    },
    {
      'name': 'Shoppee',
      'img': '../../assets/images/trans/shoppee.jpeg'
    },
    {
      'name': 'Lazada',
      'img': '../../assets/images/trans/lazada.png'
    },
    {
      'name': 'Ninja',
      'img': '../../assets/images/trans/ninja.png'
    },
    {
      'name': 'DHL',
      'img': '../../assets/images/trans/dhl.png'
    },
    {
      'name': 'Alpha',
      'img': '../../assets/images/trans/alpha.png'
    },
    {
      'name': 'Best',
      'img': '../../assets/images/trans/best.png'
    },
    {
      'name': 'SCG',
      'img': '../../assets/images/trans/scg.jpeg'
    },
    {
      'name': 'BEE',
      'img': '../../assets/images/trans/be.png'
    },
    {
      'name': 'JD',
      'img': '../../assets/images/trans/jd.png'
    },
    {
      'name': 'Nim',
      'img': '../../assets/images/trans/nim.png'
    },
    {
      'name': 'Other',
      'img': ''
    }

  ];
  parcelTyleList = [
    {
      'name': 'ซองเล็ก S',
      'sym': 'S',
      'img': 'mail'
    },
    {
      'name': 'ซองกลาง M',
      'sym': 'M',
      'img': 'mail'
    },
    {
      'name': 'ซองใหญ่ L',
      'sym': 'L',
      'img': 'mail'
    },

    {
      'name': 'ห่อเล็ก S',
      'sym': 'S',
      'img': 'tab'
    },
    {
      'name': 'ห่อกลาง M',
      'sym': 'M',
      'img': 'tab'
    },
    {
      'name': 'ห่อใหญ่ L',
      'sym': 'L',
      'img': 'tab'
    },

    {
      'name': 'กล่องเล็ก S',
      'sym': 'S',
      'img': 'work'
    },
    {
      'name': 'กล่องกลาง M',
      'sym': 'M',
      'img': 'work'
    },
    {
      'name': 'กล่องใหญ่ L',
      'sym': 'L',
      'img': 'work'
    },

    {
      'name': 'กล่องใหญ่พิเศษ XL',
      'sym': 'XL',
      'img': 'storefront'
    },

    {
      'name': 'กล่องใหญ่พิเศษ XXL',
      'sym': 'XXL',
      'img': 'storefront'
    },

    {
      'name': 'กระบอก',
      'sym': '',
      'img': 'ad_units'
    },
    {
      'name': 'ซองจดหมาย',
      'sym': '',
      'img': 'drafts'
    },
    {
      'name': 'อื่นๆ',
      'sym': '',
      'img': 'block'
    },

  ];

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_93,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODABAR,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.AZTEC,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION
  ];

  _startFetch = 0;
  _vmMobileNull = 0;
  _vmMobileNullOut = 0;


  @ViewChild('s', { static: false }) addSearchForm: NgForm;
  @ViewChild('prf', { static: false }) pacelRoomForm: NgForm;
  @ViewChild('addn', { static: false }) addNameForm: NgForm;
  @ViewChild('aqf', { static: false }) addQrForm: NgForm;

  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;
  detailsDevice = "";

  // 16 November 2023 Pm with Jane
  _extractAddName = '0';
  constructor(
    private _authenService: AuthenService,
    private _ipropService: IpropService,
    private _parcelService: ParcelService,
    private _snackBar: MatSnackBar,
    private datepipe: DatePipe, private router: Router, private dashboardService: DashboardService,
    private _bottomSheet: MatBottomSheet
  //  private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
   console.log(" ================= HOME ================");

    this._localUniqID = localStorage.getItem('_uniqId');
    console.log('this._localUniqID : ' + this._localUniqID);

    this.setDefaultDate();
    console.log(' this._globalDate : ' + this._globalDate);

    this._tokenFromServer = '';
    this._readyToReload = '0';

    this._startFetchParcelGernerateNO = '0';
    var _listProjectID = localStorage.getItem('_projectIds');
    var _user = localStorage.getItem("_userName");
    // console.log('UserAccount : ' + _user.toString());
    this._userAccount = _user;


    this._vmMobileNull = 0;
    this._workingDelete = 0;
    this._startFetch = 0;

    this.keyQrResult = '';

    const rndInt = Math.floor(Math.random() * 3) + 1
    this.randomIndex = rndInt;
    if (this.scanResult !== 'Waiting') {
      this.toggleVal = true;
    } else {
      this.toggleVal = false;
    }

    // console.log(_listProjectID);
    if (_listProjectID !== '') {
      // console.log('FOUND PROJECT');
      this.fetchMultiSiteOnHistory(_listProjectID);
    } else {
      console.log('NOT FOUND PROJECT');
    }

    this._checkVersionFromLocal = localStorage.getItem('_localversion');
    this.checkVersionNumberFromServer(this._checkVersionFromLocal);

    if (this._localUniqID !== '' && this._localUniqID !== null && this._localUniqID !== '0') {
      var _checkMatchingUniqID = this.checkUniqIDFromServer();
    }

    this._extractAddName = '0';

   // this. subScribeDeviceInfo();
  }

  // subScribeDeviceInfo() {
  //   try {
  //     this.deviceInfo = this.deviceService.getDeviceInfo();
  //     this.isMobile = this.deviceService.isMobile();
  //     this.isTablet = this.deviceService.isTablet();
  //     this.isDesktopDevice = this.deviceService.isDesktop();
  //     console.log(this.deviceInfo);
  //     console.log(this.isMobile);
  //     console.log(this.isTablet);
  //     console.log(this.isDesktopDevice);
  //     this.detailsDevice = "OS : (" + this.deviceInfo.os + ") , Browser : (" + this.deviceInfo.browser + ") , Mobile : (" + this.isMobile + ") , Tablet : ("
  //       + this.isTablet + ") , Desktop : (" + this.isDesktopDevice + ") ";
  //   } catch (error) { 
  //     console.log('Error Home subScribeDeviceInfo');
  //   }
  // }

  _gdate = '';
  _gmonth = '';
  _gyear = '';

  _dSTime = 'วันที่เริ่มต้น';
  _dETime = 'วันที่สิ้นสุด';
  _dSTimeOut = 'วันที่เริ่มต้น';
  _dETimeOut = 'วันที่สิ้นสุด';

  _dSTimeSelected = '';
  _dETimeSelected = '';

  _dSTimeSelectedOut = '';
  _dETimeSelectedOut = '';

  _globalDate = '';

  async checkVersionNumberFromServer(localVersion: any) {
    var _token = localStorage.getItem('_token');
    await this.dashboardService.fetchParcelVersion(_token).subscribe(item => {
      this._checkVersionFromServer = item[0].masterversion.toString();
      if (this._checkVersionFromServer !== localVersion) {
        console.log('NOT EQUAL');
        this._lockButton = '1';
      }
    })
  }

  setDefaultDate() {
    var datePipe = new DatePipe("en-US");
    var _vDate = datePipe.transform(new Date(), 'dd/MM/yyyy');
    var _vDateLength = _vDate.split('/');
    if (_vDateLength.length === 3) {
      var _date = _vDateLength[0];
      var _month = _vDateLength[1];
      var _year = <any>_vDateLength[2];
      this._globalDate = _date + '-' + _month + '-' + _year;
      this._dSTimeSelected = _date + '-' + _month + '-' + _year;
      this._dETimeSelected = _date + '-' + _month + '-' + _year;
      this._dSTimeSelectedOut = _date + '-' + _month + '-' + _year;
      this._dETimeSelectedOut = _date + '-' + _month + '-' + _year;
    }
  }

  checkDateNotSelected(): string {
    var datePipe = new DatePipe("en-US");
    var _vDate = datePipe.transform(new Date(), 'dd/MM/yyyy');
    var _vDateLength = _vDate.split('/');
    if (_vDateLength.length === 3) {
      var _date = _vDateLength[0];
      var _month = _vDateLength[1];
      var _year = <any>_vDateLength[2];
      return _year + '-' + _month + '-' + _date;
    }
  }

  convertDateToServer(item: any): string {
    var _item = item.split('-');
    if (_item.length === 3) {
      var _date = _item[0];
      var _month = _item[1];
      var _year = _item[2];
      if (_date.length === 2) {
        return _year + '-' + _month + '-' + _date;
      } else {
        return _date + '-' + _month + '-' + _year;
      }
    }
  }

  OnStartDateChange(itemS: any) {
    this._dSTimeSelected = '';
    this._dSTimeSelected = itemS;
  }

  _gdateE = '';
  _gmonthE = '';
  _gyearE = '';

  OnEndDateChange(itemS: any) {
    this._dETimeSelected = '';
    this._dETimeSelected = itemS;
  }

  onSelectPrefixSearch(item: any) {
    // console.log('onSelectPrefixSearch : ' + item);
    if (item === '0') {
      this._searchPrefixForm = 0
    } else {
      this._searchPrefixForm = item;
    }
  }

  fetchMultiSiteOnHistory(projectIds: any) {
    console.log('Teerat fetchMultiSiteOnHistory ..... ');
    
    this._authenService.fetchMultiSiteToAnanda(projectIds).subscribe(item => {
      if (item !== <any>'ERROR') {
        this._anandaMultiSiteOnHistory = [];
        this._anandaMultiSiteOnHistory.push({
          id: '0',
          projectnameen: 'เลือกโครงการ',
          projectnamethai: 'เลือกโครงการ',
          prefixhouse: '0'
        })

        item.forEach(element => {
          this._countMultiSiteOnHistory += 1;
          this._anandaMultiSiteOnHistory.push({
            id: element.id,
            projectnameen: element.projectnameen,
            projectnamethai: element.projectnamethai,
            prefixhouse: element.prefixhouse
          })
        });

        this.parcelStatustModel = [];
        // this.parcelStatustModel.push({
        //   val: '0',
        //   desc: '** เลือกสถานะพัสดุทั้งหมด'
        // });

        //New Requirement
        this.parcelStatustModel.push({
          val: 'All',
          desc: 'ทั้งหมด'
        });
        //New Requirement

        this.parcelStatustModel.push({
          val: 'Arrived',
          desc: 'พัสดุที่นำเข้า'
        });
        this.parcelStatustModel.push({
          val: 'Collected',
          desc: 'พัสดุที่จ่ายออก'
        });
        this._selectParcelStatustModel = 'All'; //Arrived

        // this.parcelStatustModel.push({
        //   val: 'Returned',
        //   desc: 'พัสดุที่ถูกลบ'
        // });

        //console.log('AnandaMultiSiteOnHistory');
        console.log(this._anandaMultiSiteOnHistory);
        // console.log('this._anandaMultiSiteOnHistory Length : ' + ( this._countMultiSiteOnHistory));
      } else {
        this.openErrorSnackBar();
        localStorage.clear();
        this.router.navigate(['/authen/']);
        return;
      }
    });
  }

  //Select Status
  onSelectParcelStatus(item: any) {
    this._selectParcelStatustModel = item;
    console.log('onSelectParcelStatus Event : ' + item);
  }

  //Btn Search Parcel Landing Page
  searchToParcelListModel() {
    console.log('Click searchToParcelListModel');

    this._dSTimeSelected = this.convertDateToServer(this._dSTimeSelected);
    this._dETimeSelected = this.convertDateToServer(this._dETimeSelected);

    this._parcelNOForm = this.addSearchForm.value.inputParcelNoForm;
    this._roomLanding = this.addSearchForm.value.roomlanding;
    this._roomLanding = this.reCheckDigits(this._roomLanding);

    this._tmpSearchPrefixForm = '0';
    this._tmpSearchPrefixForm = <any>this._searchPrefixForm;

    if (this._roomLanding !== '') {
      this._tmpSearchPrefixForm += "/" + this._roomLanding;
    }

    if (this._searchPrefixForm !== 0) {
      //Production
      this._startFetch = 2;
      this.parcelListModel = [];
      //fetchParcelFromAnanda ==> Previous Version 1
      //fetchParcelFromAnandaWithStatus  ==> Previous Version 2
      //_searchPrefixForm ====> _tmpSearchPrefixForm In JSON FORM

      // 10 Octeber 2023
      //Teerat 
      this.dashboardService.fetchParcelFromAnandaWithStatusAndDate
        (this._tmpSearchPrefixForm, <any>this._parcelNOForm,
          <any>this._selectParcelStatustModel, this._dSTimeSelected, this._dETimeSelected)
        .subscribe(item => {
          if (item === <any>'ERROR') {
            this.openErrorSnackBar();
            localStorage.clear();
            this.router.navigate(['/authen/']);
            return;
          } if (item !== null && item.length > 0) {
            this.parcelListModel = item;
            console.log(this.parcelListModel);
            //Print Search
            // console.log('searchToParcelListModel');
            // console.log( this.parcelListModel);
            //Print Search

            this.parcelListModel.forEach(element => {
              // console.log(element.imageblob);
              //  console.log(element.imageblob?.toString());   
              if (element.imageblob === '' || element.imageblob === null) {
                element.mapimageblob = '-';
              } else {
                element.mapimageblob = element.imageblob.toString();
              }

              var _dateCrated = element.creationiime;
             // console.log('')
              //console.log("Teerat _dateCrated ID : (" + element.id.toString() + ") : "  + _dateCrated);

              if (_dateCrated !== null) {
                var _resultKeyInDate = this.recoveryTime(new Date(element.creationiime));
                // var _datePipe = new Date(element.creationiime);
               
                // var _datePipeOut = new Date(element.lastmodificationtime);
                // var _convertGMT = new Date(_datePipe.toString().replace("GMT+0700", "GMT-0700")).toUTCString();
                // var _convertGMTOut = new Date(_datePipeOut.toString().replace("GMT+0700", "GMT-0700")).toUTCString();
                // var pipe = new DatePipe('en-US');
                // const myFormattedDateIN = pipe.transform(_datePipe, 'h:mm:ss a');
                // //Wrong
                // // console.log("Teerat extractTime : : (" + element.id.toString() + ") : "  + extractTime.toString());
                // //Wrong
                // var extractTime = new Date(<any>_convertGMT);
                //  //Wrong
                // // console.log("Teerat extractTime : : (" + element.id.toString() + ") : "  + extractTime.toString());
                // //Wrong

              

                // var extractH = extractTime.getUTCHours();//Got Time H
                // var extractM = extractTime.getUTCMinutes().toString();

                // if (extractM.length == 1) {
                //   extractM = "0" + extractM;
                // }
                // _dateCrated = <any>this.datepipe.transform(_dateCrated, 'dd-MM-yyyy'); //Re Format
                // var _extractDMY = _dateCrated.toString().split("-"); //Split Array
                // this._date = _extractDMY[0]; //GetDate
                // var month = _extractDMY[1]; //GetMonth
                // this._month = this.convertTOThaiMonth(month); // Change To Thai Language
                // this._year = _extractDMY[2];  //GetYear
 
                element.mapcreationtodate = _resultKeyInDate;
              } else {
                element.mapcreationtodate = '';
              }



              // 10 Octeber 2023
              //Teerat 
              var _qrUpdate = element.qrcode;
              // In the Future Use Logic <============
              // 10 Octeber 2023
              //Teerat 



              var _dateModifyMaster = element.lastmodificationtime;
              var _noApp = element.installapp;
             
             
              if (_dateModifyMaster !== null) {

                if (_noApp === null || _noApp === 'NULL' || _noApp === '0' || _noApp === <any>0) {

                  const date = new Date(element.lastmodificationtime);
                  const _addHoursForNoAPP = this.newAddHoursToDate(date, 7);
                  var _resultKeyOutDate = this.recoveryTime(new Date(_addHoursForNoAPP));
                  console.log('===============================================');
                  console.log('We Found No Install App ==> ID : ' + element.id)
                  console.log('We Convert (' + element.lastmodificationtime + ') : ' + _resultKeyOutDate);
                  console.log('===============================================');
                  element.maplastmodifytodate = _resultKeyOutDate;
                } else {
                  // var _datePipeModify = new Date(element.lastmodificationtime);
                  // var _convertGMT = new Date(_datePipeModify.toString().replace("GMT+0700", "GMT-0700")).toUTCString();
                  // var extractTime = new Date(<any>_convertGMT);
                  // var extractH = extractTime.getUTCHours();//Got Time H
                  // var extractM = extractTime.getUTCMinutes().toString();
                  // if (extractM.length == 1) {
                  //   extractM = "0" + extractM;
                  // }
                  // _dateModifyMaster = <any>this.datepipe.transform(_dateModifyMaster, 'dd-MM-yyyy'); //Re Format
                  // var _extractDMYModify = _dateModifyMaster.toString().split("-"); //Split Array
                  // this._dateModify = _extractDMYModify[0]; //GetDate
                  // var monthModify = _extractDMYModify[1]; //GetMonth
                  // this._monthModify = this.convertTOThaiMonth(monthModify); // Change To Thai Language
                  // this._yearModify = _extractDMYModify[2];  //GetYear
                  // this._timeModify = extractH + ':' + extractM;//Map Time
                  var _resultKeyOutDate = this.recoveryTime(new Date(element.lastmodificationtime));
                  element.maplastmodifytodate = _resultKeyOutDate;
                }
              }
              else {
                element.maplastmodifytodate = '';
              }
            });
            setTimeout(() => {
              this._startFetch = 0;
            }, 1500);
          } else {
            this._startFetch = 1;
          }
        });
    }
  }

 

  //JS
  //Teerat Mon 14  November 2023
  newAddHoursToDate(date, hours) {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }


  //Teerat Mon 13 November 2023
  recoveryTime(_time:any) : string  {
    var returnValue = ''

    try
    {
      var _datePipe = new Date(_time);
      if(_datePipe !== null) {
        var _datePipe = new Date(_time);
        var _convertGMT = new Date(_datePipe.toString().replace("GMT+0700", "GMT-0700")).toUTCString();
        var pipe = new DatePipe('en-US');
        const myFormattedDate = pipe.transform(_datePipe, 'h:mm a');
        var extractTime = new Date(<any>_convertGMT);
        var extractH = extractTime.getUTCHours();//Got Time H
        var extractM = extractTime.getUTCMinutes().toString();
        if (extractM.length == 1) {
          extractM = "0" + extractM;
        }
        var _datePipeExtractDMY_ONLY =_time;
        _datePipeExtractDMY_ONLY = <any>this.datepipe.transform(_time, 'dd-MM-yyyy'); //Re Format
        var _extractDMY = _datePipeExtractDMY_ONLY.toString().split("-"); //Split Array
        this._date = _extractDMY[0]; //GetDate
        var month = _extractDMY[1]; //GetMonth
        this._month = this.convertTOThaiMonth(month); // Change To Thai Language
        this._year = _extractDMY[2];  //GetYear
        //console.log('recoveryTimeProduction : ' + this._date + ' ' + this._month + ' ' + this._year + ' เวลา ' + myFormattedDate );
        returnValue   = this._date + ' ' + this._month + ' ' + this._year + ' เวลา ' + myFormattedDate.replace("AM", "(AM)").replace("PM", "(PM)");
        return returnValue;
      }else {
        returnValue = '';
        //console.log('recoveryTimeProduction : ' +returnValue );
        return returnValue;
      }
    }catch(error) {
      returnValue = '';
      //console.log('recoveryTimeProduction : ' +returnValue );
      return returnValue;
    }
  }


  displayStyle = "none";
  displayID = '';
  displayParcelNo = '';

  viewImage(item1: any, item2: any, item3: any) {
    // console.log('viewImage');
    var _img64: string = <any>item2;
    _img64 = _img64.toString()
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");

    this.displayID = item1;//ID
    this.displayParcelNo = item3;//ParcelNo
    this.displayStyle = "block";
    const canvas = document.querySelector('#canvas') as HTMLCanvasElement;
    const reader = new FileReader();
    const imageBlob = this.dataURItoBlob(_img64);//ImageBlob
    const imageFile = new File([imageBlob], 'canvasparcel', { type: 'image/jpg' });
    console.log('imageFile Size : ' + imageFile.size);
    reader.onload = (e) => {
      const contextOfCanvas = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        //  canvas.width = 340;
        canvas.height = 300;
        contextOfCanvas.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async (blob) => {
          this.blob = blob;
        }, 'image/jpg');
      };
      img.src = item2;
    };
    reader.readAsDataURL(imageFile);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpg' });
    return blob;
  }

  closePopup() {
    this.displayStyle = "none";
  }

  //Click Icon Delete
  _itemJsonForDelete: ParcelListModel[] = <any>[];
  setRetutnedParcel(parcelSelected: any) {
    console.log('setRetutnedParcel : Click Delete');
    var _id = parcelSelected;
    var _jsonForDelete = this.parcelListModel.find(item => item.id === _id);
    this._itemJsonForDelete.push(_jsonForDelete);
    this._workingDelete = 1;
  }

  cancelDeleteParcel() {
    this._workingDelete = 0;
    this._itemJsonForDelete = [];
  }

  deleteParcel(item: any) {
    console.log('deleteParcel');
    var _user = localStorage.getItem("_userName");
    this._workingDelete = 2;
    var _updateImageNullForm = <any>[];
    _updateImageNullForm = [{
      id: item.id
    }];

    this.dashboardService.PostToReturnParcel(_user, item.id).subscribe(item => {
      if (item !== <any>'ERROR') {
        this.dashboardService.PostUpdateImageNull(_updateImageNullForm).subscribe(itemUpdate => {
          if (item === <any>'ERROR') {
            this.openErrorSnackBar();
            localStorage.clear();
            this.router.navigate(['/authen/']);
            return;
          } else {
            _updateImageNullForm = [];
            // console.log(itemUpdate);
            setTimeout(() => {
              this._workingDelete = 0;
              window.location.reload();
            }, 1200);
          }
        });


      } else {
        this._workingDelete = 0;
        this._itemJsonForDelete = [];
      }
    });
  }

  cancelScan() {
    window.location.reload();
  }

  //Click Add Btn and Call Generate Max Paecel NO 
  scanQRCode() {
    console.log('Click Start scanQRCode.....')
    // Close Service
    return;



    var _user = localStorage.getItem('_userName');
    this._state = '1';
    this._startFetchParcelGernerateNO = '1';

    this.dashboardService.fetchParcelGenerateNo(_user).subscribe(item => {
      this._gernerateParcelno = [];
      if (item === <any>'ERROR') {
        this.openErrorSnackBar();
        localStorage.clear();
        this.router.navigate(['/authen/']);
        return;
      } else {
        this._gernerateParcelno = item;
        console.log(this._gernerateParcelno);
        this._parcelGernerateNO = this._gernerateParcelno['parcelnumber'];
        //Pharse 2
        console.log('Log ParcelGernerateNO : ' + this._parcelGernerateNO);
        if (this.scanResult === 'Waiting'
          && this._parcelGernerateNO !== '0'
          && this._gernerateParcelno !== null) {
          this._startFetchParcelGernerateNO = '0';
          this._parcelItem = {
            uniq: '',
            scanqr: '',
            state: '1',
            transport: '',
            box: ''
          };
          sessionStorage.setItem('uniq', '');
          sessionStorage.setItem('scanqr', '');
          sessionStorage.setItem('state', '1');
          sessionStorage.setItem('transport', '');
          sessionStorage.setItem('box', '');
          this._startFetchParcelGernerateNO = '0';
        } else {
          window.location.reload();
        }
        //Pharse 2
      }
    })
  }

  ////PC180-0712-2022-00001:QR001
  GetQrCode() {
    var _keyQrResult: string = this.addQrForm.value.keyQrResult;
    console.log('GetQrCode : ' + _keyQrResult);
    this.keyQrResult = _keyQrResult.replace("@","")
    .replace("*","").replace("$","").replace("%","").replace("#","")
    .replace("!","").replace("&","").replace("(","").replace(")","")
    .replace("=","").replace("+","").replace("♪","").replace("♪","").replace("ๅ","") 
    .replace(";","").replace("?","").replace("`","")
    .replace("~","").replace("^","").replace("<","").replace(">","")
    .replace("{","").replace("}","").replace("[","").replace("]","")
    .replace("//","").replace("/","").replace("\\","").replace("฿","")
    .replace("ก","").replace("ข","").replace("ฃ","")
    .replace("ค","").replace("ต","")
    .replace("ฆ","").replace("ง","").replace("จ","")
    .replace("ฉ","").replace("ช","").replace("ซ","")
    .replace("ฌ","").replace("ญ","").replace("ฎ","")
    .replace("ฏ","").replace("ฐ","")
    .replace("ฑ","").replace("ฒ","")
    .replace("ณ","").replace("ด","")
    .replace("ต","").replace("ถ","").replace("ท","")
    .replace("ธ","").replace("น","").replace("บ","").replace("ป","")
    .replace("ผ","").replace("ฝ","").replace("พ","").replace("ฟ","")
    .replace("ภ","").replace("ม","").replace("ย","").replace("ร","")
    .replace("ล","").replace("ว","").replace("ศ","").replace("ษ","")
    .replace("ส","").replace("ห","").replace("ฬ","").replace("อ","")
    .replace("ฮ","").replace(/<img[^>]*>/g,"")
    .toString();

    if (this.keyQrResult !== '' && this.keyQrResult !== null) {
      const rndInt = Math.floor(Math.random() * 3) + 1
      this.randomIndex = rndInt;

      this.scanResult = this.reCheckDigits(this.keyQrResult);// 'XE41Y-16KDEV'
      this._uniq = this.makeUniq() + this._parcelGernerateNO;
      sessionStorage.setItem('uniq', this._uniq);
      sessionStorage.setItem('scanqr', this.scanResult);
      if (this.scanResult !== 'Waiting') {
        this.progressMode = true;
        setTimeout(() => {
          this.progressMode = false;
        }, 800);
      }
    }
    else {
      return;
    }
  }

  ////PC180-0712-2022-00001:QR001
  //RESULT IS SCAN RESULT
  onCodeResult(result: string) {
    const rndInt = Math.floor(Math.random() * 3) + 1
    this.randomIndex = rndInt;

    this.scanResult = this.reCheckDigits(result);// 'XE41Y-16KDEV'
    this._uniq = this.makeUniq() + this._parcelGernerateNO;

    sessionStorage.setItem('uniq', this._uniq);
    sessionStorage.setItem('scanqr', this.scanResult);

    if (result !== 'Waiting') {
      this.progressMode = true;
      setTimeout(() => {
        this.progressMode = false;
      }, 800);
    }
  }

  //ShowTransport
  onSelectTransportCompany() {
    this._state = '2';

    this._parcelItem = {
      uniq: this._uniq,
      scanqr: this.scanResult,
      state: this._state,
      transport: '',
      box: ''
    };

    sessionStorage.setItem('state', '2');
    sessionStorage.setItem('box', '');
    sessionStorage.setItem('transport', '');

  }

  //Add Call Iprop 
  selectedTransport(item: any) {
    console.log('selectedTransportBTN');
    this._transport = item.name;
    this.callITokenFromServer();
    this._state = '2plus';//3

    this._parcelItem = {
      uniq: this._uniq,
      scanqr: this.scanResult,
      state: this._state,
      transport: this._transport,
      box: ''
    };

    sessionStorage.setItem('state', '2plus');//3
    sessionStorage.setItem('transport', this._transport);
    sessionStorage.setItem('box', '');
  }

  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  async openCamera(event) {
    try {
      const file = event.target.files[0];

      if (file != null) {
        const canvas = document.querySelector('#canvas') as HTMLCanvasElement;

        const reader = new FileReader();
        reader.onload = (e) => {
          // console.log(e)
          this._imageFromCamera = '-';
          var dataURL = e.target.result as string;

          console.log('ACCEPT CAMERA');
          this._confirmImageFromCamera = '1';
          // this._imageFromCamera = dataURL;
          console.log(file.size);
          this.compressImage(dataURL, 120, 120).then(compressed => {
            this._imageFromCamera = compressed;
          })
          // this._imageFromCamera = dataURL;

          const contextOfCanvas = canvas.getContext('2d');
          const img = new Image();

          img.onload = () => {
            canvas.width = 330;
            canvas.height = 350;
            contextOfCanvas.drawImage(img, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(async (blob) => {
              //  console.log(blob);
              this.blob = blob;
            }, 'image/jpg');
          };
          img.src = dataURL;
        };
        reader.readAsDataURL(file);
      } else {
        console.log('DONOT CAMERA');
        this._confirmImageFromCamera = '0';
        this._imageFromCamera = '-';

        const canvas = document.querySelector('#canvas') as HTMLCanvasElement;
        const contextOfCanvas = canvas.getContext('2d');
        const img = new Image();
        img.onload = () => {
          canvas.width = 330;
          canvas.height = 350;
          contextOfCanvas.drawImage(img, 0, 0, canvas.width, canvas.height);
          //console.log(canvas)
          canvas.toBlob(async (blob) => {
            //  console.log(blob);
            this.blob = blob;
          }, 'image/jpg');
        };
        // For Else We Waiting For Default Parcel Image ? ** Discuss With Team
        // img.src = ''   ====> ** NOW Clear Display First
        img.src = '';
      }
    } catch (err) {
      this._confirmImageFromCamera = '0';
      this._imageFromCamera = '-';
    }
  }

  onConfirmCamera(item: any) {
    if (item === '0') {
      //console.log('Clear Image : ' + item);
      this._state = '3';//3
      this._confirmImageFromCamera = '0';
      this._imageFromCamera = '-';
    }
    if (item === '1') {
      //console.log('Confirm Image : ' + item);
      this._confirmImageFromCamera = '1';
      this._state = '3';//3
      // console.log(this._imageFromCamera);
    }
  }

  //2 Events
  //Get ParcelType = ParcelPackage ==> Call Api MultiSite To ddl  
  //Should be ReUse After New Year
  getParcelTyle(item: any) {
    this._state = '4';
    var _item = item;
    sessionStorage.setItem('state', '4');
    sessionStorage.setItem('box', _item.name);


    //Load PrefixNumber Dropdownlist
    var _projectIds = localStorage.getItem('_projectIds');
    this._authenService.fetchMultiSiteToAnanda(_projectIds).subscribe(item => {
      if (item !== <any>'ERROR') {
        this._anandaMultiSite = [];
        if (item.length === 1) {

          this._anandaMultiSite.push({
            id: '0',
            projectnameen: '',
            projectnamethai: '',
            prefixhouse: 'บ้านเลขที่'
          })

          item.forEach(element => {
            this._oneSite = element.prefixhouse;
            this._prefixAddressRoom = this._oneSite;
            this._anandaMultiSite.push({
              id: element.id,
              projectnameen: element.projectnameen,
              projectnamethai: element.projectnamethai,
              prefixhouse: element.prefixhouse
            })
          });

        } else {
          this._oneSite = '-';
          this._anandaMultiSite.push({
            id: '0',
            projectnameen: '',
            projectnamethai: '',
            prefixhouse: 'บ้านเลขที่'
          })

          item.forEach(element => {
            this._anandaMultiSite.push({
              id: element.id,
              projectnameen: element.projectnameen,
              projectnamethai: element.projectnamethai,
              prefixhouse: element.prefixhouse
            })
          });
        }

      } else {
        this.openErrorSnackBar();
        localStorage.clear();
        this.router.navigate(['/authen/']);
        return;
      }
    });
    //Load PrefixNumber Dropdownlist

  }

  //Step Select From MultiSite
  onSelectPrefixnumber(item: any) {
    //console.log('onSelectPrefixnumber');
    if (item !== 'บ้านเลขที่') {
      this._prefixAddressRoom = item;
    } else {
      this._prefixAddressRoom = 'บ้านเลขที่';
      return;
    }
  }

  makeUniq() {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(new Date(), 'dd/MM/yyyy');
    var _extract = formattedDate.split("/");
    var _userID = localStorage.getItem('_UserId');
    //PC-2011-2022-000000(RUNNING NO)
    return "PC" + _userID + "-" + _extract[0] + _extract[1] + _extract[2] + '-';//  this.makeid(4);
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onClearVMNotFound() {
    this._vmMobileNull = 0;
    this.checkRoomNumber = '1';
  }

  _addExtraName = '';
  _startConfirm = '0';

  //Save To NewParcel With No House
  async onConfirmVMNotFound() {
    console.log('onConfirmVMNotFound No Register House....');
    setTimeout(() => {
      this._startConfirm = '1';
    }, 100);
    this._keepLocation = this.reCheckDigits(this._keepLocation);
    if (this._keepLocation !== '') {
      this._keepLocation = ' (' + this._keepLocation.replace(":", "").replace("::", "") + ')';
    }
    this.scanResult = this.reCheckDigits(this.scanResult);
    this._save = 1;
    var _user = localStorage.getItem("_userName");
    var _from = {
      "property_code": '000000',
      "unit_no": this.rnumber,
      "tracking_code": this._uniq + ":" + this.scanResult,
      "message": "ParcelWeb",
      "image_url": "https://parcel-uat.cocoro.ai/assets/images/Newparcel.jpeg",
      "status": "new",
      "user_type": _user,
      "receiver_name": this._addExtraName,
      "receiver_phone_number": '0000000000', // Auto Insert PhoneNumber
      "express_company": this._transport + ":" + this._box + this._keepLocation.replace(":", "").replace("::", "") + ""
    }


    if (this.scanResult === '-' || this.scanResult === ' ' || this.scanResult === '') {
      window.location.reload();
    }

    if (this._tokenFromServer !== '') {

      console.log('KEY REPLACE : ' + this.scanResult);
      console.log('Start Save')

      //Step 1.
      if (this._imageFromCamera === '-' || this._confirmImageFromCamera !== '1') {
        console.log('Saving No Image...')
        let _saveToDBAoneBackEnd = await this.saveToDBAoneBackEnd(this._tokenFromServer, _from);
        let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "0", "NONE");
        setTimeout(() => {
          this._save = 0;
        }, 50);
        this._state = '6';
        sessionStorage.setItem('state', '6');
        setTimeout(() => {
          window.location.reload();
        }, 150);
      }

      //Step 2.
      if (this._imageFromCamera !== '-' && this._confirmImageFromCamera === '1') {
        console.log('Saving With Image...')
        let _saveToDBAoneBackEnd = await this.saveToDBAoneBackEnd(this._tokenFromServer, _from);
        let _setToUpdateWithImage = await this.saveToDBWithImage(_user, this._uniq + ":" + this.scanResult, this._imageFromCamera);
        let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "0", "NONE");
        setTimeout(() => {
          this._save = 0;
        }, 50);
        this._state = '6';
        sessionStorage.setItem('state', '6');
        setTimeout(() => {
          window.location.reload();
        }, 150);
      }
      console.log('End Save');
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 150);
    }
  }

  onChangeNameExtra(item: any) {

    this.typeName = 'add';
    if (item !== '' && item !== null && item !== ' ') {
      this.typeName = 'add';
      this._addExtraName = item
      //this._addCustomerName = item;
      this._selectCustomerName = '';
      sessionStorage.setItem('selectCustomerName', this._addCustomerName);
      sessionStorage.setItem('typeName', 'add');
    } else {
      this.typeName = '';
      this._addExtraName = ''
      //this._addCustomerName = item;
      this._selectCustomerName = '';
      sessionStorage.setItem('selectCustomerName', '');
      sessionStorage.setItem('typeName', '');

    }
  }

  //vmMobileUser by UnitCode 26/003
  onFindCustomer() {
    //console.log('onFindCustomer');
    var _rprefixnumber = this._prefixAddressRoom;
    var _rnumber = this.pacelRoomForm.value.rnumber;

    if (_rnumber === '' || _rnumber === ' ' || _rnumber === null || _rprefixnumber === 'บ้านเลขที่') {
      this.checkRoomNumber = '0';
      setTimeout(async () => {
        this._countError = await 2;
      }, 1000);
      setTimeout(async () => {
        this._countError = await 1;
      }, 2000);
      setTimeout(async () => {
        this._countError = await 3;
        this.checkRoomNumber = '1';
      }, 3000);
      this._countError = 3;
      this._prefixAddressRoom = 'บ้านเลขที่';
      return;
    }
    else {
      this._fetchCustomerStatus = 'open';
      this.checkRoomNumber = '1';
      this.rnumber = _rprefixnumber + '/' + _rnumber;
      sessionStorage.setItem('address', _rprefixnumber + '/' + _rnumber);

      //_fetchCustomerDetail
      //Fetch API vmMobileUser by UnitCode 26/003
      //http://localhost:8081/api/RecordParcel/fetchVmMobileUser

      if (this.rnumber !== '') {
        this._ipropMobileUser = [];

        console.log('Before Call iProp');

        this._ipropService.PostToIprop(this.rnumber).subscribe(item => {

          if (item === <any>'ERROR') {
            this.openErrorSnackBar();
            localStorage.clear();
            this.router.navigate(['/authen/']);
            return;
          }

          console.log('Sent To API Server.');

          if (item !== null && item.length > 0) {

            console.log('===========> NEW fetchMOBILE')
            console.log(item);
            console.log('===========> NEW fetchMOBILE')

            console.log('Done...');
            this._ipropMobileUser = item;
            console.log('customer_id (Row First) : ' + this._ipropMobileUser[0].customer_id);
            var _customerID = <any> this._ipropMobileUser[0].customer_id;
            this._projectCode = this._ipropMobileUser[0].projectcode;
            console.log('projectcode : ' + this._ipropMobileUser[0].projectcode);
            sessionStorage.setItem('ipropCustomerID', _customerID);
            sessionStorage.setItem('projectcode', this._projectCode);
          }
          else {
            // <!-- NEW REQUIREMENT MAP home.ts -->
            this._box = sessionStorage.getItem('box');
            this._vmMobileNull = 9; //NOT FOUND
            this._fetchCustomerStatus = 'close';
            this.checkRoomNumber = '9';
            // <!-- NEW REQUIREMENT MAP home.ts -->
            return;

            this._vmMobileNull = 1; //NOT FOUND
            this._fetchCustomerStatus = 'close';
            this.checkRoomNumber = '0';
            setTimeout(async () => {
              this._countError = await 2;
            }, 1000);
            setTimeout(async () => {
              this._countError = await 1;
            }, 2000);
            setTimeout(async () => {
              this._countError = await 3;
              this._vmMobileNull = 0; //NOT FOUND And Clear Value
              this.checkRoomNumber = '1';
            }, 3000);

            this._countError = 3;
            // Default Code with this._countError = 3;
            // this._prefixAddressRoom  = 'บ้านเลขที่';
            // Default Code with this._countError = 3;

            //Add on Check Logic
            if (this._oneSite !== '-') {
              this._prefixAddressRoom = this._oneSite;
            } else {
              this._prefixAddressRoom = 'บ้านเลขที่';
            }
            //Add on Check Logic
            return;
          }

        });
      }
    }
  }

  _keepLocation = '';
  onChangeLocation(item: any) {
    this._keepLocation = item;
    // console.log(' this._keepLocation : ' +  this._keepLocation);
  }

  resetSearch() {
    //Add On
    if (this._oneSite !== '-') {
      this._prefixAddressRoom = this._oneSite;
    } else {
      this._prefixAddressRoom = 'บ้านเลขที่';
    }
    //Add On


    //Default Code
    // this._prefixAddressRoom = 'บ้านเลขที่';
    //Default Code
    this._fetchCustomerStatus = 'close';
    this._ipropMobileUser = [];
  }

  onChangeName(item: any) {
    if (item !== '' && item !== null && item !== ' ') {
      this.typeName = 'add';
      this._addCustomerName = item;
      this._selectCustomerName = '';
      sessionStorage.setItem('selectCustomerName', this._addCustomerName);
      sessionStorage.setItem('typeName', 'add');
    }
  }

  async checkUniqIDFromServer(): Promise<any> {
    var _user = localStorage.getItem("_userName");
    
    try {

      setTimeout( async() => {
        let _checkUniqIDFromServer = await this.dashboardService.validLoginDuplicate(_user).toPromise();
        console.log('_checkUniqIDFromServer');
        if (_checkUniqIDFromServer[0].uniqid !== this._localUniqID) {
          this._resultMatchingUniqId = '0';
          console.log('UNIQID : NOT EQUAL');
          console.log('UNIQID SERVER : ' + _checkUniqIDFromServer[0].uniqid);
          console.log('UNIQID LOCAL : ' + this._localUniqID);
        }
        else {
          this._resultMatchingUniqId = '1';
          console.log('UNIQID : EQUAL');
          console.log('UNIQID SERVER : ' + _checkUniqIDFromServer[0].uniqid);
          console.log('UNIQID LOCAL : ' + this._localUniqID);
  
        }
        return _checkUniqIDFromServer;
      }, 150);
    }
    catch (error) {
      window.location.reload();
    }
  }

  //This is Big Point
  selectCustomer(item: any) {

    //sessionStorage.setItem('ipropCustomerID', _customerID);

    console.log('Teerat selectCustomer');
    console.log(item);

    var _extractIDFromButtomn  = item.customer_id;
    console.log('Click Real Customer_ID : ' + _extractIDFromButtomn);
    sessionStorage.setItem('ipropCustomerID', _extractIDFromButtomn);
    
    this._checkVersionFromLocal = localStorage.getItem('_localversion');
    this.checkVersionNumberFromServer(this._checkVersionFromLocal);
    console.log(' this._lockButton : ' + this._lockButton);

    var _checkMatchingUniqID = this.checkUniqIDFromServer();

    this.typeName = 'select';

    this._state = '5'
    this._phoneno = item.phoneno;
    this._selectCustomerName = item.customername;
    this._addCustomerName = '';
    sessionStorage.setItem('selectCustomerName', item.customername);
    sessionStorage.setItem('typeName', 'select');

    sessionStorage.setItem('state', '5');
    this._uniq = sessionStorage.getItem('uniq');
    this.scanResult = sessionStorage.getItem('scanqr');
    this._transport = sessionStorage.getItem('transport');
    this._box = sessionStorage.getItem('box');
    this.rnumber = sessionStorage.getItem('address').toString().replace(":", "/").toString();
    this._fullname = this._selectCustomerName;
  }

  //This is Big Point
  // Teerat Add Name : From Button 16 November 2023
  btnAddName() {
    
     // Teerat Add Name : From Button 16 November 2023 PM
    console.log('Teerat =====> btnAddName');
  // Teerat Add Name : From Button 16 November 2023 PM

    this._checkVersionFromLocal = localStorage.getItem('_localversion');
    this.checkVersionNumberFromServer(this._checkVersionFromLocal);
    console.log(' this._lockButton : ' + this._lockButton);

    var _checkMatchingUniqID = this.checkUniqIDFromServer();


    this._state = '5';
    sessionStorage.setItem('state', '5');
    this._box = sessionStorage.getItem('box');
    this.typeName = sessionStorage.getItem('typeName');
    var _nameCustomer = sessionStorage.getItem('selectCustomerName');
    this._extractAddName = '1';

    console.log('btnAddName : ' + _nameCustomer.toString() ) ;
    this._fullname = _nameCustomer;
  }

  cancelToDB() {
    window.location.reload();
  }

  reCheckDigits(str: any) {
    var _str: string = str;
    _str = _str.toString()
      .replace("http://", "").replace("//", "").replace("/", "").replace("://", "")
      .replace("http", "")
      .replace(",", "").replace(".", "")
      .replace(" ", "").replace(":", "").replace(" :", "").replace(": ", "").replace(" : ", "").replace(" ", "")
      .replace("http//", "").replace("//", "").replace("/", "")
      .replace("wwww,.", "").replace("#", "").replace("@", "").replace("%", "").replace("^", "")
      .replace("*", "").replace("!", "").replace("$", "")
      .replace("'", "").replace("''", "").replace('"', "").replace('""', "")
      .replace("http://www.", "").replace("http://www", "")
      .replace(".com", "").replace(".ac.th", "").replace("ac.th", "")
      .replace(".co.th", "").replace("co.th", "")
      .replace("&", "").replace(";", "");
    ;
    return _str;
  }

  async callITokenFromServer() {
    await this._parcelService.getToken().subscribe(item => {
      if (item !== <any>'ERROR') {
        var _tokenFromIntegration = item.result;
        if (_tokenFromIntegration !== '' && _tokenFromIntegration !== null) {
          this._tokenFromServer = _tokenFromIntegration.token;
          console.log('token From Server YES : ' + this._tokenFromServer);
        } else {
          console.log('token From Server NO');
        }
      }
      else {
        this.openErrorSnackBar();
        localStorage.clear();
        this.router.navigate(['/authen/']);
        return;
      }
    });
  }

  //Save To Sever New Parcel
  async saveToDB() {
    this._keepLocation = this.reCheckDigits(this._keepLocation);
    if (this._keepLocation !== '') {
      this._keepLocation = ' (' + this._keepLocation.replace(":", "").replace("::", "") + ')';
    }
    this.scanResult = this.reCheckDigits(this.scanResult);
    this._save = 1;
    var _user = localStorage.getItem("_userName");
    var mapCustomerID = sessionStorage.getItem('ipropCustomerID');

    if(this._phoneno === '' || this._phoneno === null ) {
       mapCustomerID = "0";
    }

    var _from = {
      "customer_id": mapCustomerID, // Eak handle 0 Already at Backend
      "property_code": this._projectCode,
      "unit_no": this.rnumber,
      "tracking_code": this._uniq + ":" + this.scanResult,
      "message": "ParcelWeb",
      "image_url": "https://firebasestorage.googleapis.com/v0/b/anandaforlab.appspot.com/o/Newparcel.jpeg?alt=media&token=fd572302-b8cf-40be-9a7e-e32fa72983db",
      "status": "new",
      "user_type": _user,
      "receiver_name": this._fullname,
      "receiver_phone_number": this._phoneno,
      "express_company": this._transport + ":" + this._box + this._keepLocation.replace(":", "").replace("::", "") + ""
    }

    if (this.scanResult === '-' || this.scanResult === ' ' || this.scanResult === '') {
      window.location.reload();
    }

    if (this._tokenFromServer !== '') {

      console.log('KEY REPLACE : ' + this.scanResult);
      console.log('Start Save')

      //Step 1.
      if (this._imageFromCamera === '-' || this._confirmImageFromCamera !== '1') {
        console.log('Saving No Image...')
        let _saveToDBAoneBackEnd = await this.saveToDBAoneBackEnd(this._tokenFromServer, _from); //NEW PARCEL
        //let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "1", "NONE");
        if( this._extractAddName === '1') {
          console.log("Teerat No Install App 1");
          let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "0", "NONE");
        }
        if(this._extractAddName === '0') {
          console.log("Teerat Install App 1");
          let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "1", "NONE");
        }
        setTimeout(() => {
          this._save = 0;
        }, 50);
        this._state = '6';
        sessionStorage.setItem('state', '6');
        setTimeout(() => {
          window.location.reload();
        }, 150);
      }

      //Step 2.
      if (this._imageFromCamera !== '-' && this._confirmImageFromCamera === '1') {
        console.log('Saving With Image...')
        let _saveToDBAoneBackEnd = await this.saveToDBAoneBackEnd(this._tokenFromServer, _from); //NEW PARCEL
        let _setToUpdateWithImage = await this.saveToDBWithImage(_user, this._uniq + ":" + this.scanResult, this._imageFromCamera);
        //let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "1", "NONE");
        if( this._extractAddName === '1') {
          console.log("Teerat No Install App 2");
          let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "0", "NONE");
        }
        if(this._extractAddName === '0') {
          console.log("Teerat Install App 2");
          let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, this._uniq + ":" + this.scanResult, "1", "NONE");
        }        setTimeout(() => {
          this._save = 0;
        }, 50);
        this._state = '6';
        sessionStorage.setItem('state', '6');
        setTimeout(() => {
          window.location.reload();
        }, 150);
      }
      console.log('End Save');
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 150);
    }
  }

  async saveToDBAoneBackEnd(tokenFromServer: any, from: any) {
    try {
      //let saveToDBAoneBackEnd = await this._parcelService.postToServer(tokenFromServer, from).toPromise();
      let saveToDBAoneBackEnd = await this._parcelService.postToPlugAndPlay(tokenFromServer, from).toPromise();
    }
    catch (error) {
      window.location.reload();
    }
  }

  async saveToDBWithImage(user: any, uniq: any, imageFromCamera: any) {
    try {
      let saveToDBWithImage = await this.dashboardService.PostToUpdateImageBlobParcel(user, uniq, imageFromCamera
      ).toPromise();
    } catch (error) {
      window.location.reload();
    }
  }

  async saveToDBUpdateInstallApp(user: any, uniq: any, installapp: any, status: any) {
    try {
      let saveToDBInstallApp = await this.dashboardService.PostUpdateInstallApp(user, uniq, installapp, status
      ).toPromise();
    } catch (error) {
      window.location.reload();
    }
  }


  //pro = ParcelOut
  @ViewChild('pro', { static: false }) pacelOut: NgForm;
  //Production

  _prefixAddressRoomOut = 'บ้านเลขที่';
  _addressRoomOut = '';

  //Production
  _countErrorOut = 0;
  _parcelOut: ParcelOut[] = <any>[]; // Production
  _typeNameOut = '';
  _countConfirmChoose = 0;
  _whileSearch = 0;

  //Start Parcel Out =====> Click
  btnparcelOut() {
    this._state = 'A';
    this.CallMultiSiteToDdl();
  }

  //Load PrefixNumber Dropdownlist For Parcel Out Form
  _ddlParcelOutLength = 0;
  CallMultiSiteToDdl() {
    var _projectIds = localStorage.getItem('_projectIds');
    this._authenService.fetchMultiSiteToAnanda(_projectIds).subscribe(item => {
      if (item !== <any>'ERROR') {


        this._anandaMultiSite = [];
        this._anandaMultiSite.push({
          id: '0',
          projectnameen: '',
          projectnamethai: '',
          prefixhouse: 'บ้านเลขที่'
        })
        if (item.length === 1) {
          this._ddlParcelOutLength = <any>item.length;
          item.forEach(element => {
            this._prefixAddressRoomOut = element.prefixhouse;;
            this._oneSiteParcelOut = element.prefixhouse;;
            this._anandaMultiSite.push({
              id: element.id,
              projectnameen: element.projectnameen,
              projectnamethai: element.projectnamethai,
              prefixhouse: element.prefixhouse
            })
          });
        }
        else {
          this._oneSiteParcelOut = '-';
          item.forEach(element => {
            this._anandaMultiSite.push({
              id: element.id,
              projectnameen: element.projectnameen,
              projectnamethai: element.projectnamethai,
              prefixhouse: element.prefixhouse
            })
          });
        }

      } else {
        localStorage.clear();
        this.router.navigate(['/authen/']);
        return;
      }
    });
    //Load PrefixNumber Dropdownlist
  }

  //Select Multi Site From ddl
  onSelectPrefixnumberOut(item: any) {
    if (item !== 'บ้านเลขที่') {
      this._prefixAddressRoomOut = item;
    } else {
      this._prefixAddressRoomOut = 'บ้านเลขที่';
      return;
    }
  }

  OnStartDateChangeOut(itemS: any) {
    this._dSTimeSelectedOut = '';
    this._dSTimeSelectedOut = itemS;
  }

  OnEndDateChangeOut(itemS: any) {
    this._dETimeSelectedOut = '';
    this._dETimeSelectedOut = itemS;
  }

  //Btn Find ParcelOut KeyOut
  onFindCustomerOut() {
    

    console.log('onFindCustomer ParcelOut List..');


    // Remove Calendar
    // this._dSTimeSelectedOut = this.convertDateToServer(  this._dSTimeSelectedOut);
    // this._dETimeSelectedOut = this.convertDateToServer(  this._dETimeSelectedOut);
    // Remove Calendar


    var _rnumberOut = this.pacelOut.value.prefixoutroom;
    if (_rnumberOut === '' || _rnumberOut === ' ' || _rnumberOut === null) {
      this._prefixAddressRoomOut = 'บ้านเลขที่';
      this._countErrorOut = 3;
      setTimeout(async () => {
        this._countErrorOut = 2;
      }, 1000);

      setTimeout(async () => {
        this._countErrorOut = 1;
      }, 2000);

      setTimeout(async () => {
        this._countErrorOut = 0;
      }, 3000);
    } else {

      this._addressRoomOut = _rnumberOut;
      this._state = 'B';
      //New Year Will Handle
      // = 'select'; //Select or Add <==== Big Point
      //New Year Will Handle


      var _user = localStorage.getItem("_userName");
      var _concatRoomNumberPrefix = this._prefixAddressRoomOut + '/' + this._addressRoomOut;
      this._parcelOut = [];


      //Fetch From Database from prefix + room where status do not ready or whatever ...
      //call from server

      this._whileSearch = 1; /// setTimeOut While Call API

      //***   v1.Previuos Version fetchParcelOut
      //***   v2.fetchParcelFromAnandaWithStatusAndDate
      //***   v3.fetchParcelFromAnandaWithStatusAndDateForPCOut
      //***   Go back Previous v1. fetchParcelOut

      this.dashboardService.fetchParcelOut
        (_user, _concatRoomNumberPrefix).subscribe(item => {
          if (item === null || item.length === 0) {
            this._state = 'A';
            this._vmMobileNullOut = 1;
            this._whileSearch = 0;
            this._addressRoomOut = '';
            //this._prefixAddressRoomOut  = 'บ้านเลขที่'; //New Fixed 22 March
            this._countErrorOut = 0; //New Fixed 22 March
            setTimeout(() => {
              this._vmMobileNullOut = 0;
            }, 1500);
            //*ngIf="_countErrorOut === 0 &&  _prefixAddressRoomOut !== 'บ้านเลขที่' "  
          }
          if (item !== <any>'ERROR') {
            this._whileSearch = 0;
            this._parcelOut = item;
            if (item.length > 0) {
              //console.log('Fetch parcelOut');
              //console.log(item);
              this._parcelOut.forEach(element => {
                var _dateCrated = element.creationiime;
                _dateCrated = <any>this.datepipe.transform(_dateCrated, 'dd-MM-yyyy');

                var _extractDMY = _dateCrated.toString().split("-");
                this._date = _extractDMY[0];
                var month = _extractDMY[1];
                this._month = this.convertTOThaiMonth(month);
                this._year = _extractDMY[2];
                element.mapcreationtodate = this._date + ' ' + this._month + ' ' + this._year;
                element.choose = '0';
                if (element.imageblob === '' || element.imageblob === null) {
                  element.mapimageblob = '-';
                } else {
                  element.mapimageblob = element.imageblob.toString();
                }
              });
            } else {
              this._state = 'A';
              this._vmMobileNullOut = 1;
              this._whileSearch = 0;
              this._addressRoomOut = '';
              //this._prefixAddressRoomOut  = 'บ้านเลขที่'; //New Fixed 22 March
              this._countErrorOut = 0; //New Fixed 22 March
              setTimeout(() => {
                this._vmMobileNullOut = 0;
              }, 1500);
              //*ngIf="_countErrorOut === 0 &&  _prefixAddressRoomOut !== 'บ้านเลขที่' "  
            }

          }
          else {
            this.openErrorSnackBar();
            localStorage.clear();
            this.router.navigate(['/authen/']);
            return;
          }
        });
    }
  }

  //On CheckBox
  checkSelectParcel(item: any, event: any, index: any) {

    var _chooseID = this._parcelOut[index].id;
    var _chooseOrNot = this._parcelOut.find(item => item.id === _chooseID);

    //Swap Logic Checked or UnCheck

    if (_chooseOrNot.choose === '0') {

      this._parcelOut[index].choose = '1';
      this._countConfirmChoose += 1;
      // console.log('checkSelectParcel');
      // console.log('index : ' + index);
      // console.log('item');
      // console.log(item);
    } else {

      this._parcelOut[index].choose = '0';
      this._countConfirmChoose -= 1;
    }
  }

  //Teerat KeyParcelOut
  async setUpFromAndToken(parcelListArr: any) {
    console.log('Start Post TO postToPlugAndPlay For KeyParcelOut');

    let _resultToken = await this._parcelService.getToken().toPromise();
    let _tokenFromIntegration = _resultToken.result.token;
    let _idPushToServer = <any>[];
    let _updateImageNullForm = <any>[];

    if (parcelListArr.length > 0 && _tokenFromIntegration !== '') {
      /// Step 1 Form Set UP
      var mapCustomerID = sessionStorage.getItem('ipropCustomerID');
      let _loopRersult = await parcelListArr.forEach(element => {
        /// Step 1.1
        if (element.choose === '1') {
          _idPushToServer.push({
            customer_id: mapCustomerID,  // Eak handle 0 Already at Backend
            status: 'picked',
            property_code: element.propertycode,
            unit_no: element.unitNumber,
            tracking_code: element.parcelno + ':' + element.qrcode,
            message: this._userAccount,
            image_url: 'https://firebasestorage.googleapis.com/v0/b/anandaforlab.appspot.com/o/picked.jpeg?alt=media&token=f87d7ace-6ac2-4a7d-b23e-4e92dc771eea',
            user_type: element.user,
            receiver_name: element.recipient,
            receiver_phone_number: element.recipientphonenumber,
            express_company: element.expresscompany + ":" + element.package,
            installapp: element.installapp
          });
        }
        /// Step 1.2
        if (element.choose === '1' && (element.imageblob !== '-' && element.imageblob !== null
          && element.imageblob !== ' ' && element.imageblob !== '')) {
          _updateImageNullForm.push({
            id: element.id
          });
        }
      }); // End Form Set UP
    } // End Check IF parcelOut Not NULL

    try {
      await _idPushToServer.forEach(elementItem => {

        if (elementItem.installapp === '1' ) {
          console.log('Update with ShipmentTrack : (' + elementItem.installapp + ').');
          setTimeout(() => {
            //let _sendToAOneBackEnd = this._parcelService.postToServer(_tokenFromIntegration, elementItem).toPromise();
            let _sendToAOneBackEnd = this._parcelService.postToPlugAndPlay("", elementItem).toPromise();
          }, 200);
          
          // console.log('_idPushToServer : ' + _idPushToServer.length);
          // console.log('** End sendToAOneBackEnd');
        }
        if (elementItem.installapp === '0' || elementItem.installapp === null || elementItem.installapp === '') {
          console.log('Update with UpdateInstallApp : (' + elementItem.installapp + ').');
          var _user = localStorage.getItem("_userName");
          var _tracking_code = elementItem.tracking_code;
          setTimeout(async () => {
            let _saveToUpdateInstallApp = await this.saveToDBUpdateInstallApp(_user, _tracking_code, "0", "Collected");
          }, 200);
        
          //console.log('_idPushToServer : ' + _idPushToServer.length);
          //console.log('** End sendToAOneBackEnd');
        }


      });

      if (_updateImageNullForm.length > 0) {
        await _updateImageNullForm.forEach(element => {
          setTimeout(() => {
            let _sendToUpdateImageNull = this.dashboardService.PostUpdateImageNull(_updateImageNullForm).toPromise();
          }, 200);
          console.log('_updateImageNullForm : ' + _updateImageNullForm.length);
          console.log('** End sendToUpdateImageNull');
        });
      }

    } catch (error) {
      this.openErrorSnackBar();
      localStorage.clear();
      this.router.navigate(['/authen/']);
      return;
    }
  }

  _readyToReload = '0';
  displayStyleModal = "none";
  async setUpRedirectWithModal() {
    setTimeout(() => {
      this._state = 'C';
      sessionStorage.setItem('state', 'C');
      this.displayStyleModal = "block";
      this._readyToReload = '1';
    }, 800);
  }

  //Update To Server confirm to parcelOut
  _countVerify = [];
  _flagBottomSheet = 0;

  //confirmToDB...parcelOut
  async confirmToDB() {
    console.log('confirmToDB...parcelOut');
    console.log(this._parcelOut);
    let _itemForVerifyMobile = <any>[];
    if (this._parcelOut.length > 0) {
      console.log('Start Post TO aoneBackend Logic Number.');
      /// Step 1 Form Set UP
      let _loopRersult = await this._parcelOut.forEach(element => {
        /// Step 1.1
        if (element.choose === '1' && element.installapp === '1' && element.recipientphonenumber !== '') {
          this._flagBottomSheet = 1;
          var _core = element.recipientphonenumber.toString();
          var _core1 = element.recipientphonenumber.toString();
          var _core2 = element.recipientphonenumber.toString();

          var _logicTelephone = _core1.toString().substring(0, _core1.length - 4);
          var _concatTelePhone = _core2.toString().substring(_core1.length - 4);
          var mapCustomerID = sessionStorage.getItem('ipropCustomerID');

          
          //Teerat Production 13 November 2023
          if( element.recipientphonenumber === '0000000000' ||  element.recipientphonenumber === '0000' ||  element.recipientphonenumber === '000000') {
            _logicTelephone = '0000000000';
          }else {
            _logicTelephone = _logicTelephone + " - XXXX";
          }
           //Teerat Production 13 November 2023




          _itemForVerifyMobile.push({
            customer_id: mapCustomerID,  // Eak handle 0 Already at Backend
            status: 'picked',
            property_code: element.propertycode,
            unit_no: element.unitNumber,
            tracking_code: element.parcelno + ':' + element.qrcode,
            message: this._userAccount,
            image_url: 'https://firebasestorage.googleapis.com/v0/b/anandaforlab.appspot.com/o/picked.jpeg?alt=media&token=f87d7ace-6ac2-4a7d-b23e-4e92dc771eea',
            user_type: element.user,
            receiver_name: element.recipient,
            receiver_phone_number: _core,
            receiver_phone_number_logic: _logicTelephone,// _logicTelephone + " - XXXX",  //Teerat Production 13 November 2023
            receiver_phone_number_logicRight: _concatTelePhone,
            express_company: element.expresscompany + ":" + element.package,
            onkeep: '',
            confirm: '0',
          });
        }
      });

      this._countVerify = _itemForVerifyMobile.filter(
        (thing, i, arr) => arr.findIndex(t => t.receiver_phone_number === thing.receiver_phone_number) === i
      );

      if (this._flagBottomSheet === 1) {
        console.log('Use flagBottomSheet');
        localStorage.setItem('arrVerify', JSON.stringify(_itemForVerifyMobile));
        const result = await this._bottomSheet.open(BottomSheetOverviewExampleSheet, {
          disableClose: true,
          data: 'Y'
        });

        result.afterDismissed().subscribe(item => {
          if (item === 'Y') {
            setTimeout(async () => {
              this._save = 1;
              this._state = 'B';
              sessionStorage.setItem('state', 'B');
              let _setUp = await this.setUpFromAndToken(this._parcelOut);
              let _setUpReload = await this.setUpRedirectWithModal();
            }, 150);
          } else {
            console.log('==========> : ' + 'NO Submit');
          }

        });
      } else {
        console.log('Do not To flagBottomSheet');
        setTimeout(() => {
          this._save = 1;
          this._state = 'B';
          sessionStorage.setItem('state', 'B');
        }, 150);
        let _setUp = await this.setUpFromAndToken(this._parcelOut);
        let _setUpReload = await this.setUpRedirectWithModal();
      }
    }
  }

  cancelPOut() {
    window.location.reload();
  }

  clearPOut() {
    window.location.reload();
  }

  openErrorSnackBar() {
    this._snackBar.open('Session ของท่านหมดอายุ.. กรุณา Login ใหม่...', 'End now', {
      duration: 4500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
    return;
  }

    logOut() {
    console.log('Logout');
    localStorage.clear();
    this.router.navigate(['/authen/']);
    // return;
    // var _localUniqIDForLogout = localStorage.getItem('_uniqId');
    // var _userLogout = localStorage.getItem("_userName");

    // if (this.detailsDevice == "" || this.detailsDevice == null
    //   || this.detailsDevice == ' ' || this.detailsDevice == ''
    // ) {
    //   this.detailsDevice = 'Something Wrong !!!';
    // }
    // var trackAuthenLog = {
    //   user: _userLogout,
    //   uniqid:_localUniqIDForLogout,
    //   event: 'Logout',
    //   device: this.detailsDevice
    // }
    
    // try {
    //   let _updateTrackAuthenLog = this.updateTrackAuthenLog(trackAuthenLog); // trackAuthenLog 
    // } catch (error) { }
  
    // setTimeout(() => {
    //   localStorage.clear();
    //   this.router.navigate(['/authen/']);
    // }, 400);
  }



  // async updateTrackAuthenLog(from: any) {
  //   try {
  //     let updateTrackAuthenLog = await this.dashboardService.updateTrackAuthenLog(from).toPromise();
  //   }
  //   catch (error) {
  //     window.location.reload();
  //   }
  // }

  convertTOThaiMonth(item: any) {
    if (item === '1' || item === '01') {
      return 'มกราคม';
    }
    if (item === '2' || item === '02') {
      return 'กุมภาพันธ์';
    }
    if (item === '3' || item === '03') {
      return 'มีนาคม';
    }
    if (item === '4' || item === '04') {
      return 'เมษายน';
    }
    if (item === '5' || item === '05') {
      return 'พฤษภาคม';
    }
    if (item === '6' || item === '06') {
      return 'มิถุนายน';
    }
    //
    if (item === '7' || item === '07') {
      return 'กรกฏาคม';
    }
    if (item === '8' || item === '08') {
      return 'สิงหาคม';
    }
    if (item === '9' || item === '09') {
      return 'กันยายน';
    }
    if (item === '10') {
      return 'ตุลาคม';
    }
    if (item === '11') {
      return 'พฤศจิกายน';
    }
    if (item === '12') {
      return 'ธันวาคม';
    }
  }

  name = 'Angular ' + '9';
  title = 'ng-xlsx-example';
  EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  employee = [
    { name: 'Ananda1', email: 'teerat@ananda.co.th' },
    { name: 'Ananda2', email: 'teerat@ananda.co.th' },
    { name: 'Ananda3', email: 'teerat@ananda.co.th' },
  ];

  parcelListModelForExcel: ParcelListModelForExcel[] = <any>[];


  exportToExcel() {
    
    this.parcelListModelForExcel = [];
    if (this.parcelListModel.length > 0) {
      this.parcelListModel.forEach(item => {
        var _setInstallApp = item.installapp.toString();
        var _extrachMobileIncaseNull = item.recipientphonenumber.toString();
        var _extractStatus = item.status.toString();
        var _extractName = '';
        var _extractQr =  item.qrupdate.toString();

        if (_extractStatus === 'Collected') {
          _extractName = item.recipient.toString();
        }

        if (_extractStatus === 'Arrived' || _extractStatus === 'arrived'
          || _extractStatus === 'Returned' || _extractStatus === 'returned') {
          _extractName = '';
        }

        if(_extractQr === '1' &&  (_extractStatus === 'Collected' || _extractStatus === 'collected')) {
          _extractQr = 'QR From COCORO APP';
        }
        else if  ( (_extractQr === null || _extractQr === 'NULL' || _extractQr === ''  )   &&  (_extractStatus === 'Collected' || _extractStatus === 'collected')) {
          _extractQr = 'WEB';
        }
        else {
          _extractQr = '-';
        }

        // console.log('_extractStatus : ' + _extractStatus + ' : _extractName : ' + _extractName);
        if (_setInstallApp === "0" || _setInstallApp === "" || _setInstallApp === null || _extrachMobileIncaseNull === "") {
          this.parcelListModelForExcel.push({
            id: item.id,
            user: item.user,
            recipient: item.recipient,
            recipientphonenumber: item.recipientphonenumber,
            parcelno: item.parcelno,
            expresscompany: item.expresscompany,
            package: item.package,
            qrcode: item.qrcode,
            message: item.message,
            propertycode: item.propertycode,
            unitNumber: item.unitNumber,
            status: item.status,
            วันที่คีย์รับพัสดุ: item.mapcreationtodate,
            วันที่คีย์จ่ายพัสดุ: item.maplastmodifytodate,
            ผู้รับพัสดุหรือผู้รับแทนจากระบบ: _extractName,
            ลงนามผู้รับพัสดุ: '',
            installapp: 'NO COCORO APP',
            คีย์จ่ายโดย :  _extractQr.toString()
          });
        } if (_setInstallApp === "1" && _extrachMobileIncaseNull !== "") {
          this.parcelListModelForExcel.push({
            id: item.id,
            user: item.user,
            recipient: item.recipient,
            recipientphonenumber: item.recipientphonenumber,
            parcelno: item.parcelno,
            expresscompany: item.expresscompany,
            package: item.package,
            qrcode: item.qrcode,
            message: item.message,
            propertycode: item.propertycode,
            unitNumber: item.unitNumber,
            status: item.status,
            วันที่คีย์รับพัสดุ: item.mapcreationtodate,
            วันที่คีย์จ่ายพัสดุ: item.maplastmodifytodate,
            ผู้รับพัสดุหรือผู้รับแทนจากระบบ: _extractName,
            ลงนามผู้รับพัสดุ: '',
            installapp: 'COCORO APP',
            คีย์จ่ายโดย :  _extractQr.toString()
          });
        }

      });
    }

    setTimeout(() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.parcelListModelForExcel);
      const workbook: XLSX.WorkBook = {
        Sheets: { Sheet1: worksheet },
        SheetNames: ['Sheet1'],
      };
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const data: Blob = new Blob([excelBuffer], { type: this.EXCEL_TYPE });
      const date = new Date();
      const fileName = 'anandaParcel.xlsx';
      FileSaver.saveAs(data, fileName);
    }, 400);
  }

  btnparcelOutByQR() {
    console.log('btnparcelOutByQR');
    this._state = 'QR';
  }

  parcelListModelForCCRO: ParcelListModelForCCRO[] = <any>[];
  _ccroparcelno = ''
  _ccrorecipient = '';
  _ccrorephone = '';
  _ccrorunitnumber = '';
  _ccroexpresscompany = '';
  _ccroSaveToDatabase = '0';// Extra
  _ccroparcelstatus = '';

  openErrorSnackBarForCCRO() {
    this._snackBar.open('ไม่พบข้อมูลพัสดุ.....', 'End now', {
      duration: 5500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
    return;
  }

  async onCodeResultCCRO(result: string) {
    console.log("Teerat : Start onCodeResultCCRO");
    console.log("ID : " + result.toString());
    try {
      if (result != '' || result != "" || result != null) {
        this.scanResultCCRO = 'Start';
        this.progressModeCCRO = true;
        var _resultParcel = await this.findParcelByCCROID(result);
      }
    } catch (ex) {
      console.log("CCRO onCodeResultCCRO.....");
      this.openErrorSnackBarForCCRO();
      setTimeout(() => {
        window.location.reload();
      }, 600);
    }
  }


  cancelCCROScan() {
    window.location.reload();
  }

  async findParcelByCCROID(idFromCCROQr: any) {

    console.log("Teerat : findParcelByCCROID");
    localStorage.setItem('idFOrPlugAndPlay' , idFromCCROQr);
    try {
      setTimeout(() => {
        this.scanResultCCRO = 'fetchAPI';
        this.progressModeCCRO = true;
      }, 100);

      //Teerat Emergency
      let resultParcelCCRO = await this.dashboardService.fetchParcelCCROByID(idFromCCROQr).toPromise();//1133205
      //Teerat Emergency

      if (resultParcelCCRO != null) {
        if (resultParcelCCRO[0].parcelno != '') {
          setTimeout(() => {
            this.scanResultCCRO = 'itemAPI';
            this.progressModeCCRO = false;

          }, 400);
          if (resultParcelCCRO[0].status === 'Arrived') {
            this._ccroparcelstatus = 'Arrived'
            console.log('CCRO Parcel Status : =======> ' + this._ccroparcelstatus);
          }
          
          this.parcelListModelForCCRO = resultParcelCCRO;
          this._ccroparcelno = this.parcelListModelForCCRO[0].parcelno.toString();
          this._ccrorecipient = this.parcelListModelForCCRO[0].recipient.toString();
          this._ccrorephone = this.parcelListModelForCCRO[0].recipientphonenumber.toString();
          this._ccrorunitnumber = this.parcelListModelForCCRO[0].unitNumber.toString();
          this._ccroexpresscompany = this.parcelListModelForCCRO[0].expresscompany.toString();
          console.log(this.parcelListModelForCCRO);
          console.log(this._ccroparcelno.toString());
          //
        }
      }
      else {
        console.log("CCRO Parcel NOT FOUND.....");
        this.openErrorSnackBarForCCRO();
        setTimeout(() => {
          window.location.reload();
        }, 700);
      }
    }
    catch (ex) {
      //SuRE
      // this.parcelListModelForCCRO = null;
      // this.parcelListModelForCCRO = [];
      //SuRE
      // this._ccroparcelno = '';
      // this._ccrorecipient = '';
      // this._ccrorephone = '';
      // this._ccrorunitnumber = '';
      // this._ccroexpresscompany = '';
      // this._ccroSaveToDatabase = '0';
      // this._ccroparcelstatus = '';
      // window.location.reload();
      this.openErrorSnackBarForCCRO();
      setTimeout(() => {
        window.location.reload();
      }, 600);
    }
  }

  //btnConfirm CCRO QR Code Flow....
  ccroConfirmSave() {
    // Emergency
    // ***** Call Ake aPI and Then Follow this below.
    // Emergency
     var _parcelListModelForCCRO = this.parcelListModelForCCRO;
     var mapCustomerID = sessionStorage.getItem('ipropCustomerID');
    var  _mapIDForPlugAndPlay = localStorage.getItem('idFOrPlugAndPlay'); // "1133259" //; Teerat Emergency  localStorage.getItem('idFOrPlugAndPlay');

    if(_parcelListModelForCCRO != null) {

      // Block For New Plug and Play
      var _pushForPlugAndPlay = {
        customer_id: mapCustomerID, // Eak handle 0 Already at Backend
        status: 'picked',
        property_code: _parcelListModelForCCRO[0].propertycode,
        unit_no: _parcelListModelForCCRO[0].unitNumber,
        tracking_code: _parcelListModelForCCRO[0].parcelno,
        message: _parcelListModelForCCRO[0].message,
        image_url: 'https://firebasestorage.googleapis.com/v0/b/anandaforlab.appspot.com/o/picked.jpeg?alt=media&token=f87d7ace-6ac2-4a7d-b23e-4e92dc771eea',
        user_type: _parcelListModelForCCRO[0].user,
        receiver_name: _parcelListModelForCCRO[0].recipient,
        receiver_phone_number: _parcelListModelForCCRO[0].recipientphonenumber,
        express_company: _parcelListModelForCCRO[0].expresscompany,
        installapp: _parcelListModelForCCRO[0].installapp
      };
       // Block For New Plug and Play

      setTimeout( async() => {
        let _sendToAOneBackEnd = await this._parcelService.postToPlugAndPlay("", _pushForPlugAndPlay).toPromise();
      }, 100);
      
      console.log('... Ready For BTN Comfirm ...');

      try {
        setTimeout(() => {
          this._state = 'QRCCRODone';
        }, 500);
        setTimeout(async () => {
         //var _user = localStorage.getItem("_userName");
          this._ccroSaveToDatabase = '1';
          if (_mapIDForPlugAndPlay !== '' &&  this._userAccount != '') {
            console.log('IN PrOCESS ccroConfirmSave ');
            var resultUpdateToDB = await this.dashboardService.updateParcelCCROByID(_mapIDForPlugAndPlay, 
              this._userAccount.toString()
              ).subscribe(item => {
              console.log(item);
              var _result = item.toString();
              if (_result === 'Success' || _result === 'success') {
                setTimeout(() => {
                  window.location.reload();
                }, 700);
              } else {
                console.log('Error Cannot UpdateCCO QRcode : ' + item.toString());
              }
            });
          }
        }, 900);
      } catch (ex) {
        this.openErrorSnackBarForCCRO();
        setTimeout(() => {
          window.location.reload();
        }, 400);
      }
    }
  }
}


@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  templateUrl: 'bottom-sheet-overview-example-sheet.html',
})

export class BottomSheetOverviewExampleSheet implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {}

  ) { }

  castArrayVerrify = [];
  _castArrayVerrify = []
  _countKey = 0;
  _countMatch = 0;

  actions: any;

  @ViewChild('te', { static: false }) addQrForm: NgForm;

  ngOnInit() {
    this._bottomSheetRef.disableClose = true;
    var _arrItem = localStorage.getItem('arrVerify');
    this.castArrayVerrify = JSON.parse(_arrItem);
    if (this.castArrayVerrify.length > 0) {
      this._castArrayVerrify = this.castArrayVerrify.filter(
        (thing, i, arr) => arr.findIndex(t => t.receiver_phone_number === thing.receiver_phone_number) === i
      );
    }
    this._countKey = this._castArrayVerrify.length;
    console.log('Verify : ' + this._countKey);
  }


  numberPhoneOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onkeyTeleNumber(item, index) {
    this._castArrayVerrify[index].onkeep = item;
    if (this._castArrayVerrify[index].onkeep.length === 4) {
      if (this._castArrayVerrify[index].onkeep === this._castArrayVerrify[index].receiver_phone_number_logicRight) {
        this._castArrayVerrify[index].confirm = '1';
        this._countMatch += 1;
      } else {
        this._castArrayVerrify[index].confirm = '0';
      }
    } else {
      this._castArrayVerrify[index].confirm = '0';
    }
    if (this._countKey === this._countMatch) {
      console.log('Ready To Submit');
      console.log(this._castArrayVerrify);
    }
  }

  onGobackToHomeMethod(event: MouseEvent): void {
    this._bottomSheetRef.dismiss('Y');
    event.preventDefault();

  }
  onGobackToHomeMethodClear(event: MouseEvent): void {
    this._bottomSheetRef.dismiss('N');
    event.preventDefault();
  }

  //User This Repostiory

}