<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  <style>
    a.b {
      -webkit-text-decoration-line: underline;
      /* Safari */
      text-decoration-line: underline;
    }

    .note {
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      padding: 5px;
      background: #eee;
      max-width: 400px;
      border: 1px dotted #bbb;
    }

    .verticalLine {
      height: 100%;
      border-left: 2.3px solid #455385;
    }

    .dropzone2 {
      display: flex;
      align-items: left;
      justify-content: left;
      flex-direction: column;
      padding-bottom: 0.30cm;
      /* min-width: 80vw; */
      font-weight: 80;
      height: auto 100%;
      border: 2px dashed #192d4ba1;
      border-radius: 3px;
      background: rgba(247, 242, 232, 0.137);
      margin: 5px 0;
      margin-bottom: -0.3cm;
      margin-top: 0.45cm;
    }

    .hovering {
      border: 2px solid #a2906177;
      color: #dadada !important;
    }

    .file-label {
      font-size: 1.2em;
    }


    progress::-webkit-progress-value {
      transition: width 0.1s ease;
    }


    /* input[type=text] {
     width: 30%;
     padding: 8px 14px;
     margin: 2px;
     box-sizing: border-box;
  } */
  </style>
</head>

<body style="font-family: Kanit;">
  <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
    <div class="row">
      <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
        <div>
          <div class="row">
            <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;">
              <div class="row" style="margin-top: 0.3cm;" *ngIf="progressMode === false">
                <div class="col-12">
                  <div class="row">
                    <div style="margin-left: 0.5cm;">
                      <div *ngIf="scanResult === 'Waiting' ">
                        <span style="color:#192d4b;" class="material-icons">
                          toggle_on
                        </span>
                      </div>
                      <div *ngIf="scanResult !== 'Waiting' ">
                        <span style="color:#00b28b;" class="material-icons">
                          task_alt
                        </span>
                      </div>
                    </div>
                    <div class="col-6" style="margin-left: -0.4cm;">
                      <div *ngIf="scanResult === 'Waiting' ">
                        <a style="font-size: 17px;margin-left: 0.3cm;;font-family: Kanit;color: #b14747;">Waiting
                          ...</a>
                      </div>
                      <div *ngIf="scanResult !== 'Waiting' ">
                        <a style="font-size: 17px;margin-left: 0.3cm;;font-family: Kanit;color: #00b28ba8;">Success
                          !!!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 0.1cm;margin-bottom: 0.2cm;"
                *ngIf="scanResult !== 'Waiting' && progressMode === false ">
                <div class="col-12 container-fluid dropzone2 ">
                  <a style="margin-top: 0.3cm;font-size: 20px;font-family: Kanit;padding-left: -0.2cm;">Scan Result :
                  </a>
                  <strong *ngIf="startIndex === 1 "
                    style="color: rgba(191, 30, 212, 0.812);font-size: 20px;font-family: Kanit;">{{scanResult}}</strong>
                  <strong *ngIf="startIndex === 2 "
                    style="color: #192d4b;font-size: 20px;font-family: Kanit;">{{scanResult}}</strong>
                  <strong *ngIf="startIndex === 3 "
                    style="color: #00b28b;font-size: 20px;font-family: Kanit;">{{scanResult}}</strong>
                </div>
              </div>



              <div class="row" *ngIf="scanResult === 'Waiting' && progressMode === false">
                <div class="container-fluid card" style="background-color: white;border-color: white;">
                  <zxing-scanner (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"></zxing-scanner>
                </div>
              </div>

              <div class="row" *ngIf="scanResult !== 'Waiting' && progressMode === true ">
                <div class="container-fluid card"
                  style="margin-bottom: 1cm;margin-top: 1cm;background-color: white;border-color: white;">
                  <mat-spinner style="margin:0 auto;" [diameter]="30">
                    ></mat-spinner>
                  <a style="text-align: center; padding-top:0.3cm;letter-spacing: 00.030cm;">กรุณารอสักครู่ ...</a>
                </div>
              </div>



              <div *ngIf="scanResult === 'Waiting' &&  progressMode === false"
                style="margin-top: 0.10cm;margin-bottom: 0.4cm;">
                <button mat-raised-button class="btn  btn-block" (click)="onClearPage()"
                  style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">Refresh
                  / รีเฟช
                </button>
              </div>

              <form #l="ngForm">
                <div class="row" *ngIf="scanResult !== 'Waiting' &&  progressMode === false" style="margin-top: 1.2cm;">

                  <div class="col-12" style="margin-top: 0.4cm;">
                    <select class="form-control form-control-sm" (change)='onOptionsSelectedParcel(SpecRoomID.value)'
                      name="SpecRoomID" #SpecRoomID id="SpecRoomID" style="font-family: Kanit;margin-top: -0.75cm;">
                      <option *ngFor="let items of mockRoomItems" style="font-family: Kanit;" [value]="items.id">
                        {{items.roomumber}}
                      </option>
                    </select>
                  </div>
                  <div class="col-12" style="margin-top: 0.1cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size:17px">
                            edit
                          </span>
                        </div>
                      </div>
                      <input autocomplete="false" ngModel name="txt1" type="text"
                        style="font-family: Kanit;color: #192d4b;" class="form-control form-control-sm"
                        placeholder="กรอกมาครับ 1" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.1cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size:17px">
                            edit
                          </span>
                        </div>
                      </div>
                      <input autocomplete="false" ngModel name="txt2" type="text"
                        style="font-family: Kanit;color: #192d4b;" class="form-control form-control-sm"
                        placeholder="กรอกมาครับ 2" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.1cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size:17px">
                            edit
                          </span>
                        </div>
                      </div>
                      <input autocomplete="false" ngModel name="txt3" type="text"
                        style="font-family: Kanit;color: #192d4b;" class="form-control form-control-sm"
                        placeholder="กรอกมาครับ 3" />
                    </div>
                  </div>
                </div>

                <div *ngIf="scanResult !== 'Waiting' &&  progressMode === false"
                  style="margin-top: 0.20cm;margin-bottom: 0.4cm;">

                  <button *ngIf="this.awaitSave  === 0  &&  this.room !== '0' " mat-raised-button class="btn  btn-block"
                    (click)="onSaveBrcode()"
                    style="margin-top: 0.6cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">Save
                    / บันทึก
                  </button>



                  <button *ngIf="this.awaitSave  === 1 " class="btn  btn-block" disabled
                    style="background-color: #00b28b;color: white;" type="button">
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    ระบบกำลังบันทึก...
                  </button>

                  <button *ngIf="this.awaitSave  === 0 " mat-raised-button class="btn  btn-block"
                    (click)="onClearBrcode()"
                    style="margin-top: 0.1cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">Refresh
                    / รีเฟช
                  </button>

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>