import { Component, OnInit } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  declareUserId = '';
  declareUserName = '';

  //

  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;
  detailsDevice = "";

  constructor(private router: Router) {
    this.declareUserId =  localStorage.getItem("_UserId");  //JSON.parse(localStorage.getItem("_UserId"));
    this.declareUserName =  localStorage.getItem("_userName"); 
    if(this.declareUserId === '' || this.declareUserName === '') {
      localStorage.clear();
       // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/authen/']);
      // });
      this.router.navigate(['/authen/'])
        .then(() => {
          window.location.reload();
        });
    }
  }

  ngOnInit(): void {
   // this. subScribeDeviceInfo();
    $('.navbar-collapse a').click(function () {
      $('.navbar-collapse').collapse('hide');
    });
  }

  // subScribeDeviceInfo() {
  //   try {
  //     this.deviceInfo = this.deviceService.getDeviceInfo();
  //     this.isMobile = this.deviceService.isMobile();
  //     this.isTablet = this.deviceService.isTablet();
  //     this.isDesktopDevice = this.deviceService.isDesktop();
  //     console.log(this.deviceInfo);
  //     console.log(this.isMobile);
  //     console.log(this.isTablet);
  //     console.log(this.isDesktopDevice);
  //     this.detailsDevice = "OS : (" + this.deviceInfo.os + ") , Browser : (" + this.deviceInfo.browser + ") , Mobile : (" + this.isMobile + ") , Tablet : ("
  //       + this.isTablet + ") , Desktop : (" + this.isDesktopDevice + ") ";
  //   } catch (error) { }
  // }

  logOut() {

    localStorage.clear();
    this.router.navigate(['/authen/']);

  //   var _localUniqIDForLogout = localStorage.getItem('_uniqId');
  //   var _userLogout = localStorage.getItem("_userName");
  //   if (this.detailsDevice == "" || this.detailsDevice == null
  //   || this.detailsDevice == ' ' || this.detailsDevice == ''
  // ) {
  //   this.detailsDevice = 'Something Wrong !!!';
  // }

  //   var trackAuthenLog = {
  //     user: _userLogout,
  //     uniqid:_localUniqIDForLogout,
  //     event: 'Logout',
  //     device: this.detailsDevice
  //   }

    // this.dashboardService.updateTrackAuthenLog(trackAuthenLog).subscribe (item => {
    //   console.log('updateTrackAuthenLog To Server');
    //   console.log(item)
    //         localStorage.clear();
    //   this.router.navigate(['/authen/']);
    // }); 

  //   setTimeout(() => {
  //     localStorage.clear();
  //     this.router.navigate(['/authen/']);
  //   }, 500);
   }
 

}