import { ParcelService } from './../services/parcel.service';
import { ParcelModel } from './../models/parcel.module';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css']
})
export class QrcodeComponent implements OnInit {

  parcelModel: ParcelModel[] = [];
  @ViewChild('l', { static: false }) pacelForm: NgForm;
  awaitSave = 0;
  room = '0';

  constructor(private parcelService: ParcelService) { }
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_93,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODABAR,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.AZTEC,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION
  ];
  toggleVal = false;
  startIndex = 0;
  scanResult: any = 'Waiting'; 
  mockRoomItems = [
    { id: '0', roomumber: 'กรุณาเลือก บ้านเลขที่', fullname: 'กรุณาเลือก บ้านเลขที่' },
    { id: '1', roomumber: '01/100', fullname: 'คุณ สวัสดีครับ 100' },
    { id: '2', roomumber: '01/200', fullname: 'คุณ สวัสดีครับ 200' },
    { id: '3', roomumber: '01/300', fullname: 'คุณ สวัสดีครับ 300' },
    { id: '4', roomumber: '01/400', fullname: 'คุณ สวัสดีครับ 400' },
    { id: '5', roomumber: '01/500', fullname: 'คุณ สวัสดีครับ 500' },
    { id: '6', roomumber: '01/600', fullname: 'คุณ สวัสดีครับ 600' },
    { id: '7', roomumber: '01/700', fullname: 'คุณ สวัสดีครับ 700' },
    { id: '8', roomumber: '01/800', fullname: 'คุณ สวัสดีครับ 800' },
  ];
  title = 'Ananda Barcode';
  progressMode = false;
  bindingRoomNumber = '';
  txt1: string;
  txt2: string;
  txt3: string;

  ngOnInit(): void {
    this.room = '0';
    const rndInt = Math.floor(Math.random() * 3) + 1
    this.startIndex = rndInt;
    if (this.scanResult !== 'Waiting') {
      this.toggleVal = true;
    } else {
      this.toggleVal = false;
    }
  }

  toggleEvent(e: any) {
    if (this.scanResult !== 'Waiting') {
      this.toggleVal = true;
    } else {
      this.toggleVal = false;
    }
  }

  onOptionsSelectedParcel(item:string) {
    this.room = item;
    this.bindingRoomNumber =  item;
  }

  onCodeResult(result: string) {
    const rndInt = Math.floor(Math.random() * 3) + 1
    this.startIndex = rndInt;
    this.scanResult = result;
    if (result !== 'Waiting') {
      this.progressMode = true;
      setTimeout(() => {
        this.progressMode = false;
      }, 800);
    }
  }

  onSaveBrcode() {
    const resultItem =  this.mockRoomItems.find((obj) => {
      return obj.id === <any> this.bindingRoomNumber;
    });
    this.txt1 = this.pacelForm.value.txt1;
    this.txt2 = this.pacelForm.value.txt2;
    this.txt3 = this.pacelForm.value.txt3;
    console.log(this.makeid(6) + ":" + resultItem.roomumber);
    console.log('QRCode ==> ' +  this.scanResult);
    console.log(' this.txt1 : ' +  this.txt1);
    console.log(' this.txt2 : ' +  this.txt2);
    console.log(' this.txt3 : ' +  this.txt3);
    console.log(Date.now.toString());

    this.awaitSave = 1;
    setTimeout(() => {
      this.awaitSave = 0;
    }, 800);
    console.log(resultItem);
  }

  onClearBrcode() {
    this.scanResult = 'Waiting';
  }

  onClearPage() {
    window.location.reload();
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
 

}
