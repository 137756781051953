// import { Component } from '@angular/core';
// import { BarcodeFormat } from '@zxing/library';
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent {

//   formatsEnabled: BarcodeFormat[] = [
//     BarcodeFormat.CODE_93,
//     BarcodeFormat.CODE_39,
//     BarcodeFormat.CODE_128,
//     BarcodeFormat.CODABAR,
//     BarcodeFormat.DATA_MATRIX,
//     BarcodeFormat.EAN_13,
//     BarcodeFormat.QR_CODE,
//     BarcodeFormat.AZTEC,
//     BarcodeFormat.EAN_8,
//     BarcodeFormat.ITF,
//     BarcodeFormat.MAXICODE,
//     BarcodeFormat.PDF_417,
//     BarcodeFormat.RSS_14,
//     BarcodeFormat.RSS_EXPANDED,
//     BarcodeFormat.UPC_A,
//     BarcodeFormat.UPC_E,
//     BarcodeFormat.UPC_EAN_EXTENSION
//   ];
//   scanResult: any = '';

//   title = 'ANGULARQRSCANNER';

//   onCodeResult(result: string) {
//     this.scanResult = result;
//   }
// }



import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit {
  title = 'Ananda Parcel';

  ngOnInit(): void {
     $('.navbar-collapse a').click(function () {
      $('.navbar-collapse').collapse('hide');
    });
  }

}
