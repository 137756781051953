<html>
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style>
        img:hover {
            animation: shake 0.5s;
            animation-iteration-count: infinite;
        }

        @keyframes shake {
            0% {
                transform: translate(1px, 1px) rotate(0deg);
            }

            10% {
                transform: translate(-1px, -2px) rotate(-1deg);
            }

            20% {
                transform: translate(-3px, 0px) rotate(1deg);
            }

            30% {
                transform: translate(3px, 2px) rotate(0deg);
            }

            40% {
                transform: translate(1px, -1px) rotate(1deg);
            }

            50% {
                transform: translate(-1px, 2px) rotate(-1deg);
            }

            60% {
                transform: translate(-3px, 1px) rotate(0deg);
            }

            70% {
                transform: translate(3px, 1px) rotate(-1deg);
            }

            80% {
                transform: translate(-1px, -1px) rotate(1deg);
            }

            90% {
                transform: translate(1px, 2px) rotate(0deg);
            }

            100% {
                transform: translate(1px, -2px) rotate(-1deg);
            }
        }

        .bg {
            width: 100%;
            height: 100%;
            /* background: url("./assets/Images/app.jpg") no-repeat center;  */
            background-size: 100% 100%;
            background-position: top center;
            font-family: Kanit;
            /* background-color: #2c3a6f1c; */
            background-color: white;
        }

        .pointer {
            cursor: pointer;
        }
    </style>
</head>

<body class="bg" style="font-family: Kanit;">
    <nav class="navbar sticky-top navbar-expand-lg navbar-dark"
        style="background-color: #001b30; color: white;font-size: 15px;">
     <img src="../../../assets/images/512.jpeg" >
     <!-- <a class="navbar-brand" style="font-size: 0.45cm;padding-left: 0.1cm;" routerLink="/main/">
       #Ananda Parcel </a> -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText" style="margin-top: 0.2cm;">
            <ul class="navbar-nav mr-auto">
               
                <li class="nav-item active">
                    <a class="nav-link" style="color: #001b30;" routerLink="/main">&nbsp;</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" style="color: white;" routerLink="/main">หน้าหลัก</a>
                </li>
                <!-- <li class="nav-item active">
                    <a class="nav-link" style="color: white;" routerLink="qrout">พัสดุ</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" style="color: white;" routerLink="qrcode">แสกน QR</a>
                </li> -->
            </ul>

            <span class="navbar-text">
                <h6 style="
              color: white;
              font-size: 15px;
              font-family: Kanit;
              margin-top: 0.4cm;
            ">

                    <span class="label label-default">
                        User : {{this.declareUserName}}&nbsp;&nbsp;
                        <a class="pointer" (click)="logOut()"
                            style="color: white;font-size: 12.5px;padding-right: 0.5cm;">ออกจากระบบ</a>
                    </span>
                </h6>

            </span>
        </div>
    </nav>
    <main style="padding: 1px; margin: 0.5cm;background-color: white">
        <router-outlet></router-outlet>
    </main>
</body>

</html>