import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements  CanActivate {

constructor(private router: Router) {
}

canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const appAccess = localStorage.getItem('_token');
    if (appAccess === null) {
      this.router.navigate(['/main/']);
      return false;
    }
    return true;
  }
}

 