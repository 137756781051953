// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,

  mode: window["env"]["mode"] || "DEV",
  apiAuthen: window["env"]["apiAuthen"] || "https://api-uat.ananda.co.th/adc/bffs/parcel/core/",
  apiCoreParcelOut: window["env"]["apiCoreParcelOut"] ||  "https://api-uat.ananda.co.th/adc/bffs/parcel/CustomerParcel/",
  //apiCoreParcelOut: window["env"]["apiCoreParcelOut"] ||  "https://localhost:5003/api/CustomerParcel/",
  apiCoreView: window["env"]["apiCoreView"] || "https://adc-apim-dev.azure-api.net/cocoro/serv/ib/v1/parcel/",
  apiAone: window["env"]["apiAone"] || "https://api-uat.ananda.co.th/aonebackend/api/services/",
  apiPlugAndPlay: window["env"]["apiPlugAndPlay"] || "https://adc-apim-dev.azure-api.net/cocoro/serv/ib/v1/parcel/shipmentTrack",
  debug: window["env"]["debug"] || false
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
