<html>

<head>

  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <meta name="description" content="">
  <meta name="author" content="">

  <title>Authen</title>
  <style>
    .bg {
      background-size:cover;
      width: 100%;
      height: 100%;
      background-color: #f2f3f6;
      background-size: 100% 100%;
      background-position: top center;
      font-family: Kanit;
    }
  </style>
</head>

<body class="bg" >

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-5 col-lg-5 col-md-9" style="margin-top: 3cm;">
        <div class="card o-hidden border-0 shadow-sm my-5">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12">
                <div class="p-5">
                  <div class="text-right">
                   
                    <div class="d-flex justify-content-end"> 
                      <h1 class="h5  mb-3" style="color: black;font-weight: 200;font-family: Kanit;font-size: 20px;">
                        <mat-chip-list aria-label="Fish selection">
                          <mat-chip  style="background-color: #001b30;font-family: Kanit;font-size: 17px;" selected>Ananda</mat-chip> #Parcel
                        </mat-chip-list>
                       </h1>
                    </div>
                    <h1 class="h5  mb-2" style="color: #c85d5d9e;font-weight: 200;font-family: Kanit;font-size: 22px;">
                      #ยินดีต้อนรับ</h1>
                  </div>
                  <form class="user" #l="ngForm">
                    <div class="form-group">
                    </div>
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons">
                            email
                          </span>
                        </div>
                      </div>
                      <input autocomplete="false" ngModel name="exampleInputEmail1" type="text" style="color: #192d4b;"
                        class="form-control form-control-sm" placeholder="Username" />
                    </div>

                    <div class="mb-2"></div>
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons">
                            lock
                          </span>
                        </div>
                      </div>
                      <input ngModel name="exampleInputPassword1" type="password" style="color: #192d4b;"
                        class="form-control form-control-sm" placeholder="Password" />
                    </div>
                    <div class="form-group">
                    </div>
                    <div *ngIf="!btnLoginClick">
                      <button mat-raised-button class="btn  btn-block" (click)="loginServer()"
                        style="letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #001b30;color: white;">เข้าสู่ระบบ
                        / Login</button>
                    </div>
                    <div *ngIf="btnLoginClick2">
                      <button class="btn  btn-block" disabled style="background-color: #001b30;color: white;"
                        type="button">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        ระบบกำลังตรวจสอบ...
                      </button>
                    </div>
                  </form>
                  <div class="text-center">
                    <a class="small" href="#"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div style="text-align: center;">
          <a    style="color: #001b30;letter-spacing: 0.05em;">Tech department</a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" style="font-family: kanit;font-size: 18px;color: #a29061a9;">
            กรุณาระบุข้อมูล</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กำลังพัฒนา
        </div>
        <div class="modal-footer">
          <button type="button" class="btn " data-dismiss="modal"
            style="background-color: #112c53;color: white;">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</body>
</html>