import { TrandateModel } from './../models/trandate.module';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ParcelModel, ParcelListModel, ParcelListModelForCCRO } from '../models/parcel.module';
import { GenerateParcelNo } from '../models/generateparcelno.module';
import { ParcelOut } from '../models/parcelout.module';
import { ParcelPickStatus } from '../models/pickstatus.module';
import { ParcelPostReturn } from '../models/pacelpostreturn.module';
import { environment } from 'src/environments/environment';
import { ParcelAuthenLogModel, ParcelUniqIDModel, ParcelVersionModel } from '../models/parcelversion.module';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {


    _prefix = environment.apiCoreParcelOut;
    _expireToken = '401';
    _urlAnandaParcelList = this._prefix + 'fetchCustomerParcel';         //Method in Controller
    _urlAnandaParcelNo = this._prefix + 'fetchAndGenerateParcelNo';    //Method in Controller
    _urlAnandaParcelOut = this._prefix + 'fetchCustomerParcelOut';      //Method in Controller
    _urlAnandaParcelPick = this._prefix + 'UpdatePickedCustomerParcel';  //Method in Controller
    _urlAnandaParcelReturn = this._prefix + 'updateReturnCustomerParcel';  //Method in Controller
    _urlAnandaParcelListWithStatus = this._prefix + 'fetchCustomerParcelWithStatus';   //Method in Controller
    _urlAnandaUpdateImageCustomerParcel = this._prefix + 'updateImageCustomerParcel';    //Method in Controller
    _urlAnandaUpdateImageWithNullCustomerParcel = this._prefix + 'updateImageWithNullCustomerParcel';    //Method in Controller
    _urlAnandaParcelListWithStatusAndDate = this._prefix + 'fetchCustomerParcelWithStatusAndDate';     //Method in Controller
    _urlAnandaParcelListWithStatusAndDateForPCOut = this._prefix + 'fetchCustomerParcelWithStatusAndDateForPCOut';     //Method in Controller
    _urlAnandaParcelUpdateInstallApp = this._prefix + 'updateInstallApp';     //Method in Controller
    _urlAnandaParcelfetchParcelVersion = this._prefix + 'fetchParcelVersion';  //Method in Controller
    _urlAnandaParcelupdateParcelVersion = this._prefix + 'updateParcelVersion';  //Method in Controller
    _urlAnandaParcelvalidLoginDuplicate = this._prefix + 'validLoginDuplicate';  //Method in Controller
    _urlAnandaParcelfetchParcelCCROByID = this._prefix + 'fetchParcelCCROByID';  //Method in Controller
    _urlAnandaParcelupdateParcelCCROByID = this._prefix + 'UpdateParcelCCROByID';  //Method in Controller
    _urlAnandaParcelupdateAuthenLog = this._prefix + 'updateTrackAuthenLog';  //Method in Controller



    constructor(private http: HttpClient, private router: Router) {
        this.callENV();
    }

    async callENV() {
        this._prefix = await environment.apiCoreParcelOut;
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }


    //DO NOT USE
    fetchParcelFromAnanda(_searchPrefixForm: any, _parcelno: string): Observable<ParcelListModel[]> {

        var _token = localStorage.getItem('_token');
        var _data = {
            "unitNumber": _searchPrefixForm,
            "parcelno": _parcelno,
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        let options = { headers: headers };
        return this.http.post<ParcelListModel[]>(this._urlAnandaParcelList, _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    fetchParcelGenerateNo(user: any): Observable<GenerateParcelNo[]> {
        var _token = localStorage.getItem('_token');
        var _data = {
            "user": user
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        let options = { headers: headers };
        return this.http.post<GenerateParcelNo[]>(this._urlAnandaParcelNo, _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );

    }

    //** Re Use
    fetchParcelOut(user: any, uniNUmber: any): Observable<ParcelOut[]> {
        var _token = localStorage.getItem('_token');
        var _data = {
            "user": user,
            "unitnumber": uniNUmber
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        let options = { headers: headers };
        return this.http.post<ParcelOut[]>(this._urlAnandaParcelOut, _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );

    }

    //DO NOT USE ==> We Use Iprop
    PostParcelPick(formJson: any): Observable<ParcelPickStatus[]> {
        var _token = localStorage.getItem('_token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        let options = { headers: headers };
        return this.http.post<ParcelPickStatus[]>(this._urlAnandaParcelPick, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    PostToReturnParcel(user: any, id: any): Observable<ParcelPostReturn[]> {
        var _token = localStorage.getItem('_token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        var _data = {
            "user": user,
            "id": id
        }

        let options = { headers: headers };
        return this.http.post<ParcelPostReturn[]>(this._urlAnandaParcelReturn, _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    //DO NOT USE
    fetchParcelFromAnandaWithStatus(_searchPrefixForm: any, _parcelno: string, _status: string): Observable<ParcelListModel[]> {

        var _token = localStorage.getItem('_token');
        var _data = {
            "unitNumber": _searchPrefixForm,
            "parcelno": _parcelno,
            "status": _status
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        let options = { headers: headers };
        return this.http.post<ParcelListModel[]>(
            this._urlAnandaParcelListWithStatus
            , _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }


    PostToUpdateImageBlobParcel(user: any, _parcelno: any, _imageblob: any): Observable<ParcelPostReturn[]> {
        var _token = localStorage.getItem('_token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        var _data = {
            "user": user,
            "parcelno": _parcelno,
            "imageblob": _imageblob
        }

        let options = { headers: headers };

        return this.http.post<ParcelPostReturn[]>(this._urlAnandaUpdateImageCustomerParcel, _data, options)
            .pipe(

                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }


    PostUpdateImageNull(formJson: any): Observable<ParcelPickStatus[]> {
        var _token = localStorage.getItem('_token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        let options = { headers: headers };
        return this.http.post<ParcelPickStatus[]>(this._urlAnandaUpdateImageWithNullCustomerParcel, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    fetchParcelFromAnandaWithStatusAndDate(_searchPrefixForm: any, _parcelno: string, _status: string,
        _timestart: any, _timeend: any): Observable<ParcelListModel[]> {

        var _token = localStorage.getItem('_token');
        var _data = {
            "unitNumber": _searchPrefixForm,
            "parcelno": _parcelno,
            "status": _status,
            "timestart": _timestart,
            "timeend": _timeend,
        }
        console.log(_data);
        console.log(  this._urlAnandaParcelListWithStatusAndDate);
        //fetchCustomerParcelWithStatusAndDate

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        let options = { headers: headers };
        return this.http.post<ParcelListModel[]>(
            this._urlAnandaParcelListWithStatusAndDate
            , _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    fetchParcelFromAnandaWithStatusAndDateForPCOut(_searchPrefixForm: any, _parcelno: string, _status: string,
        _timestart: any, _timeend: any): Observable<ParcelListModel[]> {

        var _token = localStorage.getItem('_token');
        var _data = {
            "unitNumber": _searchPrefixForm,
            "status": _status,
            "timestart": _timestart,
            "timeend": _timeend,
        }
        console.log(_data);

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        let options = { headers: headers };
        return this.http.post<ParcelListModel[]>(
            this._urlAnandaParcelListWithStatusAndDateForPCOut
            , _data, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    PostUpdateInstallApp(_user: any, _parcelno: any, _installapp: any, _status: any): Observable<ParcelPickStatus[]> {
        var _token = localStorage.getItem('_token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

        var _data = {
            "user": _user,
            "parcelno": _parcelno,
            "installapp": _installapp,
            "status": _status
        }
        console.log('Teerat PostUpdateInstallApp Service');
        console.log(_data);
        // {
        //     "user": "teerat@ananda.co.th",
        //     "parcelno": "PC180-13112023-00013:mkmkkmk123",
        //     "installapp": "0",
        //     "status": "Collected"
        // }

        let options = { headers: headers };
        return this.http.post<ParcelPickStatus[]>(this._urlAnandaParcelUpdateInstallApp, _data, options)
            .pipe(
                map(response => {
                    console.log(this._urlAnandaParcelUpdateInstallApp);
                    console.log('Update Result : ' +response.toString() );
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    fetchParcelVersion(_token: any): Observable<ParcelVersionModel[]> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        var formJson = {
            id: 1,
            project: "ParcelWeb"
        };

        console.log("Teerat fetchParcelVersion");
        console.log(formJson);


        let options = { headers: headers };
        return this.http.post<ParcelVersionModel[]>(this._urlAnandaParcelfetchParcelVersion, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    recordVersionOnScreen(_token: any, _user, _version, _uniqid): Observable<ParcelPickStatus[]> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        var formJson = {
            id: 1,
            projectversionid: 1,
            pcuser: _user,
            screenversion: _version,
            uniqid: _uniqid
        };

        console.log("Teeat recordVersionOnScreen");
        console.log(formJson);
 
        let options = { headers: headers };
        return this.http.post<ParcelPickStatus[]>(this._urlAnandaParcelupdateParcelVersion, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    validLoginDuplicate(_user: any): Observable<ParcelVersionModel[]> {
        var _token = localStorage.getItem('_token');

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        var formJson = {
            pcuser: _user
        };

        console.log("Teerat : validLoginDuplicate");
        console.log(formJson);
        console.log(this._urlAnandaParcelvalidLoginDuplicate);

        let options = { headers: headers };
        return this.http.post<ParcelUniqIDModel[]>(this._urlAnandaParcelvalidLoginDuplicate, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    fetchParcelCCROByID(parcelIDFromCCRO: any): Observable<ParcelListModelForCCRO[]> {
        var _token = localStorage.getItem('_token');

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        var formJson = {
            id: parcelIDFromCCRO
        };

        console.log("Teerat : Service fetchParcelCCROByID");
        console.log(formJson);
        console.log(this._urlAnandaParcelfetchParcelCCROByID);

        let options = { headers: headers };
        console.log(options);
        return this.http.post<ParcelListModelForCCRO[]>(this._urlAnandaParcelfetchParcelCCROByID, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }

    updateParcelCCROByID(parcelIDFromCCRO: any, userType: any): Observable<ParcelListModelForCCRO[]> {
        var _token = localStorage.getItem('_token');

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });
        var formJson = {
            id: parcelIDFromCCRO,
            user: userType,
            qrupdate : '1' // Alway 1 because : Update With CCRO QRCode 
        };

        console.log("Teerat : updateParcelCCROByID");
        console.log(formJson);
        console.log(this._urlAnandaParcelupdateParcelCCROByID);

        let options = { headers: headers };
        return this.http.post<ParcelListModelForCCRO[]>(this._urlAnandaParcelupdateParcelCCROByID, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }
    
    updateTrackAuthenLog( formJson: any): Observable<ParcelAuthenLogModel[]> {
        var _token = localStorage.getItem('_token');

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
        });

          // **********************
          // Teerat 12 October 2023
          // Keep Authen Log
          // For Track User use what device and time auth. 
         
        console.log("Teerat : updateTrackAuthenLog");
        console.log(formJson);
        console.log(this._urlAnandaParcelupdateAuthenLog);

        let options = { headers: headers };
        return this.http.post<ParcelAuthenLogModel[]>(this._urlAnandaParcelupdateAuthenLog, formJson, options)
            .pipe(
                map(response => {
                    return <any>response;
                }),
                catchError(error => {
                    this.handleError("ERROR");
                    return of("ERROR");
                })
            );
    }
    
}
