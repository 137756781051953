import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParcelModel } from '../models/parcel.module';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParcelService {

    _prefix = environment.apiAone;
    _prefixPlugAndPlay = environment.apiPlugAndPlay
    _api_key = 'iprop'; // KEY
    _api_secret = 'gIthEto6ik'; // SECRET

  _urlGetToken =      this._prefix + 'integration/getToken'
  _urlSaveToParcel =  this._prefix + 'integration/shipmentTrack';
  _urlPrefixPlugAndPlay = this._prefixPlugAndPlay;
  
  constructor(private http: HttpClient) {
    this.callENV();
  }

  async callENV() {
    this._prefix = await environment.apiAone;
  }

   private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

   getToken() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      });

      var _data = {
        'api_key': this._api_key,
        'api_secret':this._api_secret
      }
      let options = { headers: headers };
      return this.http.post<tokenFromIntegration[]>(this._urlGetToken, _data, options)
        .pipe(
            map(response => {
                return <any>response;
            }),
            catchError(error => {
                this.handleError("ERROR");
                return of("ERROR");
            })
        );  
    }

    postToServer(token:any,_from:any) {
      let headers = new HttpHeaders({
        'API-USER-TOKEN': token
      });
      let options = { headers: headers };
      return this.http.post<SaveToShipmentTrack[]>(this._urlSaveToParcel, _from, options)
        .pipe(
          map(response => {
            return <any>response;
          }),
          catchError(error => {
            this.handleError("ERROR");
            return of("ERROR");
          })
        );  
      }

      //Teerat Save New Parcel
      postToPlugAndPlay(token:any,_from:any) {
        console.log("Teerat postToPlugAndPlay Service");
        console.log(_from);

        // Print For Eak
        //   {
        //     "customer_id": null,
        //     "status": "picked",
        //     "property_code": "202006",
        //     "unit_no": "26/001",
        //     "tracking_code": "PC180-13112023-00009:dIOO",
        //     "message": "teerat@ananda.co.th",
        //     "image_url": "https://firebasestorage.googleapis.com/v0/b/anandaforlab.appspot.com/o/picked.jpeg?alt=media&token=f87d7ace-6ac2-4a7d-b23e-4e92dc771eea",
        //     "user_type": "teerat@ananda.co.th",
        //     "receiver_name": "วรรณิศา หงษ์ทอง",
        //     "receiver_phone_number": "0849791532",
        //     "express_company": "Shoppee:ห่อกลาง M",
        //     "installapp": "1"
        // }
        // Print For Eak


        let headers = new HttpHeaders({
          // 'API-USER-TOKEN': token
          'Authorization': 'Basic cGFjZWwtYXBwOnBhY2VsLWFwcC0yMDIzMDkwNg==',
        });
        let options = { headers: headers };
        return this.http.post<SaveToShipmentTrack[]>(this._urlPrefixPlugAndPlay, _from, options)
          .pipe(
            map(response => {
              console.log('Update SaveToShipmentTrack : ' +response.toString() );
              return <any>response;
            }),
            catchError(error => {
              this.handleError("ERROR");
              return of("ERROR");
            })
          );  
        }

  }


export interface tokenFromIntegration {
  result?: {
    token?: string;
  },
  targetUrl?: string,
  success?: boolean,
  error?: string,
  unAuthorizedRequest?: boolean,
  __abp?: boolean
}

export interface SaveToShipmentTrack {
  result?: string,
  targetUrl?: string,
  success?: boolean,
  error?: string,
  unAuthorizedRequest?: boolean,
  __abp: boolean
}
  



