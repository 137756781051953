 <html>
    <header>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <style>
          .scanner {
              position: absolute;
            margin-left:13cm;
            margin-top: 7cm;
            width: 500px;
            height: 300px;
            
              /* width: 97%;
              height: 1100px; */
              /* margin-top: 10cm;
              margin-left: auto;
              margin-right:  auto; */
              /* align-items: center; */
              /* justify-content: center;
              text-align: center;
              vertical-align: center; */
              color: white;
              backdrop-filter: blur(8px) brightness(80%);
          }
          .dropzone2 {
              width: 650px;
              display: flex;
              align-items: left;
              justify-content: left;
              flex-direction: column;
              /* min-width: 80vw; */
              font-weight: 100;
              height: 100px;
              border: 2px dashed #192d4ba1;
              border-radius: 3px;
              background: rgba(247, 242, 232, 0.137);
              margin: 5px 0;
              padding-bottom: 0.5cm;
          }
      </style>
         
   
      
  
   
  
    <title></title>
</header>

<body>
    <div  *ngIf="scanResult === 'Waiting' ">
      <!-- <h1 class="scanner" ></h1> -->
      <zxing-scanner  (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"></zxing-scanner>
    </div>
    <div *ngIf="scanResult === 'Waiting' " style="margin-top: 0.25cm;">
      <button mat-raised-button class="btn  btn-block"  
        style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #112c53;color: white;">Refresh / รีเฟช
      </button>
    </div>
     <div class="container-fluid">
       <div class="row">
         <div class="col-12 container-fluid dropzone2 ">
           <a style="margin-top: 0.3cm;font-size: 20px;font-family: Kanit;padding-left: -0.2cm;">Scan Result : </a>
           <strong *ngIf="startIndex === 1 " style="color: rgba(191, 30, 212, 0.812);font-size: 20px;font-family: Kanit;">{{scanResult}}</strong>
           <strong *ngIf="startIndex === 2 " style="color: #192d4b;font-size: 20px;font-family: Kanit;">{{scanResult}}</strong>
           <strong *ngIf="startIndex === 3 " style="color: #00b28b;font-size: 20px;font-family: Kanit;">{{scanResult}}</strong>
         </div>
       </div>
        
       <div class="row" *ngIf="scanResult !== 'Waiting' " style="margin-top: 1.2cm;"  >
         <div class="col-12" >
           <select class="form-control form-control-sm" name="SpecRoomID" #SpecRoomID id="SpecRoomID"
             style="font-family: Kanit;margin-top: -0.75cm;">
             <option *ngFor="let items of mockRoomItems" style="font-family: Kanit;" [value]="items.id">
               {{items.roomumber}}
             </option>
           </select>
         </div>
         <div class="col-12" style="margin-top: 0.1cm;">
          <div class="input-group mr-sm-12">
            <div class="input-group-prepend" style="height: 31px;">
              <div class="input-group-text">
                <span class="material-icons">
                  #
                </span>
              </div>
            </div>
            <input autocomplete="false" ngModel name="exampleInputEmail1" type="text" 
            style="font-family: Kanit;color: #192d4b;"
              class="form-control form-control-sm" placeholder="กรอกมาครับ 1" />
          </div>
        </div>
        <div class="col-12" style="margin-top: 0.1cm;">
          <div class="input-group mr-sm-12">
            <div class="input-group-prepend" style="height: 31px;">
              <div class="input-group-text">
                <span class="material-icons">
                  #
                </span>
              </div>
            </div>
            <input autocomplete="false" ngModel name="exampleInputEmail2" type="text" 
            style="font-family: Kanit;color: #192d4b;"
              class="form-control form-control-sm" placeholder="กรอกมาครับ 2" />
          </div>
        </div>
        <div class="col-12" style="margin-top: 0.1cm;">
          <div class="input-group mr-sm-12">
            <div class="input-group-prepend" style="height: 31px;">
              <div class="input-group-text">
                <span class="material-icons">
                  #
                </span>
              </div>
            </div>
            <input autocomplete="false" ngModel name="exampleInputEmail3" type="text" 
            style="font-family: Kanit;color: #192d4b;"
              class="form-control form-control-sm" placeholder="กรอกมาครับ 3" />
          </div>
        </div>
         
         <!-- <div class="input-group mr-sm-12">
          <div class="input-group-prepend" style="height: 31px;">
            <div class="input-group-text">
              <span class="material-icons">
                #
              </span>
            </div>
          </div>
          <input autocomplete="false" ngModel name="exampleInputEmail1" type="text" 
          style="font-family: Kanit;color: #192d4b;"
            class="form-control form-control-sm" placeholder="กรอกมาครับ" />
        </div> -->

         <div  style="margin-top: 0.35cm;margin-left: 0.3cm;">
          <button mat-raised-button class="btn  btn-block" (click)="onClearBrcode()" 
            style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #112c53;color: white;">Clear
            / รีเฟช
          </button>
        </div>
       </div>

      





     </div>

     
   
</body>
 </html>
   

     


 
 

