import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labcom',
  templateUrl: './labcom.component.html',
  styleUrls: ['./labcom.component.css']
})
export class LabcomComponent implements OnInit {

   
    _mockModel : MockModel[] = <any> [];

   
  constructor() { }

  ngOnInit(): void {
    this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    });
    this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    }); this._mockModel.push( {
      name: 'Dev',
      money: '10,000',
      desc: 'Ok',
    });
  }

}



export interface MockModel {
  name: string;
  money:string;
  desc: string;
}