<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
<title></title>
  
</head>

<body style="font-family: Kanit;" style="padding-top: 1cm;">

  <div class="container-fluid" style="background-color: #eeeff3;"   > 
    <div class="row" >

        <div *ngFor="let item of _mockModel;" class="col-12" style="margin-bottom: 0.4cm">
            <mat-card class="example-card mat-elevation-z2" style="padding-bottom: 0.8cm;">
                <mat-card-header>
                  <div mat-card-avatar  >
        
                    <span class="material-icons">
                      add
                  </span>
                  </div>
                  <mat-card-title>{{item.name}}</mat-card-title>
                  <mat-card-subtitle style="font-family: Kanit;">Admin Total : {{item.money}}</mat-card-subtitle>
                </mat-card-header>
                <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
                <mat-card-content>
                  <p>
                    {{item.desc}}
                  </p>
                </mat-card-content>
                <!-- <mat-card-actions>
                  <button mat-raised-button color="primary">
                    <span class="material-icons">
                      search
                  </span>
                    View</button>
                </mat-card-actions> -->
              </mat-card>
        </div>
     </div>
     
  </div>

 

  
</body>

</html>