<html>
<header>
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  <style>
    .scanner {
      position: absolute;
      margin-left: 13cm;
      margin-top: 7cm;
      width: 500px;
      height: 300px;
      color: white;
      backdrop-filter: blur(8px) brightness(80%);
    }

    .dropzone2 {
      width: 650px;
      display: flex;
      align-items: left;
      justify-content: left;
      flex-direction: column;
      font-weight: 100;
      height: 100px;
      border: 2px dashed #192d4ba1;
      border-radius: 3px;
      background: rgba(247, 242, 232, 0.137);
      margin: 5px 0;
      padding-bottom: 0.5cm;
    }

    .imagecontainer {
      background: url('../../assets/images/bg2.jpg') no-repeat center;
     background-size: cover;
      /* height: 150px;
      width: 100%; */
      /* width: 100px;
      height: auto;
      border: 1px solid;
      background-size: contain; */
    }

    div.background-parent
    {
      position: relative;
      width: auto;
      height: 135px;
      /* 350 */
    }
    .background {

      border-radius: 12px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-size:cover;
      background-image: url('../../assets/images/bg4.jpg');
    }

    img {
    max-width: 100%;
    max-height: 100%;
    }

    .portrait {
    height: 80px;
    width: 30px;
    }

    .landscape {
    height: 60px;
    width: 100px;
    }

    .verticalHR {
            border-left: 6px solid red;
            height: 200px;
            position:absolute;
            left: 50%;
        }

        .modal-backdrop {
  z-index: -1;
}
.assigned-chip{
  background-color:  #001b30;
  /* 001b30 */
  
}
.assigned-chipEditCancel{
  
  background-color:  #00b28b;
  color: white;
  /* 001b30 */
}

.assigned-chipEditCancel{
  background-color:  #00b28b;
  color: white;
  /* padding: 0.45cm; */
  /* padding: 1cm;
  margin: 1cm;
  border-radius: 115px; */
  }

.assigned-chipF{
  background-color:  #00b28b33;
}
.assigned-chipImage{
  background-color:  #001b30;
}

.customIconSize {
  font-size: 50px;
  height: 50px ;
  width: 50px ;
}

.assigned-chipDone{
background-color:  #00b28b;
font-size: 10px;
/* padding: 1cm;
margin: 1cm;
border-radius: 115px; */
}

.assigned-chipEdit{
  background-color:  #001b30;
  color: white;
  /* padding: 1cm;
  margin: 1cm;
  border-radius: 115px; */
  }



  </style>
  <title></title>
</header>

<body>

<div style="height: 100%;"  >
    <div class="row"  style="margin-top: 0.45cm;padding-bottom: 0.5cm;" >
      <div class="col-8">
        <a style="padding-left: 0.8cm;margin-top: 0.5cm;margin-bottom: 0.3cm;font-family: kanit;font-size: 22px;color: red;">
          #จำนวนทั้งหมด : {{_castArrayVerrify.length}}</a>
      </div>
    <div class="col-4" style="margin-top: 0.12cm;padding-right: 2.5cm;">
      <mat-chip-list #chipEditCancel>
        <mat-chip 
        (click)="onGobackToHomeMethodClear($event)"
        class="assigned-chipEditCancel"
         style="font-size: 16.5px;font-family: kanit;padding: 0.45cm;;"   >
          ยกเลิก
        </mat-chip>
      </mat-chip-list>
    </div>
         
    </div>
   
    
        <div class="container-fluid"
          style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.7cm;margin-top: 0.3cm;">

          <div class="container-fluid card"
            style="padding-top: 0.4cm;padding-bottom: 0.4cm;margin-top: 0.1cm;background-color: #e9ecef;border-radius: 9px;margin-bottom: 0.1cm;">

            <div *ngFor="let item of _castArrayVerrify; let i =index"  class="row" style="background-color: white;margin: 0.1cm;border-radius: 9px">
              <div class="col-12 container-fluid " style="padding-top: 0.25cm;font-family: kanit;font-size: 16.0px;">
               <mat-chip-list #chipEdit>
                <mat-chip class="assigned-chipEdit" style="margin-left: 0.1cm;margin-top: 0.3cm;font-size: 16.5px;font-family: kanit;text-align: left;"   >
                  No.{{i+1}}
                </mat-chip>
              </mat-chip-list>
               
               <hr>
              </div>
              <div class="col-12 container-fluid " style="font-family: kanit;font-size: 15.0px;">
                หมายเลขติดตามพัสดุ
               </div>
              
              <div class="col-12 container-fluid " style="font-family: kanit;font-size: 15.0px;">
               <a style="color: #00b28b;">{{item.tracking_code}}</a> 
               </div>
               <div class="col-12 container-fluid " style="padding-top: 0.25cm;font-family: kanit;font-size: 15.0px;">
                ชื่อผู้รับ คุณ <a style="color: #00b28b;">{{item.receiver_name}}</a>
               </div>
               <div class="col-12 container-fluid " style="font-family: kanit;font-size: 15.0px;">
                บ้านเลขที่ <a style="color: #00b28b;">{{item.unit_no}}</a>
               </div>
               <div class="col-12 container-fluid " style="font-family: kanit;font-size: 15.0px;">
                เบอร์ <a style="color: rgba(255, 0, 0, 0.615);">{{item.receiver_phone_number_logic}}</a>
                <!-- <hr>   -->
                </div>
                <!-- <div class="col-12 container-fluid " style="font-family: kanit;font-size: 15.0px;">
                    เฉลยให้อ่ะ หยอกๆๆ <a style="color: #00b28b;">{{item.receiver_phone_number_logicRight}}</a>
                   </div> -->
                   <form  #te="ngForm">
                   <div class="col-12 container-fluid " style="font-family: kanit;font-size: 15.0px;">
                    <div class="row">
                        <div class="col-10">
                            <mat-form-field class="example-full-width" *ngIf="item.confirm === '0' " 
                              style="font-family: Kanit;font-size: 17px; width: 100%;text-align: left;margin-top: -0.05cm;">
                              <mat-label 
                                style=" color: rgb(153, 153, 153);font-size: 12px;padding-left: 0.25cm;color: rgba(255, 0, 0, 0.656);">
                                #กรุณาระบุเลข 4 ตัวท้าย&nbsp;&nbsp;
                              </mat-label>
                             <!-- <mat-label *ngIf="item.confirm === '1' " 
                              style=" color: rgb(153, 153, 153);font-size: 12px;padding-left: 0.25cm;color: rgba(255, 0, 0, 0.656);">
                              {{item.receiver_phone_number}}
                            </mat-label> -->
                            

                              <input maxlength="4" (input)="onkeyTeleNumber($event.target.value,i)"
                                (keypress)="numberPhoneOnly($event)" *ngIf="item.confirm === '0' "  
                                 ngModel name="passnum" id="passnum" matInput>
                                 <!-- <input maxlength="4" (input)="onkeyTeleNumber($event.target.value,i)"
                                 (keypress)="numberPhoneOnly($event)" *ngIf="item.confirm === '1' " disabled
                                  ngModel name="passnum" id="passnum" matInput> -->
                                 
                            </mat-form-field>

                            <input    *ngIf="item.confirm === '1' " disabled
                            style="color: rgb(224, 24, 24);font-size: 14px;margin-top: 0.25cm;margin-bottom: 0.35cm;" 
                             class="form-control form-control-sm" placeholder="{{item.receiver_phone_number}}" />
                          </div>
                          <div class="col-2" style="margin-top: 0.25cm;">
                            <mat-chip-list #chipEdit>
                                <!-- <mat-chip class="assigned-chipEdit"  *ngIf="item.btn_enable === '0' "
                                  disable
                                  style="padding: 0.5cm;;margin-left: 0.1cm;margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                                  ยืนยัน&nbsp;
                                 
                                </mat-chip> -->
                                <!-- <mat-chip class="assigned-chipEdit"  *ngIf="item.onkeep === item.receiver_phone_number_logicRight "
                                 
                                style="padding: 0.5cm;;margin-left: 0.1cm;margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                                ยืนยัน&nbsp;
                               
                              </mat-chip> -->
                              <mat-chip-list #chipDone *ngIf="item.onkeep === item.receiver_phone_number_logicRight ">
                                <mat-chip class="assigned-chipDone" >
                                  <span   class="material-icons"
                                    style="color: white"> done </span>
                                </mat-chip>
                              </mat-chip-list>
                              </mat-chip-list>
                          </div>
                    </div>
                    
                   </div>
                   </form>

            </div>


            <div class="col-12" style="margin-top: 0.4cm;text-align: center;margin-bottom: 0.6cm;">
              <button mat-raised-button class="btn  btn-block" *ngIf=" _countMatch  === _countKey "
              (click)="onGobackToHomeMethod($event)"
                style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                ยืนยันการจ่ายออก
              </button>
              
            </div>
          </div>
        </div>

     
</div>


</body>

</html>
