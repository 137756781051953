import { IpropService } from './services/iprop.service';
import { DashboardService } from './services/dashboard.service';
import { BottomSheetOverviewExampleSheet, HomeComponent } from './home/home.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AppComponent } from './app.component';
import { AuthenComponent } from './authen/authen.component';
import { MainComponent } from './share/main.component';
import { HeaderComponent } from './share/header/header.component';

import { Routes, RouterModule } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from "@angular/common";import { HttpClientModule } from "@angular/common/http";
import { LOCALE_ID } from "@angular/core";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule} from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule} from '@angular/material/badge';
import { MatCheckboxModule } from "@angular/material/checkbox";

import { AuthenService } from './services/authen.service';
import { ParcelService } from './services/parcel.service';
import { ScanoutComponent } from './scanout/scanout.component';
import { ServererrorInterceptorService } from './services/servererror-interceptor.service';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { LabcomComponent } from './labcom/labcom.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';


const appRoutes: Routes = [
  { path: "", redirectTo: "/authen", pathMatch: "full" },
  {
    path: "main",
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeComponent },
      //{ path: "a", component: ScanoutComponent },
    ],
  },
 { path: "**", component: AuthenComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    AuthenComponent,
    MainComponent,
    HeaderComponent,
    QrcodeComponent,
    BarcodeComponent,
    HomeComponent,
    LabcomComponent,
    ScanoutComponent,
    BottomSheetOverviewExampleSheet
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
    ZXingScannerModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatBottomSheetModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "en-US" },
    AuthGuard,
    MatDatepickerModule,
    MatNativeDateModule,
    DatePipe,
    AuthenService,
    ParcelService,
    DashboardService,
    ServererrorInterceptorService,
    IpropService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
