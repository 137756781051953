<html>
<header>
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  <style>
    .scanner {
      position: absolute;
      margin-left: 13cm;
      margin-top: 7cm;
      width: 500px;
      height: 300px;
      color: white;
      backdrop-filter: blur(8px) brightness(80%);
    }

    .dropzone2 {
      width: 650px;
      display: flex;
      align-items: left;
      justify-content: left;
      flex-direction: column;
      font-weight: 100;
      height: 100px;
      border: 2px dashed #192d4ba1;
      border-radius: 3px;
      background: rgba(247, 242, 232, 0.137);
      margin: 5px 0;
      padding-bottom: 0.5cm;
    }

    .imagecontainer {
      background: url('../../assets/images/bg2.jpg') no-repeat center;
      background-size: cover;
      /* height: 150px;
      width: 100%; */
      /* width: 100px;
      height: auto;
      border: 1px solid;
      background-size: contain; */
    }

    div.background-parent {
      position: relative;
      width: auto;
      height: 135px;
      /* 350 */
    }

    .background {

      border-radius: 12px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-image: url('../../assets/images/bg4.jpg');
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .portrait {
      height: 80px;
      width: 30px;
    }

    .landscape {
      height: 60px;
      width: 100px;
    }

    .verticalHR {
      border-left: 6px solid red;
      height: 200px;
      position: absolute;
      left: 50%;
    }

    .modal-backdrop {
      z-index: -1;
    }
  </style>
  <title></title>
</header>

<body>
  <div style="text-align: end;font-weight: bold"
    *ngIf=" _checkVersionFromLocal !== _checkVersionFromServer  && _resultMatchingUniqId === '1'  ">
    <a style="font-family: kanit;font-size: 14px;color: red;">
      #กรุณา Logout <br>เนื่องจากเวอร์ชั่นของคุณไม่ตรงกับ เวอร์ชั่นปัจจุบัน
    </a>
  </div>

  <div style="text-align: end;font-weight: bold;"
    *ngIf=" _checkVersionFromLocal === _checkVersionFromServer   && _resultMatchingUniqId === '1' ">
    <a style="font-family: kanit;font-size: 14px;color: #00b28b;">
      #เวอร์ขั่นปัจจุบัน : ({{_checkVersionFromServer}})
    </a>
  </div>

  <div *ngIf=" _resultMatchingUniqId === '0'  ">


    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">
        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>


            <div class=" justify-content-center" style="border-radius: 9px; padding-top: 0.1cm;padding-bottom: 0.2cm;">
              <div style="border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
                <div class="row">
                  <div class="col-12">
                    <!-- <mat-card   class="shadow-md"  >
                          <div class="row  " style="font-family: kanit;">
                            <span class="material-icons">
                              Ake
                            </span>&nbsp;DEV
                          </div>
                          
                        </mat-card> -->
                    <mat-card class="example-card">
                      <img style="width: 35px;height: 35px;" src="../../assets/images/errorS.png"
                        class="rounded-circle">
                      <a style="font-family: kanit;font-size: 18.5px;">
                        &nbsp;Account : {{ this._userAccount}}
                      </a>
                      <mat-card-header style="margin-top: 0.3cm;">
                        <mat-card-subtitle style="font-family: kanit;font-size: 16px;">

                          <a style="font-family: kanit;color: red;">
                            มีการ Login ด้วย Account นี้<br>มากกว่า 1 เครื่อง !!!
                          </a>
                        </mat-card-subtitle>
                      </mat-card-header>
                      <mat-card-actions>
                        <!-- <button mat-button style="font-family : kanit;font-size: 18.5px;">อยู่ต่อ</button>
                            <button mat-button style="font-family : kanit;font-size: 18.5px;">พอแค่นี้</button> -->
                        <!-- <button mat-raised-button style="font-family : kanit;font-size: 14.5px;letter-spacing: 1.3px;">อยู่ต่อ</button> -->
                        <button (click)="logOut()" mat-raised-button
                          style="color: white;background-color: #192d4b;margin-left: 0.5cm;margin-bottom: 1.0cm;font-family : kanit;font-size: 13.5px;letter-spacing: 1.3px;">ออกจากระบบ..</button>

                      </mat-card-actions>
                    </mat-card>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>

  <div *ngIf="_state === '0'  && _resultMatchingUniqId === '1'  ">

    <div style="margin-top: 0.3cm">
      <div class="row" style=" background-color: white;border-radius: 9px; padding-top: 0.3cm;padding-bottom: 0.2cm;">

        <!-- <div class="col-12" style="text-align: center;font-family:kanit;">
                <div class="col-12" style="margin-bottom: 0.5cm;background-color: white;border-radius: 6px;font-family:kanit;">
                    <div class="row justify-content-center" >
                        <div class="col-12" style="font-size: 25px;">#พัสดุ 
                          </div>
                          <a style="font-family: kanit;font-size: 0.5;color: #c4c4c4;">Version 3.1</a> 
                    </div>
                  </div>
            </div> -->

        <div class="col-12" style="text-align: center;font-family:kanit;">

          <div class="row justify-content-center" *ngIf="_checkVersionFromLocal === _checkVersionFromServer">
            <mat-card  (click)="scanQRCode()" class="col-3"
              style="border-color: #192d4b;border-radius: 6px;font-family:kanit;">
              <div class="row justify-content-center">
                <div class="col-12 row justify-content-center">
                  <mat-chip-list #chipList>
                    <!-- <mat-chip>
                      <span (click)="scanQRCode()" class="material-icons" style="font-size: 14px;"> add </span>
                    </mat-chip> -->
                    <mat-chip>
                      <span   class="material-icons" style="font-size: 14px;"> close </span>
                    </mat-chip>
                  </mat-chip-list>
                </div>
                <!-- <div class="col-12" style="font-size: 15px;padding-top: 0.3cm;letter-spacing: 0.03cm;">เพิ่มพัสดุ</div> -->
                <div class="col-12" style="color:red;font-size: 15px;padding-top: 0.3cm;letter-spacing: 0.03cm;">ปิดบริการ ชั่วคราว</div>

              </div>
            </mat-card>
            &nbsp; &nbsp;
            <mat-card (click)="btnparcelOut()" class="col-3" style="border-radius: 6px;font-family:kanit;">
              <div class="row justify-content-center">
                <div class="col-12 row justify-content-center">
                  <mat-chip-list #chipList>
                    <mat-chip class="assigned-chipF">
                      <span (click)="btnparcelOut()" class="material-icons" style="font-size: 14px;"> inventory_2
                      </span>
                    </mat-chip>
                  </mat-chip-list>
                </div>
                <div class="col-12" style="font-size: 15px;padding-top: 0.3cm;letter-spacing: 0.03cm;">
                  จ่ายพัสดุ</div>
              </div>
            </mat-card>
            &nbsp; &nbsp;
            <mat-card (click)="btnparcelOutByQR()" class="col-3" style="border-radius: 6px;font-family:kanit;">
              <div class="row justify-content-center">
                <div class="col-12 row justify-content-center">
                  <mat-chip-list #chipList>
                    <mat-chip class="assigned-chipF">
                      <!-- <span  (click)="btnparcelOutByQR()" class="material-icons" style="font-size: 14px;"> qr_code </span> -->
                      <img src="../../assets/images/app.png" width="22px" height="150px">
                    </mat-chip>
                  </mat-chip-list>
                </div>

                <div class="col-12" style="color:#d23f3fd8;;font-size: 15px;padding-top: 0.3cm;letter-spacing: 0.03cm;">
                  จ่ายพัสดุ (QR)
                </div>
              </div>
            </mat-card>
            &nbsp;
          </div>

        </div>
      </div>
    </div>

    <div class="row justify-content-center"
      style="font-weight: bold;font-family: kanit;font-size:19px;margin-top: 0.8cm;">
      - ค้นหารายการพัสดุ -
    </div>

    <!-- From for Search Landing Page-->
    <div *ngIf="_anandaMultiSiteOnHistory.length !== 0 && _workingDelete === 0  " class="row justify-content-center"
      style="font-weight: bold;font-family: kanit;font-size:19px;margin-top: 0.8cm;">

      <div class="col-12" style="margin-top: -0.6cm;color:#010101;font-size: 18px;text-align: center;padding: 0.5cm;">
        <form #s="ngForm">
          <div class="row justify-content-center">
            <div class="col-12">
              <select class="form-control form-control-sm" (change)='onSelectPrefixSearch(prefixsearch.value)'
                name="prefixsearch" #prefixsearch id="prefixsearch"
                style="font-size: 14.5px;height: 35px;font-family: Kanit;margin-top: -0.10cm;   ">

                <option *ngFor="let items of _anandaMultiSiteOnHistory" style="font-family: kanit;border-color: white;"
                  [value]="items.prefixhouse">
                  {{items.projectnamethai}}
                </option>
              </select>
            </div>

            <div class="col-12" style="margin-top: 0.15cm;">
              <div class="input-group mr-sm-12">
                <div class="input-group-prepend" style="height: 31px;">
                  <div class="input-group-text">
                    <span class="material-icons">
                      pin
                    </span>
                  </div>
                </div>
                <input ngModel #roomlanding name="roomlanding" id="roomlanding"
                  style="border-color: #00b28bb9;color: rgb(224, 24, 24);font-size: 14px;"
                  (keypress)="numberOnly($event)" class="form-control form-control-sm"
                  placeholder="ระบุเลขที่ห้อง Optional" />
              </div>
            </div>




            <div class="col-12" style="margin-top: 0.25cm;">
              <select class="form-control form-control-sm" (change)='onSelectParcelStatus(parcelstatus.value)'
                name="parcelstatus" #parcelstatus id="parcelstatus"
                style="font-size: 14.5px;height: 35px;font-family: Kanit;margin-top: -0.10cm;   ">

                <option *ngFor="let items of parcelStatustModel" style="font-family: kanit;border-color: white;"
                  [value]="items.val">
                  {{items.desc}}
                </option>
              </select>
            </div>

            <div class="col-12" style="text-align: right;margin-top: 0.25cm;color:rgb(84, 82, 82);">
              <div class="row">
                <div class="col-12">
                  <a style="font-size: 15.5px;color:#00b28b;padding-right: 0.3cm;">ว/ด/ป</a>
                </div>
              </div>
            </div>


            <!-- Add Filter Date -->
            <div class="col-12" style="margin-top: 0.15cm;color:rgb(84, 82, 82);">
              <div class="row">
                <div class="col-6">
                  <mat-form-field appearance="outline" style="width: 150px;">
                    <mat-label style="font-family: kanit;font-size: 13px;color: rgb(224, 24, 24);">
                      {{_dSTimeSelected}}</mat-label>
                    <input matInput [matDatepicker]="pickerA" [(ngModel)]="_dSTimeSelected" #StartDateS readonly
                      (dateChange)="OnStartDateChange(StartDateS.value)" name="StartDateS"
                      style="font-family: kanit;font-size: 12px;border-color: yellow;" />
                    <mat-datepicker-toggle matSuffix [for]="pickerA">
                    </mat-datepicker-toggle>
                    <mat-datepicker style="font-family: kanit;font-size: 15px" #pickerA disabled="false">
                    </mat-datepicker>
                  </mat-form-field>

                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline" style="width: 150px;">
                    <mat-label style="font-family: kanit;font-size: 13px;color: rgb(224, 24, 24);">
                      {{_dETimeSelected}}</mat-label>
                    <input matInput [matDatepicker]="pickerB" [(ngModel)]="_dETimeSelected" #StartDateE readonly
                      (dateChange)="OnEndDateChange(StartDateE.value)" name="StartDateE"
                      style="font-family: kanit;font-size: 12px;" />
                    <mat-datepicker-toggle matSuffix [for]="pickerB">
                    </mat-datepicker-toggle>
                    <mat-datepicker style="height:10px;font-family: kanit;font-size: 15px" #pickerB disabled="false">
                    </mat-datepicker>
                  </mat-form-field>

                </div>

              </div>
            </div>
            <!-- Add Filter Date -->

            <div class="col-12" style="margin-top: -0.20cm;">
              <div class="input-group mr-sm-12">
                <div class="input-group-prepend" style="height: 31px;">
                  <div class="input-group-text">
                    <span class="material-icons">
                      pin
                    </span>
                  </div>
                </div>
                <input ngModel name="inputParcelNoForm" id="inputParcelNoForm" style="color: #192d4b;"
                  class="form-control form-control-sm" placeholder="Parcel No : 0000X : Optional" />
              </div>
            </div>
            <div class="col-12" style="margin-top: 0.43cm;">
              <button *ngIf="_searchPrefixForm === 0 " disabled mat-raised-button class="btn  btn-block"
                style="padding: 0.13cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                &nbsp;&nbsp;ค้นหาพัสดุ&nbsp;
                <span class="material-icons" style="width: 12px;">
                  search
                </span>
              </button>
              <button (click)="searchToParcelListModel()" *ngIf="_searchPrefixForm !== 0 " mat-raised-button
                class="btn  btn-block"
                style="padding: 0.13cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                &nbsp;&nbsp;ค้นหาพัสดุ&nbsp;
                <span class="material-icons" style="width: 12px;">
                  search
                </span>
              </button>
            </div>
          </div>

        </form>


        <div *ngIf="parcelListModel.length > 0 " class="row justify-content-end col-12" style="margin-top: 0.23cm;">
          <!-- <a  (click)="exportToExcel()"     
                style="padding: 0.13cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                &nbsp;&nbsp;Export to Excel&nbsp;
                <span class="material-icons" style="width: 12px;">
                  system_update_alt
                </span>
              </a> -->
          <a (click)="exportToExcel()" class="row justify-content-end"
            style="text-align: right;font-family: kanit;font-size: 13.5px;color: red;">
            #Export-To-Excel..&nbsp;&nbsp;
            <span class="material-icons" style="width: 12px;">
              system_update_alt
            </span>
          </a>

        </div>

      </div>
    </div>
    <!-- From for Search Landing Page -->

    <!-- Teerat Search -->
    <!-- Loop parcel list  Histoy  -->
    <div class="row" *ngIf="_workingDelete === 0 ">
      <div class="col-12">
        <div style="background-color: #f6f6f778;border-radius: 9px; padding-top: 0.5cm;padding-bottom: 0.2cm;">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <mat-card *ngFor="let item of parcelListModel" class="col-12"
                  style="border-radius: 6px;margin-bottom: 0.2cm;">
                  <div class="row">
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;margin-bottom: 0.25cm;">
                      <mat-chip-list #chipList>
                        <mat-chip class="assigned-chip"
                          style="letter-spacing: 0.03cm;padding: 0.45cm;;font-size: 10px;color:white;">
                          {{item.unitNumber}}
                        </mat-chip>


                        <a *ngIf="item.installapp === '0' || item.recipientphonenumber === '' "
                          style="font-family: kanit;color: red;">&nbsp;** ไม่ได้ติดตั้ง COCORO APP **</a>

                        <mat-chip class="assigned-chipImage" *ngIf="item.mapimageblob !== '-'"
                          (click)="viewImage(item.id,item.mapimageblob,item.parcelno)"
                          style="padding: 0.45cm;;font-size: 10px;color:white;font-weight: 700;">
                          รูปพัสดุ&nbsp;<span class="material-icons" style="width: 9px;">
                            search
                          </span>
                          &nbsp;
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                    <a hidden #tmpuseraccount id="tmpuseraccount" name="tmpuseraccount" value="{{item.user}}">
                      {{item.user}}
                    </a>
                    <div
                      *ngIf="_userAccount === tmpuseraccount.value &&  item.status !== 'Collected' && item.status !== 'Returned'   "
                      class="col-2" (click)="setRetutnedParcel(item.id)">
                      <span class="material-icons" style="width: 12px;">
                        delete_forever
                      </span>
                    </div>
                    <div *ngIf="_userAccount !== tmpuseraccount.value " hidden class="col-2"
                      (click)="setRetutnedParcel(item.id)">
                      <span class="material-icons" style="width: 12px;">
                        delete_forever
                      </span>
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      ID :
                      <a  style="font-family: kanit;color: #00000052;">{{item.id}}</a>
                      <!-- <a *ngIf="item.status === 'Collected' &&  item.qrupdate !== '1' " style="font-family: kanit;color: #000;">คีย์จ่ายพัสดุ (Web)</a>
                      <a *ngIf="item.status === 'Collected' &&  item.qrupdate === '1' " style="font-family: kanit;color: #000;">คีย์จ่ายพัสดุ (QR Code)</a>
                      <a *ngIf="item.status === 'Returned' " style="font-family: kanit;color: #000;">ลบพัสดุ</a> -->
                      &nbsp;
                      <img *ngIf="item.qrupdate === '1' " src="../../assets/images/app.png" width="23px" height="23px">
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      Status :
                      <a *ngIf="item.status === 'Arrived' " style="font-family: kanit;color: #000;">คีย์รับพัสดุ</a>
                      <a *ngIf="item.status === 'Collected' &&  item.qrupdate !== '1' " style="font-family: kanit;color: #000;">คีย์จ่ายพัสดุ (Web)</a>
                      <a *ngIf="item.status === 'Collected' &&  item.qrupdate === '1' " style="font-family: kanit;color: #000;">คีย์จ่ายพัสดุ (QR Code)</a>
                      <a *ngIf="item.status === 'Returned' " style="font-family: kanit;color: #000;">ลบพัสดุ</a>
                      &nbsp;
                      <img *ngIf="item.qrupdate === '1' " src="../../assets/images/app.png" width="23px" height="23px">
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      Running No : {{item.parcelno}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      QR Code : {{item.qrcode}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 12px;font-family: kanit;">
                      Staff (ผู้รับพัสดุ) : <a style="color: red;">{{item.user}}</a>
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      Trans & Package : {{item.expresscompany}} : {{item.package}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 12px;font-family: kanit;color: #192d4b57;">
                      ** <a style="color: #00b28bb9;">วันที่ทำรายการ : {{item.mapcreationtodate}}</a> **
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                  </div>
                </mat-card>
                <mat-card *ngIf=" _startFetch === 2 && (parcelListModel === null || parcelListModel.length === 0) "
                  class="col-12" style="border-radius: 6px;margin-bottom: 0.2cm;">
                  <div class="row justify-content-center" style="text-align: center;">
                    <a
                      style="color: #00b28bb9;font-size: 20px;text-align: center;font-family:kanit;">กรุณารอซักครู่...</a>
                  </div>
                </mat-card>

                <mat-card *ngIf=" _startFetch === 1 && (parcelListModel === null || parcelListModel.length === 0) "
                  class="col-12" style="border-radius: 6px;margin-bottom: 0.2cm;">
                  <div class="row justify-content-center" style="text-align: center;">
                    <a
                      style="color: #00b28bb9;font-size: 20px;text-align: center;font-family:kanit;">ไม่พบข้อมูลพัสดุ...</a>
                  </div>
                </mat-card>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loop parcel list  Histoy -->

    <!-- They are doing(delete) -->
    <div *ngIf="_workingDelete === 1 ">
      <div class="col-12">
        <div style="background-color: #f6f6f778;border-radius: 9px; padding-top: 0.5cm;padding-bottom: 0.2cm;">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <mat-card *ngFor="let item of _itemJsonForDelete" class="col-12"
                  style="border-radius: 6px;margin-bottom: 0.2cm;">
                  <div class="row">
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;margin-bottom: 0.25cm;">
                      <mat-chip-list #chipList>
                        <mat-chip class="assigned-chip"
                          style="letter-spacing: 0.03cm;padding: 0.45cm;;font-size: 10px;color:white;">
                          {{item.unitNumber}}
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                    <div class="col-2" *ngIf="_workingDelete === 0 " (click)="setRetutnedParcel(item.id)">
                      <span class="material-icons" style="width: 12px;">
                        delete_forever
                      </span>
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      Running No : {{item.parcelno}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      QR Code : {{item.qrcode}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      Msg : {{item.message}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-10" style="font-size: 13.5px;font-family: kanit;">
                      Trans & Package : {{item.expresscompany}} : {{item.package}}
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                    <div class="col-12" style="font-size: 12px;font-family: kanit;color: #192d4b57;margin-top: 0.50cm;">
                      <button mat-raised-button class="btn  btn-block" (click)="deleteParcel(item)"
                        style="padding: 0.05cm;letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #192d4b;color: white;">
                        ลบข้อมูล
                      </button>
                    </div>
                    <div class="col-12"
                      style="font-size: 12px;font-family: kanit;color: #192d4b57;margin-top: 0.13cm;margin-bottom: -0.3cm;">
                      <button mat-raised-button class="btn  btn-block" (click)="cancelDeleteParcel()"
                        style="padding: 0.05cm;letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                        ยกเลิก
                      </button>
                    </div>
                    <div class="col-2">
                      &nbsp;
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- They are doing(delete) -->

    <div *ngIf="_workingDelete === 2 ">
      <div class="col-12">
        <div
          style="text-align: center;background-color: white;border-radius: 9px; padding-top: 0.5cm;padding-bottom: 0.2cm;">
          <div class="col-12">
            <div class="row justify-content-center" style="margin-top: 0.15cm;margin-bottom: 0.45cm;">
              <mat-chip-list #chipDone>
                <mat-chip class="assigned-chipDone">
                  <span class="material-icons" style="color: white;font-size: 15px;"> done </span>
                </mat-chip>
              </mat-chip-list>
            </div>
            <button mat-raised-button class="btn  btn-block"
              style="border-radius: 14.5px;padding: 0.05cm;letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #192d4b;color: white;">
              #ลบข้อมูลเรียบร้อย กรุณารอซักครู่ ...
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Waiting Generate Parcel No. From Server -->
  <div *ngIf="_startFetchParcelGernerateNO === '1' && _resultMatchingUniqId === '1' ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">
        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>
            <div class="row">
              <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;">

                <div class="row justify-content-center" style="margin-top: 0.3cm;" *ngIf="progressMode === false">
                  <div *ngIf="scanResult === 'Waiting' && progressMode === false">
                    <div class="container-fluid card" style="background-color: white;border-color: white;">
                      <a style="text-align: center;font-family: kanit;font-size: 18px;padding: 0.2cm;">
                        กรุณารอซักครู่ !!!</a>
                    </div>
                    <div class="container-fluid card" style="background-color: white;border-color: white;">
                      <a
                        style="color: #00b28b;;font-family: kanit;font-size: 18px;padding: 0.1cm;margin-bottom: 0.2cm;">
                        ระบบกำลัง Generate Parcel No.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Waiting Generate Parcel No. From Server -->


  <!-- Scan -->
  <div *ngIf="_state === '1' && _startFetchParcelGernerateNO === '0' &&  _resultMatchingUniqId === '1' ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">
        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>
            <div class="row">
              <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;">

                <div class="row" style="margin-top: 0.3cm;" *ngIf="progressMode === false">
                  <div *ngIf="scanResult === 'Waiting' && progressMode === false">
                    <div class="container-fluid card" style="background-color: white;border-color: white;">

                      <a style="font-family: kanit;font-size: 19px;">
                        <span class="material-icons" style="font-size: 14px;color: #adb6c3;">
                          qr_code_scanner
                        </span>
                        แสกนหมายเลขติดตามพัสดุ</a>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 0.3cm;" *ngIf="scanResult === 'Waiting' && progressMode === false">
                  <div class="container-fluid card" style="background-color: white;border-color: white;">
                    <zxing-scanner (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"></zxing-scanner>
                  </div>
                  <!-- position:relative; -->
                  <!-- <a style="color: red;position:absolute; top:160px; right:-10px">xxxxx</a> -->
                </div>


                <!-- <div class="row" style="margin-top: 0.1cm;margin-bottom: 0.3cm;"
                    *ngIf="scanResult === 'Waiting' && progressMode === false">
                    <div class="container-fluid card" style="background-color: white;border-color: white;">
                      <div   class="col-12" style=" margin-top: 0.07cm;text-align: center;margin-bottom: 0.1cm;">
                        <button mat-raised-button class="btn  btn-block"  (click)="cancelScan()"
                        style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                        ยกเลิก
                      </button>
                      </div>
                    </div>
                  </div> -->

                <!-- AkeDev -->
                <hr>
                <form #aqf="ngForm">
                  <div class="row" *ngIf="scanResult === 'Waiting' &&  progressMode === false"
                    style="margin-top: 0.10cm;margin-bottom: 0.4cm;">
                    <div class="col-12">
                      <div class="input-group mr-sm-12">
                        <div class="input-group-prepend" style="height: 31px;">
                          <div class="input-group-text">
                            <span class="material-icons">
                              qr_code
                            </span>
                          </div>
                        </div>
                        <input autocomplete="false" ngModel #keyQrResult id="keyQrResult" name="keyQrResult" type="text"
                          style="font-family: kanit;color: #001b30;" class="form-control form-control-sm"
                          placeholder="กรอกหมายเลขติดตามพัสดุ" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="scanResult === 'Waiting' &&  progressMode === false"
                    style="margin-top: 0.050cm;margin-bottom: 0.4cm;">
                    <button mat-raised-button class="btn  btn-block" (click)="GetQrCode()"
                      style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                      ยืนยันหมายเลขติดตามพัสดุ
                    </button>
                  </div>
                </form>

                <div *ngIf="scanResult === 'Waiting' &&  progressMode === false"
                  style="margin-top: -0.30cm;margin-bottom: 0.4cm;">
                  <button mat-raised-button class="btn  btn-block" (click)="cancelScan()"
                    style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                    ยกเลิก
                  </button>
                </div>



                <div class="row" *ngIf="scanResult !== 'Waiting' && progressMode === true ">
                  <div class="container-fluid card"
                    style="margin-bottom: 1cm;margin-top: 1cm;background-color: white;border-color: white;">
                    <mat-spinner style="margin:0 auto;" [diameter]="30"></mat-spinner>
                    <a style="font-family: kanit;text-align: center; padding-top:0.3cm;letter-spacing: 00.030cm;">กรุณารอสักครู่
                      ...</a>
                  </div>
                </div>

                <div class="row" style="margin: 0.1cm;margin-bottom: 0.2cm;"
                  *ngIf="scanResult !== 'Waiting' && progressMode === false ">
                  <div class="col-12 container-fluid ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      #เพิ่มพัสดุ : {{_uniq}}
                    </a>
                    <!-- <strong *ngIf="randomIndex !== 0  && _uniq !== ' ' "
                    style="font-size: 20px;font-family: Kanit;text-align: center;margin-top: 0.10cm;">{{_uniq}}</strong> -->
                  </div>
                </div>

                <div class="row" style="margin: 0.1cm;margin-bottom: 0.2cm;"
                  *ngIf="scanResult !== 'Waiting' && progressMode === false ">
                  <div class="col-12 container-fluid dropzone2 ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      - หมายเลขติดตามพัสดุ -
                    </a>
                    <strong *ngIf="randomIndex !== 0 "
                      style="font-size: 20px;font-family: Kanit;text-align: center;margin-top: 0.35cm;">{{scanResult}}</strong>
                  </div>
                </div>

                <div *ngIf="scanResult !== 'Waiting' &&  progressMode === false &&  randomIndex !== 0"
                  style="margin-top: 0.10cm;margin-bottom: 0.4cm;">
                  <button mat-raised-button class="btn  btn-block" (click)="onSelectTransportCompany()"
                    style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">ต่อไป
                    / Next
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
  </div>
  <!-- Scan -->

  <!-- TranSport -->
  <div *ngIf="_state === '2' &&  _startFetchParcelGernerateNO === '0' &&  _resultMatchingUniqId === '1' ">

    <div style="margin-top: 0.1cm" class=" container-fluid">
      <div class=" justify-content-center"
        style=" background-color: white;border-radius: 9px; padding-top: 0.1cm;padding-bottom: 0.2cm;">
        <div style="background-color: white;border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
          <div class="row">
            <div *ngFor="let tran of transportList" class="col-6" style="padding: 0.3cm">
              <a hidden id="tranname" name="tranname" #tranname value="{{tran.name}}"></a>

              <mat-card *ngIf="tranname.value !== 'Other' " class="shadow-md" (click)="selectedTransport(tran)">
                <div class="row  justify-content-center">
                  <img class="landscape" src="{{tran.img}}" width="50px" height="50px">
                </div>
              </mat-card>

              <mat-card *ngIf="tranname.value === 'Other' " class="shadow-md" (click)="selectedTransport(tran)">
                <div class="row  justify-content-center">
                  <a style="font-family: kanit;font-size: 22px;color: red;">อื่นๆ</a>
                </div>
              </mat-card>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- TranSport -->

  <!-- Camera Capture -->
  <div *ngIf="_state === '2plus' && _startFetchParcelGernerateNO === '0' &&  _resultMatchingUniqId === '1' ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">
        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>

            <!-- declare Header -->
            <div class="row">
              <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;">
                <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;"
                  *ngIf="scanResult !== 'Waiting' && progressMode === false ">
                  <div class="col-12 container-fluid ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      #ถ่ายรูปพัสดุ : {{_uniq}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- declare Header -->

            <div class=" justify-content-center"
              style="border-radius: 9px; padding-top: 0.1cm;padding-bottom: 0.2cm;padding-left: 0.15cm;padding-right: 0.15cm;">

              <!-- Btn OpenCamera -->
              <div style="border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
                <div class="row">
                  <mat-card class="shadow-md">
                    <div class="row  justify-content-center"
                      style="font-family: kanit;padding-left: 0.4cm;padding-right: 0.4cm;">

                      <input mat-raised-button class="btn btn-block" *ngIf="_imageFromCamera === '-' "
                        style="margin-top: 0.25cm;padding: 0.13cm;letter-spacing: 0.05em;font-size: 18px;font-family: Kanit;background-color: #001b30;color: white;"
                        type="button" id="loadFileXml" value=" ถ่ายรูปพัสดุ "
                        onclick="document.getElementById('camera').click();" />

                      <input mat-raised-button class="btn btn-block" *ngIf="_imageFromCamera !== '-' "
                        style="padding: 0.13cm;letter-spacing: 0.05em;font-size: 18px;font-family: Kanit;background-color: #001b30;color: white;"
                        type="button" id="loadFileXml" value=" #ถ่ายรูปพัสดุใหม่ "
                        onclick="document.getElementById('camera').click();" />

                      <button mat-raised-button class="btn  btn-block" (click)="onConfirmCamera('0')"
                        *ngIf="_imageFromCamera === '-' "
                        style="letter-spacing: 0.05em;font-size: 16.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                        &nbsp;&nbsp;ถัดไป&nbsp;
                      </button>
                      <input type="file" accept="image/jpg" capture="camera" id="camera" style="display:none;"
                        (change)="openCamera($event)">

                      <div style="padding-top: 0.3cm;">
                        <canvas id="canvas"></canvas>
                      </div>
                      <div style="margin-top: 0.05cm;">&nbsp;</div>
                      <!-- confirm Button -->
                      <button mat-raised-button class="btn btn-block" *ngIf="_imageFromCamera !== '-' "
                        (click)="onConfirmCamera('1')"
                        style="padding: 0.13cm;letter-spacing: 0.05em;font-size: 17px;font-family: Kanit;background-color: #001b30;color: white;">
                        ยืนยัน ?
                      </button>
                      <button mat-raised-button class="btn btn-block" *ngIf="_imageFromCamera !== '-' "
                        (click)="onConfirmCamera('0')"
                        style="padding: 0.13cm;letter-spacing: 0.05em;font-size: 17px;font-family: Kanit;background-color: #00b28b;color: white;">
                        ยกเลิก ..
                      </button>
                      <!-- confirm Button -->

                    </div>
                  </mat-card>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Camera Capture -->

  <!-- ParcelPackage -->
  <div *ngIf="_state === '3' && _startFetchParcelGernerateNO === '0' &&  _resultMatchingUniqId === '1' ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">
        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>
            <div class="row">
              <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;">

                <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;"
                  *ngIf="scanResult !== 'Waiting' && progressMode === false ">
                  <div class="col-12 container-fluid ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      #ประเภทพัสดุ : {{_uniq}}
                    </a>
                  </div>
                  <div class="col-12 container-fluid "
                    *ngIf="_imageFromCamera !== '-' && _confirmImageFromCamera === '1' ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      แนบไฟล์ :
                      <span class="material-icons" style="padding-top: 0.25cm;color: #00b28b;font-size: 27px;">
                        check_circle
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class=" justify-content-center" style="border-radius: 9px; padding-top: 0.1cm;padding-bottom: 0.2cm;">
              <div style="border-radius: 9px; padding-top: 0.2cm;padding-bottom: 0.2cm;">
                <div class="row">
                  <div *ngFor="let item of parcelTyleList" class="col-4" style="padding: 0.3cm">
                    <mat-card (click)="getParcelTyle(item)" class="shadow-md">
                      <div class="row  justify-content-center" style="font-family: kanit;">
                        <span class="material-icons">
                          {{item.img}}
                        </span>&nbsp;{{item.sym}}
                      </div>
                      <a hidden id="tmpig" name="tmpig" #tmpig value="{{item.img}}"></a>
                      <div *ngIf="tmpig.value !== 'block' " class="row  justify-content-center"
                        style="font-family: kanit;font-size: 14px;">
                        {{item.name}}
                      </div>
                      <div *ngIf="tmpig.value === 'block' " class="row  justify-content-center"
                        style="color: red;font-family: kanit;font-size: 14px;">
                        {{item.name}}
                      </div>
                    </mat-card>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- ParcelPackage -->


  <!-- Form Search House + Room -->
  <div *ngIf="_state === '4' && _uniq !== '' &&  _resultMatchingUniqId === '1'  ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">

        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>

            <div class="row">

              <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;">
                <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;"
                  *ngIf="scanResult !== 'Waiting' && _uniq !== '' ">
                  <div class="col-12 container-fluid ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      #ประเภทพัสดุ : {{_uniq}}
                    </a>
                  </div>
                  <div class="col-12 container-fluid "
                    *ngIf="_imageFromCamera !== '-' && _confirmImageFromCamera === '1' ">
                    <a
                      style="text-align: center;margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      แนบไฟล์ :
                      <span class="material-icons" style="padding-top: 0.25cm;color: #00b28b;font-size: 27px;">
                        check_circle
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div class="card container-fluid"
                style="border-color: #e9ecef;background-color: #e9ecef;margin:0.125cm;border-radius: 9px;margin-top: 0.2cm;">
                <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.1cm;"
                  *ngIf="scanResult !== 'Waiting' && _uniq !== '' && _fetchCustomerStatus !== 'open'  ">
                  <div class="col-12 container-fluid ">
                    <a style="margin-top: 0.3cm;font-size: 16.5px;font-family: Kanit;text-align: center;">
                      - ค้นหาบ้านเลขที่ -
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="scanResult !== 'Waiting' && _uniq !== '' && _fetchCustomerStatus === 'open' "
                class="card container-fluid" style="margin:0.125cm;border-radius: 9px;margin-top: -0.3cm;">
                <div class="row" style="margin: 0.1cm;margin-bottom: 0.1cm;"
                  *ngIf="scanResult !== 'Waiting' && _uniq !== '' ">
                  <div class="col-12 container-fluid ">
                    <mat-form-field class="example-full-width"
                      style="font-family: Kanit;font-size: 17px; width: 100%;text-align: left;margin-top: 0.2cm;">
                      <mat-label
                        style=" color: rgb(153, 153, 153);font-size: 12px;padding-left: 0.25cm;color: rgba(255, 0, 0, 0.656);">
                        #เพิ่มสถานที่จัดเก็บ Optional
                      </mat-label>
                      <input (input)="onChangeLocation($event.target.value)" ngModel name="locationstr" id="locationstr"
                        matInput>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- Btn Cancel Search -->
              <div class="card container-fluid"
                style="border-color: #e9ecef;background-color: #e9ecef;margin:0.125cm;border-radius: 9px;margin-top: 0.25cm;">
                <div class="row" style="text-align:right;margin: 0.1cm;margin-bottom: 0.1cm;"
                  *ngIf="scanResult !== 'Waiting' && _uniq !== '' && _fetchCustomerStatus === 'open'  ">
                  <div class="col-12">
                    <button mat-raised-button class="btn" (click)="resetSearch()"
                      style="letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                      * ค้นหาบ้านเลขที่ใหม่
                    </button>
                  </div>
                </div>
              </div>
              <!-- Btn Cancel Search -->

              <!-- Form Find IProp -->
              <form style="margin-left: 0.1cm;margin-right:0.50cm; "
                *ngIf="checkRoomNumber === '1' && _fetchCustomerStatus !== 'open' " #prf="ngForm">
                <div class="card container-fluid"
                  style="border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">
                  <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;"
                    *ngIf="scanResult !== 'Waiting' && _uniq !== ''  ">
                    <div class="col-12 container-fluid " style="margin-top: 0.25cm;">
                      <a style="font-size: 19px;font-family: Kanit;text-align: center;">
                        <div class="row">

                          <div class="col-6" style="color:#010101;font-size: 18px;text-align: center;padding: 0.5cm;">
                            <!-- CheckLogic One Site -->
                            <select *ngIf="_oneSite !== '-' " class="form-control form-control-sm"
                              (change)='onSelectPrefixnumber(prefixnumber.value)' name="prefixnumber" #prefixnumber
                              id="prefixnumber"
                              style="font-size: 14.5px;height: 35px;font-family: Kanit;margin-top: -0.10cm;   ">
                              <option *ngFor="let items of _anandaMultiSite" [selected]="items.prefixhouse === _oneSite"
                                style="font-family: kanit;border-color: white;" [value]="items.prefixhouse">
                                {{items.prefixhouse}}
                              </option>
                            </select>

                            <!-- CheckLogic Multi Site -->

                            <select *ngIf="_oneSite === '-' " class="form-control form-control-sm"
                              (change)='onSelectPrefixnumber(prefixnumber.value)' name="prefixnumber" #prefixnumber
                              id="prefixnumber"
                              style="font-size: 14.5px;height: 35px;font-family: Kanit;margin-top: -0.10cm;   ">
                              <option *ngFor="let items of _anandaMultiSite"
                                style="font-family: kanit;border-color: white;" [value]="items.prefixhouse">
                                {{items.prefixhouse}}
                              </option>
                            </select>

                          </div>


                          <div class="col-1"
                            style="font-size: 25px;;text-align: center;padding-top: 0.5cm;margin-right: 0.5cm;">
                            <a>/</a>
                          </div>
                          <div class="col-4" style="font-size: 18px;;text-align: center;padding: 0.1cm;">
                            <mat-form-field class="example-full-width"
                              style="font-family: Kanit;font-size: 15px; width: 100%;text-align: center;color: rgb(56, 56, 56); ">
                              <mat-label style="color: rgb(153, 153, 153);">เลขที่ห้อง</mat-label>
                              <input ngModel name="rnumber" id="rnumber" matInput (keypress)="numberOnly($event)"
                                #roomnumber maxlength="5">
                              <!-- <mat-hint align="end">800</mat-hint> -->
                            </mat-form-field>
                          </div>

                        </div>
                        <div class="row" style="margin-bottom: 0.16cm;">
                          <div class="col-12">
                            <button *ngIf="_prefixAddressRoom === 'บ้านเลขที่' " disabled mat-raised-button
                              class="btn  btn-block" (click)="onFindCustomer()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                              <span class="material-icons" style="width: 12px;">
                                search
                              </span>
                              &nbsp;&nbsp;ค้นหา&nbsp;
                            </button>
                            <button *ngIf="_prefixAddressRoom !== 'บ้านเลขที่' " mat-raised-button
                              class="btn  btn-block" (click)="onFindCustomer()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                              <span class="material-icons" style="width: 12px;">
                                search
                              </span>
                              &nbsp;&nbsp;ค้นหา&nbsp;
                            </button>
                          </div>
                        </div>

                        <div class="row" style="margin-bottom: 0.45cm;">
                          <div class="col-12">
                            <button mat-raised-button class="btn  btn-block" (click)="cancelScan()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                              &nbsp;&nbsp;ยกเลิก&nbsp;
                            </button>
                          </div>
                        </div>

                      </a>
                    </div>
                  </div>
                </div>
              </form>
              <!-- Form Find IProp -->

              <div *ngIf="checkRoomNumber === '0'  && _vmMobileNull === 0 " class="card container-fluid"
                style="margin:0.125cm;border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">

                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <span class="material-icons customIconSize" style="margin-top: 1cm;color: red;">
                    close
                  </span>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a style="margin-top: 0.3cm;margin-bottom: 0.5cm;font-size: 25px;">
                    {{_countError}}
                  </a>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a style="margin-top: 0.3cm;margin-bottom: 0.5cm;">กรุณา กรอกข้อมูลบ้านเลขที่ให้ถูกต้อง !!!</a>
                </div>
              </div>

              <div *ngIf="checkRoomNumber === '0' && _vmMobileNull === 1 " class="card container-fluid"
                style="margin:0.125cm;border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">

                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <span class="material-icons customIconSize" style="margin-top: 1cm;color: red;">
                    close
                  </span>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a style="margin-top: 0.3cm;margin-bottom: 0.5cm;font-size: 25px;">
                    {{_countError}}
                  </a>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a style="margin-top: 0.3cm;margin-bottom: 0.5cm;">ไม่พบข้อมูลลูกค้า !!!</a>
                </div>
              </div>

              <!-- NEW REQUIREMENT MAP home.ts -->

              <div *ngIf="checkRoomNumber === '9' && _vmMobileNull === 9 " class="card container-fluid"
                style="margin:0.125cm;border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">


                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a> </a>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a> </a>
                </div>
                <div class="row"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.1cm;font-family: kanit;color: red;font-size: 18px;">
                  ** ไม่พบห้องลูกค้า {{rnumber}} ในระบบ **
                </div>
                <div class="row "
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;color: red;font-size: 13px;">
                  ** ไม่ได้ติดตั้ง COCORO APP **
                </div>
                <hr>
                <div class="row"
                  style="text-align: left;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;font-size: 16.5px;">
                  &nbsp;&nbsp;หมายเลขพัสดุ : <a
                    style="font-family: kanit;color: #00b28b;font-size: 16.5px;">&nbsp;{{_uniq}}</a>
                </div>
                <div class="row"
                  style="text-align: left;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;font-size: 16.5px;">
                  &nbsp;&nbsp;หมายเลขติดตามพัสดุ : <a
                    style="font-family: kanit;color: #00b28b;font-size: 16.5px;">&nbsp;{{scanResult}}</a>
                </div>
                <div class="row"
                  style="text-align: left;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;font-size: 16.5px;">
                  &nbsp;&nbsp;บริการขนส่ง : <a
                    style="font-family: kanit;color: #00b28b;font-size: 16.5px;">&nbsp;{{_transport}}</a>
                </div>
                <div class="row"
                  style="text-align: left;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;font-size: 16.5px;">
                  &nbsp;&nbsp;ลักษณะพัสดุ : <a
                    style="font-family: kanit;color: #00b28b;font-size: 16.5px;">&nbsp;{{_box}}</a>
                </div>

                <div class="row justify-content-center" style="margin-left: 0.3cm;margin-right: 0.3cm;">
                  <mat-form-field class="example-full-width"
                    style="font-family: Kanit;font-size: 17px; width: 100%;text-align: left;margin-top: 0.3cm;">
                    <mat-label style=" color: rgb(153, 153, 153);font-size: 15px;padding-left: 0.25cm;">**
                      เพิ่มชื่อเจ้าของพัสดุ **
                    </mat-label>
                    <input (input)="onChangeNameExtra($event.target.value)" ngModel name="addCustomerNameExtra"
                      id="addCustomerNameExtra" matInput>
                  </mat-form-field>
                  <mat-form-field class="example-full-width"
                    style="font-family: Kanit;font-size: 17px; width: 100%;text-align: left;margin-top: 0.2cm;margin-top: -0.4cm;">
                    <mat-label style=" color: rgb(153, 153, 153);font-size: 15px;padding-left: 0.25cm;">**
                      เพิ่มสถานที่จัดเก็บ Optional **
                    </mat-label>
                    <input (input)="onChangeLocation($event.target.value)" ngModel name="locationstr" id="locationstr"
                      matInput>
                  </mat-form-field>

                </div>


                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <button mat-raised-button class="btn  btn-block"
                    *ngIf="_addExtraName === '' || _startConfirm === '1' " disabled
                    style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                    &nbsp;&nbsp;ยืนยัน&nbsp;
                  </button>

                  <button mat-raised-button class="btn  btn-block" (click)="onConfirmVMNotFound()"
                    *ngIf="_addExtraName !== '' && _startConfirm !== '1' "
                    style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #192d4b;color: white;">
                    &nbsp;&nbsp;ยืนยัน&nbsp;
                  </button>

                </div>


                <div class="row justify-content-center"
                  style="text-align: center;margin-top: 0.5cm;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <button mat-raised-button class="btn  btn-block" (click)="onClearVMNotFound()"
                    *ngIf="_startConfirm !== '1' "
                    style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                    &nbsp;&nbsp;ยกเลิก&nbsp;
                  </button>
                  <button mat-raised-button class="btn  btn-block" (click)="onClearVMNotFound()"
                    *ngIf="_startConfirm === '1' " disabled
                    style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                    &nbsp;&nbsp;ยกเลิก&nbsp;
                  </button>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a> </a>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a> </a>
                </div>
              </div>
              <!-- NEW REQUIREMENT MAP home.ts -->


              <div
                *ngIf="_fetchCustomerStatus === 'open' && ( _ipropMobileUser.length === 0 || _ipropMobileUser  === null) "
                class="card container-fluid"
                style="margin:0.125cm;border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">
                <div class="col-12" style="color:#010101;font-size: 18px;text-align: center;padding: 0.1cm;">
                  <a style="font-family: kanit;color: #00b28b;font-size: 17.5px;">กรุณารอซักครู่...</a>
                </div>
              </div>

              <!-- Form Display Iprop -->
              <div *ngIf="_fetchCustomerStatus === 'open' && _ipropMobileUser.length > 0 && _projectCode !== '' "
                class="card container-fluid"
                style="margin:0.125cm;border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">
                <div class="col-12" style="color:#010101;font-size: 18px;text-align: center;padding: 0.1cm;">

                  <form #addn="ngForm">
                    <mat-form-field class="example-full-width"
                      style="font-family: Kanit;font-size: 17px; width: 100%;text-align: left;margin-top: 0.3cm;">
                      <mat-label style=" color: rgb(153, 153, 153);font-size: 15px;padding-left: 0.25cm;">**
                        เพิ่มชื่อเจ้าของพัสดุ **
                      </mat-label>
                      <input (input)="onChangeName($event.target.value)" ngModel name="addCustomerName"
                        id="addCustomerName" matInput>
                    </mat-form-field>

                    <button *ngIf="_addCustomerName === '' " disabled mat-raised-button class="btn  btn-block"
                      style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                      <span class="material-icons" style="width: 15.5px;">
                        add
                      </span>
                      &nbsp;&nbsp;เพิ่ม&nbsp;
                    </button>

                    <button *ngIf="_addCustomerName !== '' " mat-raised-button class="btn  btn-block"
                      (click)="btnAddName()"
                      style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                      <span class="material-icons" style="width: 15.5px;">
                        add
                      </span>
                      &nbsp;&nbsp;เพิ่ม&nbsp;
                    </button>
                  </form>

                  <!-- Total Recode From Database -->
                  <div class="row col-12" style="margin-top:1cm;color:#010101;font-size: 18px; padding: 0.1cm;">
                    <a style="margin-top: 0.1cm;font-size: 14px;font-family: Kanit;margin-left: 0.3cm;">
                      <mat-chip-list #chipList>
                        &nbsp;*** พบ <mat-chip class="assigned-chip" style="font-size: 18px;color:white;">
                          {{_ipropMobileUser.length}}
                        </mat-chip> รายการ ( {{rnumber}} )
                      </mat-chip-list>
                    </a>
                  </div>
                  <!-- Total Recode From Database -->

                  <!-- Loop Fron Database to Select Iprop VmMobileUser -->
                  <div *ngIf="_fetchCustomerStatus === 'open' && _fetchCustomerDetail !== null "
                    class="row justify-content-center "
                    style="background-color: #e9ecef;border-radius: 9px;margin-bottom: 0.3cm;">
                    <div style="padding: 0.16cm;padding-top: 0.3cm;">
                      <div *ngFor="let item of _ipropMobileUser" class="card" class="col-12">
                        <div class="row">
                          <div class="col-9"
                            style="padding: 0.2cm;font-family: kanit;text-align: left;margin-bottom: 0.2cm;font-size: 15px;background-color: white;border-radius: 5px;">
                            <a style="margin-left: 0.5cm">คุณ : {{item.customername}}</a>
                            <div class="row" style="margin-left: 0.52cm;font-family: kanit;">
                              <a>เบอร์ : </a>
                              <a style="color: #00b28b;">&nbsp;{{item.phoneno}}</a>

                            </div>
                          </div>
                          <div class="col-3">
                            <button mat-raised-button class="btn  btn-block" (click)="selectCustomer(item)"
                              style="letter-spacing: 0.05em;font-size: 13.5px;font-family: Kanit;background-color: #001b30;color: white;">
                              เลือก
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Loop Fron Database to Select Iprop VmMobileUser -->

                </div>
              </div>
              <!-- Form Display Iprop -->

              <!-- <div *ngIf="_fetchCustomerStatus === 'open' " class="card container-fluid" >
                </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Form Search House + Room -->

  <!-- Save DB -->
  <div *ngIf="_state === '5' && _uniq !== ''  &&  _resultMatchingUniqId === '1'  ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
      <div class="row">
        <div class="container-fluid card"
          style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 0.4cm;">

          <div class="card" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.5cm;">
            <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;"
              *ngIf="scanResult !== 'Waiting' && _uniq !== '' ">

              <div class="col-12" style="margin-top: 0.7cm;">
                <a style="margin-top: 0.3cm;font-size: 20px;font-family: kanit;text-align: center;">
                  #รายละเอียดพัสดุ
                </a>
              </div>

              <div class="col-6" style=" margin-top: 0.55cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  บ้านเลขที่
                </a>
              </div>
              <div class="col-6" style="margin-top: 0.55cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  {{rnumber}}
                </a>
              </div>

              <div class="col-6" style=" margin-top: 0.35cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  หมายเลขพัสดุ
                </a>
              </div>
              <div class="col-6" style="margin-top: 0.35cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  {{_uniq}}
                </a>
              </div>

              <div class="col-6" style=" margin-top: 0.35cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  หมายเลขติดตามพัสดุ
                </a>
              </div>
              <div class="col-6" style="margin-top: 0.35cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  {{scanResult}}
                </a>
              </div>

              <div class="col-6" style=" margin-top: 0.35cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  ชื่อลูกค้า
                  <a *ngIf="typeName === 'add' " style="color: rgb(114, 114, 114);">(เพิ่ม)</a>
                  <a *ngIf="typeName === 'select' " style="color: rgb(114, 114, 114);">(เลือก)</a>
                </a>
              </div>
              <div class="col-6" style="margin-top: 0.35cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  {{_fullname}}
                </a>
              </div>

              <div class="col-6" style=" margin-top: 0.35cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  บริการขนส่ง
                </a>
              </div>
              <div class="col-6" style="margin-top: 0.35cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  {{_transport}}
                </a>
              </div>
              <div class="col-6" style=" margin-top: 0.35cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  ลักษณะพัสดุ
                </a>
              </div>
              <div class="col-6" style="margin-top: 0.35cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  {{_box}}
                </a>
              </div>

              <div *ngIf="_imageFromCamera !== '-' && _confirmImageFromCamera === '1' " class="col-6"
                style=" margin-top: 0.35cm;text-align: center;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: right;">
                  แนบไฟล์ :
                </a>
              </div>
              <div *ngIf="_imageFromCamera !== '-' && _confirmImageFromCamera === '1' " class="col-6"
                style="margin-top: 0.35cm;text-align: left;">
                <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                  <span class="material-icons" style="color: #00b28b;font-size: 30px;">
                    check_circle
                  </span>
                </a>
              </div>






              <div *ngIf="_save === 0" class="col-12"
                style=" margin-top: 0.55cm;text-align: center;margin-bottom: 0.1cm;">
                <button *ngIf="_lockButton === '0' " mat-raised-button class="btn  btn-block" (click)="saveToDB()"
                  style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                  บันทึก
                </button>
              </div>

              <!-- while Save -->

              <div *ngIf="_save === 1 " class="col-12"
                style=" margin-top: 0.55cm;text-align: center;margin-bottom: 0.1cm;">
                <button disabled mat-raised-button class="btn  btn-block"
                  style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>

                  ระบบกำลังบันทึก...
                </button>
              </div>


              <!-- while Save -->

              <div class="col-12" style=" margin-top: 0.01cm;text-align: center;margin-bottom: 0.5cm;">
                <button mat-raised-button class="btn  btn-block" (click)="cancelToDB()"
                  style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                  ยกเลิก
                </button>

              </div>




            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Save DB -->

  <!-- Already Save -->
  <div
    *ngIf="_state === '6' && _uniq !== ''  && scanResult !== '' && rnumber !== '' &&  _resultMatchingUniqId === '1' ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
      <div class="row">
        <div class="container-fluid card"
          style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 1.4cm;">

          <div class="card" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.55cm;">
            <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;"
              *ngIf="scanResult !== 'Waiting' && _uniq !== '' ">
              <div class="col-12" style="margin-top: 0.7cm;">

                <div class="row justify-content-center">
                  <div class="col-5" style="border-color: #192d4b;border-radius: 6px;font-family:kanit;">
                    <div class="row justify-content-center">
                      <div class="col-12 row justify-content-center">
                        <mat-chip-list #chipDone>
                          <mat-chip class="assigned-chipDone">
                            <span class="material-icons" style="color: white"> done </span>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                      <div class="row justify-content-center"
                        style="font-size: 19px;padding-top: 0.4cm;letter-spacing: 0.03cm;">
                        <div class="col-12">
                          <a style="font-family: kanit;">บันทึกเรียบร้อย</a>
                        </div>
                        <!-- <div class="col-12">
                            <a style="font-family: kanit;">{{_countError}}</a>
                          </div> -->

                      </div>
                      <div class="row justify-content-center"
                        style="font-size: 19px;padding-top: 0.15cm;letter-spacing: 0.03cm;">
                        <div class="col-12">
                          <a style="font-family: kanit;">&nbsp;</a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Already Save -->

  <!-- Parcel Out Key Scan Out -->
  <div *ngIf="_state === 'A' && _vmMobileNullOut ===  0  &&  _resultMatchingUniqId === '1' ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 3cm;;">
      <div class="row">
        <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
          <div>
            <div class="row">

              <!-- address Text -->
              <div class="card container-fluid"
                style="border-color: #e9ecef;background-color: #e9ecef;margin:0.125cm;border-radius: 9px;margin-top: 0.2cm;">
                <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.1cm;">
                  <div class="col-12 container-fluid ">
                    <a style="margin-top: 0.3cm;font-size: 19px;font-family: Kanit;text-align: center;">
                      - บ้านเลขที่ -
                    </a>
                  </div>
                </div>
              </div>
              <!-- address Text -->

              <!-- Start OutF -->
              <form style="margin-left: 0.1cm;margin-right:0.50cm; "
                *ngIf=" _anandaMultiSite !== null || _anandaMultiSite.length !== 0 " #pro="ngForm">
                <div *ngIf="_countErrorOut === 0 " class="card container-fluid"
                  style="border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">
                  <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;">
                    <div class="col-12 container-fluid " style="margin-top: 0.25cm;">
                      <a style="font-size: 19px;font-family: Kanit;text-align: center;">

                        <div class="row">

                          <!-- this._ddlParcelOutLength = <any>item.length;
                              this._oneSiteParcelOut = _projectIds; -->
                          <!-- AkeDev -->

                          <div *ngIf="_ddlParcelOutLength !== 1 &&  _oneSiteParcelOut === '-'  " class="col-6"
                            style="color:#010101;font-size: 18px;text-align: center;padding-top: 0.6cm;">
                            <select class="form-control form-control-sm"
                              (change)='onSelectPrefixnumberOut(prefixnumberOut.value)' name="prefixnumberOut"
                              #prefixnumberOut id="prefixnumberOut"
                              style="font-size: 14.5px;height: 35px;font-family: Kanit;margin-top: -0.10cm;   ">
                              <option *ngFor="let items of _anandaMultiSite"
                                style="font-family: kanit;border-color: white;" [value]="items.prefixhouse">
                                {{items.prefixhouse}}
                              </option>
                            </select>
                          </div>


                          <div *ngIf="_ddlParcelOutLength === 1 &&  _oneSiteParcelOut !== '-' " class="col-6"
                            style="color:#010101;font-size: 18px;text-align: center;padding-top: 0.6cm;">
                            <select class="form-control form-control-sm"
                              (change)='onSelectPrefixnumberOut(prefixnumberOut.value)' name="prefixnumberOut"
                              #prefixnumberOut id="prefixnumberOut"
                              style="font-size: 14.5px;height: 35px;font-family: Kanit;margin-top: -0.10cm;   ">
                              <option *ngFor="let items of _anandaMultiSite"
                                style="font-family: kanit;border-color: white;"
                                [selected]="items.prefixhouse === _oneSiteParcelOut" [value]="items.prefixhouse">
                                {{items.prefixhouse}}
                              </option>
                            </select>
                          </div>


                          <div class="col-1"
                            style="margin-left: -0.3cm;font-size: 25px;;text-align: center;padding-top: 0.5cm;margin-right: 0.2cm;">
                            <a>/</a>
                          </div>

                          <div class="col-4"
                            style="font-size: 18px;;text-align: center;padding: 0.1cm;padding-top: 0.2cm;">
                            <mat-form-field class="example-full-width"
                              style="font-family: Kanit;font-size: 15px; width: 100%;text-align: center;color: rgb(56, 56, 56); ">
                              <mat-label style="color: rgb(153, 153, 153);">เลขที่ห้อง</mat-label>
                              <input ngModel name="prefixoutroom" id="prefixoutroom" matInput
                                (keypress)="numberOnly($event)" #prefixoutroom maxlength="7">
                              <!-- <mat-hint align="end">800</mat-hint> -->
                            </mat-form-field>
                          </div>

                        </div>

                        <!-- <div class="col-12" style="text-align: right;margin-top: 0.15cm;color:rgb(84, 82, 82);">
                            <div class="row">
                              <div class="col-12">
                                <a style="font-size: 15.5px;color:#00b28b;">#คลิ๊กเลือกวันที่</a>
                              </div>
                            </div>
                          </div> -->


                        <!-- Add Filter Date -->
                        <!-- <div class="row justify-content-center"  >
                            <mat-form-field appearance="outline" style="width: 265px;height: 80px;"    >
                              <mat-label style="font-family: kanit;font-size: 13px;color: rgb(224, 24, 24);">
                                {{_dSTimeSelectedOut}}</mat-label>
                              <input matInput [matDatepicker]="pickerC" [(ngModel)]="_dSTimeSelectedOut" #StartDateSOut
                                 readonly
                                (dateChange)="OnStartDateChangeOut(StartDateSOut.value)" name="StartDateSOut"
                                style="font-family: kanit;font-size: 13px;" />
                              <mat-datepicker-toggle matSuffix [for]="pickerC">
                              </mat-datepicker-toggle>
                              <mat-datepicker style="font-family: kanit;font-size: 15px;" #pickerC disabled="false">
                              </mat-datepicker>
                            </mat-form-field>
                          </div> -->
                        <!-- <div class="row justify-content-center" style="margin-top: -0.05cm;">
                            <mat-form-field appearance="outline" style="width: 265px;;height: 80px;"  >
                              <mat-label style="font-family: kanit;font-size: 13px;color: rgb(224, 24, 24);">
                                {{_dETimeSelectedOut}}</mat-label>
                              <input matInput [matDatepicker]="pickerD" [(ngModel)]="_dETimeSelectedOut" #StartDateEOut
                                 readonly
                                (dateChange)="OnEndDateChangeOut(StartDateEOut.value)" name="StartDateEOut"
                                style="font-family: kanit;font-size: 13px;" />
                              <mat-datepicker-toggle matSuffix [for]="pickerD">
                              </mat-datepicker-toggle>
                              <mat-datepicker style="height:10px;font-family: kanit;font-size: 15px" #pickerD
                                disabled="false">
                              </mat-datepicker>
                            </mat-form-field>
                          </div> -->
                        <!-- Add Filter Date -->


                        <div class="row" style="margin-bottom: 0.45cm;margin-top: 0.3cm;">
                          <div class="col-12">
                            <button *ngIf="_countErrorOut === 0 &&  _prefixAddressRoomOut !== 'บ้านเลขที่' "
                              mat-raised-button class="btn  btn-block" (click)="onFindCustomerOut()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                              &nbsp;&nbsp;ค้นหาพัสดุ&nbsp;
                              <span class="material-icons" style="width: 12px;">
                                search
                              </span>
                            </button>

                            <button *ngIf="  _prefixAddressRoomOut === 'บ้านเลขที่' " disabled mat-raised-button
                              class="btn  btn-block"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                              &nbsp;&nbsp;ค้นหาพัสดุ&nbsp;
                              <span class="material-icons" style="width: 12px;">
                                search
                              </span>
                            </button>

                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 0.15cm;margin-top: -0.45cm;">
                          <div class="col-12">
                            <button *ngIf="_countErrorOut !== 0 " disabled mat-raised-button class="btn  btn-block"
                              (click)="onFindCustomerOut()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                              <span class="material-icons" style="width: 12px;">
                                search
                              </span>
                              &nbsp;&nbsp;ค้นหาพัสดุ&nbsp;
                            </button>

                          </div>
                        </div>
                        <div class="row" *ngIf="_countErrorOut === 0 "
                          style="margin-bottom: 0.45cm;margin-top: 0.25cm;">
                          <div class="col-12">
                            <!-- <button   mat-raised-button class="btn  btn-block"  (click)="cancelScan()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                              &nbsp;&nbsp;ยกเลิก&nbsp;
                            </button> -->
                            <button mat-raised-button class="btn  btn-block" (click)="cancelScan()"
                              style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                              ยกเลิก
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </form>
              <!-- Start OutF -->

              <!-- errorOut -->
              <div *ngIf="_countErrorOut !== 0  " class="card container-fluid"
                style="margin:0.125cm;border-radius: 9px;margin-left: 0.2cm;margin-right: 0.2cm;margin-bottom: 0.3cm;">

                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <span class="material-icons customIconSize" style="margin-top: 1cm;color: red;">
                    close
                  </span>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a style="margin-top: 0.3cm;margin-bottom: 0.5cm;font-size: 25px;">
                    {{_countErrorOut}}
                  </a>
                </div>
                <div class="row justify-content-center"
                  style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;font-family: kanit;">
                  <a style="margin-top: 0.3cm;margin-bottom: 0.5cm;">กรุณา กรอกข้อมูลให้ถูกต้อง !!!</a>
                </div>
              </div>
              <!-- errorOut -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Parcel Out Key Scan Out -->

  <!-- WAITING PARCELOUT -->
  <div *ngIf="_state === 'B' && _save === 0 && _whileSearch === 1 &&  _resultMatchingUniqId === '1'  ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
      <div class="row" *ngIf="_save === 0 ">
        <div class="container-fluid card"
          style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 0.4cm;">
          <div class="row justify-content-center" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;">
            <div class="card " style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.5cm;">
              <div class="col-12   " style="margin-top: 0.7cm;">
                <div class="row">
                  <div class="col-12" style="margin: 0.3cm;text-align: center;padding-right: 1cm;">
                    <a style="font-family: kanit;font-size:14px;">กรุณารอสักครู่ !!!</a>
                  </div>
                  <div class="col-12"
                    style="margin-top: -0.2cm;margin: 0.3cm;text-align: center;padding-right: 1cm;margin-bottom: 0.4cm;">
                    <a style="font-family: kanit;font-size:19px;color: #00b28b;">
                      ระบบกำลังประมวลผล</a>
                  </div>
                  <div class="col-12" style="margin-top: 0.4cm;margin: 0.3cm;text-align: center;padding-right: 1cm;">
                    <a style="font-family: kanit;font-size:1ภpx;color: #00b28b;">&nbsp;</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- WAITING PARCELOUT -->

  <!-- NOT FOUND PARCELOUT -->
  <div *ngIf="_state === 'A' &&  _vmMobileNullOut === 1  &&  _resultMatchingUniqId === '1'  ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
      <div class="row" *ngIf="_save === 0 ">
        <div class="container-fluid card"
          style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 0.4cm;">
          <div class="row justify-content-center" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;">
            <div class="card " style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.5cm;">
              <div class="col-12   " style="margin-top: 0.7cm;">
                <div class="row">
                  <div class="col-12" style="margin: 0.3cm;text-align: center;padding-right: 1cm;">
                    <span class="material-icons customIconSize" style="margin-top: 1cm;color: red;">
                      close
                    </span>
                  </div>
                  <div class="col-12"
                    style="margin-top: -0.2cm;margin: 0.3cm;text-align: center;padding-right: 1cm;margin-bottom: 0.4cm;">
                    <a style="font-family: kanit;font-size:19px;color: red;">
                      ไม่พบข้อมูลลูกค้า </a>
                  </div>
                  <div class="col-12" style="margin-top: 0.4cm;margin: 0.3cm;text-align: center;padding-right: 1cm;">
                    <a style="font-family: kanit;font-size:1ภpx;color: #00b28b;">&nbsp;</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- NOT FOUND PARCELOUT -->

  <!-- From ParcelOut Search For Multi Row -->
  <div
    *ngIf="_state === 'B' && _save === 0  && _parcelOut.length > 0 && _whileSearch === 0  &&  _resultMatchingUniqId === '1'  ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
      <div class="row" *ngIf="_save === 0 ">
        <div class="container-fluid card"
          style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 0.4cm;">

          <div class="row justify-content-center" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;">
            <div class="card " style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.5cm;">

              <!-- row -->
              <div class="col-12   " style="margin-top: 0.7cm;">
                <div class="row">
                  <div class="col-12" style="margin: 0.3cm;text-align: right;padding-right: 1.4cm;"
                    *ngFor="let item of _parcelOut;let i = index">
                    <a style="color: white;" #loopindex name="loopindex" id="loopindex" value="{{i}}"></a>
                    <mat-chip-list #chipEdit>
                      <mat-chip class="assigned-chipEdit"
                        style="margin-left: 0.1cm;margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                        No.{{i+1}}
                      </mat-chip>
                      &nbsp;&nbsp;
                      <a style="margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                        #ข้อมูลพัสดุ
                      </a>
                      <div style="margin-top: 0.55cm;;text-align: right;padding-left: 1.5cm;">
                        <section class="example-section">
                          <mat-checkbox (change)="checkSelectParcel(item,$event,loopindex.value)"
                            style="margin-top: 0.3cm;font-family: kanit;font-size: 17px;">
                            เลือก
                          </mat-checkbox>
                        </section>
                      </div>
                    </mat-chip-list>
                    <div style="text-align: left;margin-top: 0.1cm;margin-bottom: 0.1cm;">
                      <a style="font-family: kanit;font-size: 15px;color: #00b28bba;font-weight: 500;">User :
                        {{item.user}}</a>
                    </div>


                    <hr>
                    <div *ngIf="item.installapp === '0' " class="col-12" style="margin-top: 0.50cm;text-align: center;">
                      <div class="row">
                        <div class="col-12" style="font-family: kanit;">สถานะ
                          <!-- <a *ngIf="_typeNameOut === 'add' " style="color: rgb(114, 114, 114);"  >(เพิ่ม)</a>
                                  <a *ngIf="_typeNameOut === 'select' " style="color: rgb(114, 114, 114);" >(เลือก)</a> -->
                        </div>
                      </div>
                    </div>
                    <div *ngIf="item.installapp === '0' || item.recipientphonenumber === '' " class="col-12"
                      style="margin-top: 0.1cm;text-align: center;">
                      <div class="row">

                        <div class="col-12" style="font-family: kanit;color:red;">
                          ** ไม่ได้ติดตั้ง COCORO APP **
                        </div>

                      </div>
                    </div>
                    <div class="col-12" style="margin-top: 0.40cm;text-align: center;">
                      <div class="row">

                        <div class="col-12" style="font-family: kanit;">หมายเลขพัสดุ</div>
                        <div class="col-12" style="font-family: kanit;text-align: left;color: red;">
                          &nbsp;&nbsp;&nbsp;&nbsp;{{item.parcelno}}
                        </div>

                      </div>
                    </div>


                    <div class="col-12" style="margin-top: 0.50cm;text-align: center;">
                      <div class="row">

                        <div class="col-12" style="font-family: kanit;">บ้านเลขที่ - ชื่อผู้รับ
                          <!-- <a *ngIf="_typeNameOut === 'add' " style="color: rgb(114, 114, 114);"  >(เพิ่ม)</a>
                                  <a *ngIf="_typeNameOut === 'select' " style="color: rgb(114, 114, 114);" >(เลือก)</a> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-12" style="margin-top: 0.1cm;text-align: left;">
                      <div class="row">

                        <div class="col-12" style="font-family: kanit;color: #00b28b;">
                          &nbsp;&nbsp;&nbsp;&nbsp;{{item.unitNumber}}
                          &nbsp;&nbsp;&nbsp;คุณ&nbsp;{{item.recipient}}
                        </div>

                      </div>
                    </div>

                    <div class="col-12" style="margin-top: 0.40cm;text-align: center;">
                      <div class="row">

                        <div class="col-12" style="font-family: kanit;">หมายเลขติดตามพัสดุ</div>
                        <div class="col-12" style="font-family: kanit;text-align: left;color: #00b28b;">
                          &nbsp;&nbsp;&nbsp;&nbsp;{{item.qrcode}}
                        </div>

                      </div>
                    </div>
                    <div class="col-12" style="margin-top: 0.40cm;text-align: center;">
                      <div class="row">
                        <div class="col-12" style="font-family: kanit;">บริการขนส่ง - ลักษณะพัสดุ
                        </div>
                      </div>
                    </div>

                    <div class="col-12" style="margin-top: 0.1cm;text-align: left;">
                      <div class="row">
                        <div class="col-12" style="font-family: kanit;color: #00b28b;">
                          &nbsp;&nbsp;&nbsp;&nbsp;{{item.expresscompany}}
                          :&nbsp;&nbsp;{{item.package}}
                        </div>
                      </div>
                    </div>

                    <div class="col-12" style="margin-top: 0.40cm;text-align: left;">
                      <div class="row">
                        <div class="col-12" style="font-family: kanit;text-align: center;">
                          วันที่ทำรายการ</div>
                        <div class="col-12" style="font-family: kanit;text-align: left;color: #00b28b;">
                          &nbsp;&nbsp;&nbsp;&nbsp;{{item.mapcreationtodate}}
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center" style="margin-top: 0.26cm;"
                      *ngIf="item.mapimageblob !== '-' ">
                      <div class="row">
                        <div class="col-12" style="font-family: kanit;text-align: center;">
                          <mat-chip-list #chipEdit>
                            <mat-chip class="assigned-chipEdit"
                              (click)="viewImage(item.id , item.imageblob , item.parcelno)"
                              style="padding: 0.5cm;;margin-left: 0.1cm;margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                              รูปพัสดุ&nbsp;
                              <span class="material-icons" style="color: white;font-size: 27px;">
                                search
                              </span>
                            </mat-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                    </div>

                    <hr>
                  </div>

                </div>
                <div *ngIf="_countConfirmChoose === 0 " class="col-12"
                  style="margin-top: -0.35cm;text-align: center;margin-bottom: 0.2cm;">
                  <button disabled mat-raised-button class="btn  btn-block"
                    style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                    กรุณาเลือกพัสดุ
                  </button>
                </div>
                <div *ngIf="_countConfirmChoose !== 0 " class="col-12"
                  style="margin-top: -0.35cm;text-align: center;margin-bottom: 0.2cm;">
                  <button mat-raised-button class="btn  btn-block" (click)="confirmToDB()"
                    style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                    ยืนยันการจ่ายออก ({{_countConfirmChoose}})
                  </button>
                </div>
                <div class="col-12" style=" margin-top: 0.025cm;text-align: center;margin-bottom: 0.6cm;">
                  <button mat-raised-button class="btn  btn-block" (click)="cancelPOut()"
                    style="letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                    ยกเลิก
                  </button>
                </div>
              </div>
              <!-- row -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- From ParcelOut Search For Multi Row -->

  <!-- Parcel Out Display Done and Redirect -->
  <div *ngIf="_state === 'B'  && _save === 1 ">
    <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
      <div class="row">
        <div class="container-fluid card"
          style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 1.4cm;">

          <div class="card" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.55cm;">
            <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;">
              <div class="col-12" style="margin-top: 0.7cm;">

                <div class="row justify-content-center">
                  <div class="col-5" style="border-color: #192d4b;border-radius: 6px;font-family:kanit;">
                    <div class="row justify-content-center">
                      <div class="col-12 row justify-content-center">
                        <mat-chip-list #chipDone>
                          <mat-chip class="assigned-chipDone">
                            <span class="material-icons" style="color: white"> done </span>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                      <div class="row justify-content-center"
                        style="font-size: 19px;padding-top: 0.4cm;letter-spacing: 0.03cm;">
                        <div class="col-12">
                          <a style="font-family: kanit;">กรุณารอซักครู่</a>
                        </div>
                        <div class="col-12">
                          <a style="font-family: kanit;font-size: 15px;">ระบบกำลังบันทึก..</a>
                        </div>

                        <!-- <div class="col-12">
                            <a style="font-family: kanit;">{{_countErrorOut}}</a>
                          </div> -->

                      </div>
                      <div class="row justify-content-center"
                        style="font-size: 19px;padding-top: 0.15cm;letter-spacing: 0.03cm;">
                        <div class="col-12">
                          <a style="font-family: kanit;">&nbsp;</a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Parcel Out Display Done and Redirect -->


  <div style="margin-top: 2cm;padding-left: 0.2cm;padding-right: 0.2cm;" class="modal" tabindex="-1" role="dialog"
    [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div>
              <h2 class="modal-title" style="font-family: kanit;font-size: 18px;">
                <mat-chip-list #chipEdit>
                  &nbsp;&nbsp;
                  <mat-chip class="assigned-chipEdit"
                    style="margin-left: 0.1cm;margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                    No.
                  </mat-chip>
                  {{displayID}}
                </mat-chip-list>
              </h2>
              <h4 class="modal-title" style="font-family: kanit;font-size: 14.5px;margin-top: -0.6cm;">
                <mat-chip-list #chipEdit>
                  &nbsp;&nbsp;
                  <mat-chip class="assigned-chipEdit"
                    style="margin-left: 0.1cm;margin-top: 0.3cm;font-size: 15px;font-family: kanit;text-align: left;">
                    Runnung No
                  </mat-chip>
                  : {{displayParcelNo}}

                </mat-chip-list>
              </h4>
              <h4 class="modal-title"
                style="color: #00b28b;padding-left: 0.3cm;margin-top: 0.60cm;font-family: kanit;font-size: 14.5px;">
                ** เพื่อความเร็วในการดึงข้อมูล **
              </h4>
              <h4 class="modal-title"
                style="color: #00b28b;padding-left: 0.3cm;font-family: kanit;font-size: 14.5px;margin-top: -0.4cm;">
                ** ระบบจะปรับความชัดของรูปอัตโนมัติ **
              </h4>
            </div>
          </div>
        </div>

        <div class="modal-body" style="text-align: center;">
          <canvas id="canvas"></canvas>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn" data-dismiss="modal"
            style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 17.5px;font-family: Kanit;background-color: #00b28b;color: white;"
            (click)="closePopup()">
            &nbsp;&nbsp;ปิด&nbsp;&nbsp;
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Alert Modal Reload -->
  <div *ngIf="_readyToReload === '1' " style="margin-top: 3.2cm;padding-left: 0.2cm;padding-right: 0.2cm;" class="modal"
    tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleModal}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-body" style="margin-top: 0.4cm;">
          <div class="row justify-content-center">
            <mat-chip-list #chipDone>
              <mat-chip class="assigned-chipDone">
                <span class="material-icons" style="color: white;font-size: 15px;"> done </span>
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="row justify-content-center" style="margin-top: 0.4cm;">
            <a style="font-family: kanit;font-size: 19px;">บันทึกเรียบร้อย</a>
            ​
          </div>
          <div class="row justify-content-center" style="margin-top: 1.1cm;margin-bottom: 0.7cm;">
            <button type="button" class="btn" data-dismiss="modal"
              style="border-radius: 18px;padding: 0.15cm;letter-spacing: 0.05em;font-size: 16px;font-family: Kanit;background-color: #06162c;color: white;"
              (click)="clearPOut()">
              &nbsp;&nbsp;คลิ๊ก เพื่อทำรายการใหม่อีกครั้ง..&nbsp;&nbsp;
            </button>
          </div>
        </div>



      </div>
    </div>
  </div>


  <div *ngIf="_state === 'QR' ">
    <div class="row">
      <div class="container-fluid card" style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;">
        <div>
          <div class="row">
            <div class="card container-fluid" style="margin:0.125cm;border-radius: 9px;">
              <div class="row" style="margin-top: 0.3cm;" *ngIf="progressModeCCRO === false">
                <div *ngIf="scanResultCCRO === 'Waiting' && progressModeCCRO === false">
                  <div class="container-fluid card" style="background-color: white;border-color: white;">

                    <a style="font-family: kanit;font-size: 17px;">
                      <span class="material-icons" style="font-size: 14px;color: #adb6c3;">
                        qr_code_scanner
                      </span>
                      Scan QR From COCORO App</a>
                  </div>


                </div>
              </div>

              <div class="row" style="margin-top: 0.3cm;"
                *ngIf="scanResultCCRO === 'Waiting' && progressModeCCRO === false">
                <div class="container-fluid card" style="background-color: white;border-color: white;">
                  <zxing-scanner (scanSuccess)="onCodeResultCCRO($event)" [formats]="formatsEnabled"></zxing-scanner>
                </div>

                <div class="col-12" style="margin-top: 0.37cm;margin-bottom: 0.4cm;">


                  <button mat-raised-button class="btn  btn-block" (click)="cancelCCROScan()"
                    style="margin-bottom: 0.35cm;padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                    ยกเลิก
                  </button>
                </div>
              </div>

              <!-- <hr> -->

              <form #aqf="ngForm"
                *ngIf="parcelListModelForCCRO.length !== 0 && scanResultCCRO === 'itemAPI' &&  progressModeCCRO === false">
                <div class="row justify-content-center"
                  style="font-weight: bold;font-family: kanit;font-size:18px;margin-top: 0.4cm;">
                  - ข้อมูลลูกค้า -
                </div>
                <hr>
                <div class="row" style="margin-top: -0.42cm;margin-bottom: 0.1cm;">
                  <div class="col-12">
                    <a style="text-align: center;align-items: center;font-family: kanit;">&nbsp;</a>
                  </div>


                  <div class="col-12" style="margin-top: 0.11cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size: 18px;">
                            pin
                          </span>
                        </div>
                      </div>
                      <input
                        style="border-color: #00b28bb9;border-style: dotted;color: rgb(224, 24, 24);font-size: 14px;"
                        class="form-control form-control-sm" disabled placeholder="{{_ccroparcelno}}" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.15cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size: 18px;">
                            person
                          </span>
                        </div>
                      </div>
                      <input
                        style="color: #00b28bb9;font-family: kanit;border-color: #c3105dda;border-style: dotted;font-size: 14px;"
                        class="form-control form-control-sm" disabled placeholder="{{_ccrorecipient}}" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.15cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size: 18px;">
                            call
                          </span>
                        </div>
                      </div>
                      <input
                        style="color: #00b28bb9;font-family: kanit;border-color: #c3105dda;border-style: dotted;;font-size: 14px;"
                        class="form-control form-control-sm" disabled placeholder="{{_ccrorephone}}" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.15cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size: 18px;">
                            cottage
                          </span>
                        </div>
                      </div>
                      <input
                        style="color: #00b28bb9;font-family: kanit;border-color: #c3105dda;border-style: dotted;;font-size: 14px;"
                        class="form-control form-control-sm" disabled placeholder="{{_ccrorunitnumber}}" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.15cm;">
                    <div class="input-group mr-sm-12">
                      <div class="input-group-prepend" style="height: 31px;">
                        <div class="input-group-text">
                          <span class="material-icons" style="font-size: 18px;">
                            clear_all
                          </span>
                        </div>
                      </div>
                      <input
                        style="color: #00b28bb9;font-family: kanit;border-color: #00b28bb9;border-style: dotted;;font-size: 14px;"
                        class="form-control form-control-sm" disabled placeholder="{{_ccroexpresscompany}}" />
                    </div>
                  </div>

                  <div class="col-12" style="margin-top: 0.55cm;" *ngIf="_ccroparcelstatus === 'Arrived' ">
                    <button mat-raised-button class="btn  btn-block" (click)="ccroConfirmSave()"
                      style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #001b30;color: white;">
                      ยืนยัน
                    </button>
                  </div>
                  <div class="col-12" style="margin-top: 0.45cm;margin-bottom: 0.03cm;"
                    *ngIf="_ccroparcelstatus !== 'Arrived' ">
                    <!-- <button mat-raised-button class="btn  btn-block"
                      style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #192d4b;color: white;">
                      พัสดุนี้ คีย์ออกจากระบบไปแล้ว...
                    </button> -->
                    <a style="font-family: kanit;color: #c43737;font-family: 15.5px;">*** พัสดุนี้
                      คีย์ออกจากระบบไปแล้ว...</a>
                  </div>
                  <div class="col-12" style="margin-top: 0.15cm;margin-bottom: 0.35cm;">
                    <button mat-raised-button class="btn  btn-block" (click)="cancelCCROScan()"
                      style="padding: 0.15cm;letter-spacing: 0.05em;font-size: 15.5px;font-family: Kanit;background-color: #00b28b;color: white;">
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </form>
              <div class="row" *ngIf="scanResultCCRO === 'fetchAPI' && progressModeCCRO === true ">
                <div class="container-fluid card"
                  style="margin-bottom: 1cm;margin-top: 1cm;background-color: white;border-color: white;">
                  <mat-spinner style="margin:0 auto;" [diameter]="30"></mat-spinner>
                  <a style="font-family: kanit;text-align: center; padding-top:0.3cm;letter-spacing: 00.030cm;">กรุณารอสักครู่
                    ...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="_state === 'QRCCRODone' ">
    <div>
      <div class="container-fluid;" style="margin-right: 0.1cm;margin-left: 0.1cm;margin-bottom: 0.3cm;;">
        <div class="row">
          <div class="container-fluid card"
            style="background-color: #e9ecef;margin-top: 0.3cm;border-radius: 9px;margin-bottom: 1.4cm;">

            <div class="card" style="margin:0.125cm;border-radius: 9px;margin-top: 0.5cm;margin-bottom: 0.55cm;">
              <div class="row" style="text-align: center;margin: 0.1cm;margin-bottom: 0.2cm;">
                <div class="col-12" style="margin-top: 0.7cm;">

                  <div class="row justify-content-center">
                    <div class="col-5" style="border-color: #192d4b;border-radius: 6px;font-family:kanit;">
                      <div class="row justify-content-center">
                        <div class="col-12 row justify-content-center" *ngIf="_ccroSaveToDatabase === '0' ">
                          <img src="../../assets/images/app.png" width="42px" height="40px">
                        </div>

                        <div class="col-12 row justify-content-center" *ngIf="_ccroSaveToDatabase === '1' ">
                          <mat-chip-list #chipDone>
                            <mat-chip class="assigned-chipDone">
                              <span class="material-icons" style="color: white;font-size: 16px;"> done </span>
                            </mat-chip>
                          </mat-chip-list>
                        </div>


                        <div class="row justify-content-center"
                          style="font-size: 19px;padding-top: 0.4cm;letter-spacing: 0.02cm;">
                          <div class="col-12">
                            <a *ngIf="_ccroSaveToDatabase === '0' "
                              style="font-family: kanit;color: #000;">กรุณารอซักครู่</a>
                          </div>
                          <div class="col-12">
                            <a *ngIf="_ccroSaveToDatabase === '0' "
                              style="font-family: kanit;font-size: 15px;color: #000;">ระบบกำลังบันทึก..</a>
                            <a *ngIf="_ccroSaveToDatabase === '1' "
                              style="font-family: kanit;font-size: 15px;color: #001b30;">บันทึกเรียบร้อย..</a>

                          </div>

                        </div>
                        <div class="row justify-content-center"
                          style="font-size: 19px;padding-top: 0.15cm;letter-spacing: 0.03cm;">
                          <div class="col-12">
                            <a style="font-family: kanit;">&nbsp;</a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</body>

</html>