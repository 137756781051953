import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { CustomerModel } from '../models/customer.module';
import { AnandaAuthenModel , AnandaMultiSite  } from '../models/anandaauthen.module';
 import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  _prefix =  environment.apiAuthen;

  _authen = environment.apiAuthen;
  _mode = environment.mode;
  _dashboard = environment.apiCoreParcelOut;
  _view = environment.apiCoreView;
  _aone = environment.apiAone;
  _plugAndPlay = environment.apiPlugAndPlay;


  _urlAnandaAuthen =         this._prefix + 'authen';         //Method in Controller
  _urlAnandaFetchMultiSite = this._prefix + 'fetchMultiSite'; //Method in Controller
    
  constructor(private http: HttpClient, private router: Router) {
     this.callENV();
   }

  async callENV() {

    this._prefix = await environment.apiAuthen;
    this._mode = await environment.mode;

    this._authen = await environment.apiAuthen;
    this._dashboard = await environment.apiCoreParcelOut;
    this._view = await environment.apiCoreView;
    this._aone = await environment.apiAone;
    this._plugAndPlay = await environment.apiPlugAndPlay;
  }
 

   private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }


  authenToAnanda(_email: String, _pass: String) {
    var _data = {
      "username": _email,
      "passwordhash": _pass
    }
    return this.http.post<AnandaAuthenModel[]>(this._urlAnandaAuthen, _data)
    .pipe(
      map(response => {
          return <any>response;
      }),
      catchError(error => {
          this.handleError("authenToAnanda : " + error);
          return of('ERROR');
      })
    )};


  fetchMultiSiteToAnanda(_id: String) {
    var _data = {
      "id": _id,
    }
    var _token = localStorage.getItem('_token');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${_token}`
  });
 

  console.log('=====> fetchMultiSiteToAnanda');
  console.log(this._urlAnandaFetchMultiSite);
  console.log('=====> fetchMultiSiteToAnanda');

  let options = { headers: headers };
    return this.http.post<AnandaMultiSite[]>(this._urlAnandaFetchMultiSite,  _data ,options )
    .pipe(
      map(response => {
          return <any>response;
      }),
      catchError(error => {
          this.handleError("fetchMultiSiteToAnanda : " + error);
          return of('ERROR');
      })
    )};

  }

 
  

