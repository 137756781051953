import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AuthenService } from '../services/authen.service';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-authen',
  templateUrl: './authen.component.html',
  styleUrls: ['./authen.component.css']
})
export class AuthenComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  // deviceInfo = null;
  // isDesktopDevice: boolean;
  // isTablet: boolean;
  // isMobile: boolean;
  // detailsDevice = "";


  @ViewChild('l', { static: false }) loginForm: NgForm;
  email: string;
  password: string;
  _uniqId : string;
  
  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    private location: Location,
    private authenService: AuthenService,
    private _dashboardService : DashboardService
    ) { }

  btnLoginClick = false;
  btnLoginClick2 = false;

   
  ngOnInit() {

      ///
      /// this.subScribeDeviceInfo();
      ///

    console.log('MODE : ' + this.authenService._mode);
    console.log('AUTHEN : ' + this.authenService._authen);
    console.log('DASHBOARD : ' + this.authenService._dashboard);
    console.log('VIEW : ' + this.authenService._view);
    //console.log('AONE : ' + this.authenService._aone);
    console.log('PLUG & PLAY : ' + this.authenService._plugAndPlay);
  

    var storedToken = '';
    var storedAccessApp = '';
    storedAccessApp = localStorage.getItem('_accesApp');
    //if (storedToken != null && storedToken != '') {
    if (storedAccessApp === 'true') {
      this.router.navigate(['/main']);
    }
  }

  // subScribeDeviceInfo() {
  //   try {
  //     this.deviceInfo = this.deviceService.getDeviceInfo();
  //     console.log(this.deviceInfo);
  //     this.isMobile = this.deviceService.isMobile();
  //     this.isTablet = this.deviceService.isTablet();
  //     this.isDesktopDevice = this.deviceService.isDesktop();
  //     console.log(this.deviceInfo);
  //     console.log(this.isMobile);
  //     console.log(this.isTablet);
  //     console.log(this.isDesktopDevice);
  //     this.detailsDevice = "OS : (" + this.deviceInfo.os + ") , Browser : (" + this.deviceInfo.browser + ") , Mobile : (" + this.isMobile + ") , Tablet : ("
  //       + this.isTablet + ") , Desktop : (" + this.isDesktopDevice + ") ";
  //   } catch (error) { 
  //     console.log('Error Login subScribeDeviceInfo');
  //   }
  // }

  async loginServer() {

  this._uniqId = this.makeid(15);
  localStorage.setItem('_uniqId', this._uniqId.toString());
  console.log('this.uniqId : ' +  this._uniqId);

    this.email = this.loginForm.value.exampleInputEmail1;
    this.password = this.loginForm.value.exampleInputPassword1;
    this.btnLoginClick = true;
    this.btnLoginClick2 = true;

    if (this.email == '' || this.password == '' || this.password == null || this.email == null) {
      this.openErrorSnackBar();
      return;
    }

    try {
      this.authenService.authenToAnanda(this.email, this.password).subscribe(async authenModel => {
      //  console.log(authenModel);
         if (authenModel === 'ERROR') {
          setTimeout(() => {
            this.btnLoginClick = false;
            this.btnLoginClick2 = false;
          }, 300);
          localStorage.clear();
          this.router.navigate(['/authen/']);
          return;
        }
        if (authenModel !== 'ERROR' && authenModel != null && authenModel["id"] !== 0 ) {

          var _userid = authenModel["id"];
          var _token = authenModel["token"];
          var _userName =  authenModel["userName"];
         

          localStorage.setItem('_accesApp', 'true');
          localStorage.setItem('_UserId', _userid);
          localStorage.setItem('_token', _token);
          localStorage.setItem('_userName', authenModel["userName"]);
          localStorage.setItem('_projectIds', authenModel["projectids"]);

          let _parcelVersion = await  this.fetchParcelVersionFromServer(_token , _userName);
          console.log('Call Back =====> _parcelVersion');
      
        } else {
          this.openErrorSnackBar();
          setTimeout(() => {
            this.btnLoginClick = false;
            this.btnLoginClick2 = false;
          }, 500);
        }
      });
    } catch (error) {
      setTimeout(() => {
        this.btnLoginClick = false;
        this.btnLoginClick2 = false;
      }, 300);
      localStorage.clear();
      this.router.navigate(['/authen/']);
      return;
    }
  }

   async fetchParcelVersionFromServer(_token:any , user:any) {
    try {
      let fetchParcelVersion = await  this._dashboardService.fetchParcelVersion(_token).toPromise();

      localStorage.setItem('_localversion', fetchParcelVersion[0].masterversion.toString());
      localStorage.setItem('_sererversion', fetchParcelVersion[0].masterversion.toString());

      let _recordVersionOnScreen = await this._dashboardService.recordVersionOnScreen(
        _token, user, fetchParcelVersion[0].masterversion.toString()
        , this._uniqId).toPromise();

      var _swarpResult = _recordVersionOnScreen;

        console.log('_recordVersionOnScreen');
        console.log('=============> ' + _recordVersionOnScreen);
        if(_swarpResult === <any> 'Success') {

          setTimeout(() => {
            this.router.navigate(['/main/']);
            return
          }, 300);
          // var trackAuthenLog = {
          //   user: user,
          //   uniqid: this._uniqId,
          //   event: 'Login',
          //   device: this.detailsDevice
          // }

          // var _authLog = await this._dashboardService.updateTrackAuthenLog(trackAuthenLog).toPromise();
          // if(_authLog === <any> 'Success') {
          //   console.log("Teerat _parcelVersion");
          //   this.openSnackBar();
          //   setTimeout(() => {
          //     this.router.navigate(['/main/']);
          //     return
          //   }, 300);
          // }
          // else {
          //   window.location.reload();
          // }
        }
      // await this._dashboardService.recordVersionOnScreen(_token,user, fetchParcelVersion[0].masterversion.toString() , 
      // this._uniqId).subscribe(item => {
      //   if(item.toString() === "Success") {
      //     console.log('update version ON Scrren Done...');  

      //     // **********************
      //     // Teerat 12 October 2023
      //     // Keep Authen Log

      //     // if (this.detailsDevice == ""   || this.detailsDevice == null 
      //     //   || this.detailsDevice == ' ' || this.detailsDevice == ''
      //     // ) {
      //     //   this.detailsDevice = 'Something Wrong !!!';
      //     // }
      //     // var trackAuthenLog = {
      //     //   user: user,
      //     //   uniqid: this._uniqId,
      //     //   event: 'Login',
      //     //   device: this.detailsDevice
      //     // }

      //     // console.log('');
      //     // console.log('From Front Call ====> updateTrackAuthenLog ')
      //     // this._dashboardService.updateTrackAuthenLog(trackAuthenLog).subscribe(item => {
      //     //   console.log('Return From Server ====>  updateTrackAuthenLog ');
      //     //   console.log('Status : ' + item);
      //     // });
      //   }else {
      //     window.location.reload();
      //   }
      // });
    }
    catch (error) {
      window.location.reload();
    }
  }

  openErrorSnackBar() {
    this._snackBar.open('Username or Password Invalid !!!', 'End now', {
      duration: 500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });

    this.btnLoginClick2 = true;
    setTimeout(() => {
      localStorage.clear();
      this.btnLoginClick = false;
      this.btnLoginClick2 = false;
    }, 600);
    return;
  }

  openSnackBar() {
    this._snackBar.open('Welcome to Ananda Parcel !!!', '', {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['blue-snackbar']
    });
  }


  openSnackBarWelcomeBack() {
    this._snackBar.open('Welcome back', 'End now', {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
