import { async } from '@angular/core/testing';
import { MockModel, ParcelModelMock2 } from './../models/mock.module';
import { Component, OnInit, ViewChild } from '@angular/core';
  import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "./format-datepicker";
 import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TrandateModel } from '../models/trandate.module';
import { ParcelModel } from '../models/parcel.module';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-scanout',
  templateUrl: './scanout.component.html',
  styleUrls: ['./scanout.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class ScanoutComponent implements OnInit {

  _mockModel : MockModel[] = <any> [];
  _view = 0;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  startIndex = 0;
  
  title = 'Ananda Home';

  readyForDropDown = 0;
  parcelTranDateList : TrandateModel[] = <any> [];
  parcelItemList : ParcelModel[] = <any> [];
  parcelSelected = 'กรุณาเลือกวันที่';
 
  dataSource = new MatTableDataSource<ParcelModel>();
  totalList = 0;
  displayedColumns: string[] = ['parcelstr'  , 'progress'  ];
  selection = new SelectionModel<ParcelModel>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private dashboardService: DashboardService,private router: Router,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.parcelTranDateList.push({
      'trandatestr': 'กรุณาเลือกวันที่'
    });
    this.parcelTranDateList.push({
      'trandatestr': '14 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '13 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '12 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '11 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '08 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '07 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '06 พฤศจิกายน 2565'
    });
    this.parcelTranDateList.push({
      'trandatestr': '05 พฤศจิกายน 2565'
    });

    //Production
    // this.dashboardService.fetchTrandate().subscribe(item => {
    //   if (item === <any>'401') {
    //     this.openErrorSnackBar();
    //   } else {
        
    //     this.parcelTranDateList.push({
    //       'trandatestr': 'กรุณาเลือกวันที่'
    //     });
    //     item.forEach(element => {
    //       this.parcelTranDateList.push({ 'trandatestr': element.trandatestr });
    //     });
    //   }
    // });
     //Production

    setTimeout(() => {
      this.readyForDropDown = 1
    }, 3000);


   }


  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

  openErrorSnackBar() {
    this._snackBar.open('Session Expired Please Login again... !!', 'End now', {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
    setTimeout(() => {
      localStorage.clear();
    }, 600);
    return;
  }

  // onSelectParcelDate(items: any, event) {
  //   this._mockModel = [];
  //   this.parcelSelected = items;
  //   this.dashboardService.fetchParcelToDisplay(this.parcelSelected).subscribe(item => {
  //     if (item === <any>'401') {
  //       this.openErrorSnackBar();
  //     } else {
  //      this.parcelItemList = item;
  //      this.dataSource.data = this.parcelItemList;
  //      this.dataSource.paginator =  this.paginator;
  //      this.totalList =  item.length;
  //     }
  //   });
  // }

  onS() {
    console.log('onS');
  }

  onF() {
    console.log('onF');
  }

  fetchParcel() {
 
    if( this.parcelSelected  != 'กรุณาเลือกวันที่')
    {
      this._mockModel = [];
      for (let i = 1; i <= 10; i++) {
        if(i <= 3) {
          this._mockModel.push( {
            address: '00' + i.toString() + '/' + i.toString() + '00',
            qr: this.makeid(5).toString(),
            text1: 'คุณ อนันดา ไอดีโอ',
            text2: '089-800-8008',
            text3: 'พัสดุมาแล้ว ครับ',
            time : ("0" + i).slice(-2)  + ":" +  ("0" + i).slice(-2),
            user : 'Teerat Pho',
            statusparcel : '1'
          });
        }else {
          this._mockModel.push( {
            address: '00' + i.toString() + '/' + i.toString() + '00',
            qr: this.makeid(5).toString(),
            text1: 'คุณ อนันดา ไอดีโอ',
            text2: '089-800-8008',
            text3: 'พัสดุมาแล้ว ครับ',
            time : ("0" + i).slice(-2)  + ":" +  ("0" + i).slice(-2),
            user : 'Teerat Pho',
            statusparcel : '0'
          });
        }
     
      }
      this._view = 1;
    }
  }

  changeView() {
    this.parcelSelected  = 'กรุณาเลือกวันที่';
    this._mockModel = [];
    this._view = 0;
  }

}