import { Component, OnInit } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.css']
})
export class BarcodeComponent implements OnInit {

  constructor() { }

    formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_93,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODABAR,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.AZTEC,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION
  ];
  startIndex = 0;
  scanResult: any = 'Waiting';
  title = 'Ananda Barcode';
  mockRoomItems = [
    { id : 0 , roomumber : 'กรุณาเลือก บ้านเลขที่', fullname : 'กรุณาเลือก บ้านเลขที่'},
    { id : 1 , roomumber : '01/100', fullname : 'คุณ สวัสดีครับ 100'},
    { id : 2 , roomumber : '01/200', fullname : 'คุณ สวัสดีครับ 200'},
    { id : 3 , roomumber : '01/300', fullname : 'คุณ สวัสดีครับ 300'},
    { id : 4 , roomumber : '01/400', fullname : 'คุณ สวัสดีครับ 400'},
    { id : 5 , roomumber : '01/500', fullname : 'คุณ สวัสดีครับ 500'},
    { id : 6 , roomumber : '01/600', fullname : 'คุณ สวัสดีครับ 600'},
    { id : 7 , roomumber : '01/700', fullname : 'คุณ สวัสดีครับ 700'},
    { id : 8 , roomumber : '01/800', fullname : 'คุณ สวัสดีครับ 800'},
  ];

  ngOnInit(): void {
    const rndInt = Math.floor(Math.random() * 3) + 1
    this.startIndex = rndInt;
    }

  onCodeResult(result: string) {
    const rndInt = Math.floor(Math.random() * 3) + 1
    this.startIndex = rndInt;
    this.scanResult = result;
  }

  onClearBrcode() {
    this.scanResult = 'Waiting';
     
  }

}
